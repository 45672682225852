import React, { useEffect, useState } from "react";
import axios from "axios";

import { notify } from "utilities/notifications/Notify";
import * as authActions from "reduxFiles/actions/authActions";

import DesignerSignup from "./DesignerSignup";
import BrandSignup from "./BrandSignup";
import BuilderSignup from "./BuilderSignup";
import OtherSignup from "./OtherSignup";
import { validateEmail } from "utilities/validateEmail";


function PartnerSignup({ form, step, setStep, serviceType, verifyOtpLogin, otp, setOtp, setUsername }) {
  const [userSubtypes, setUserSubtypes] = useState([]);
  const [requiredSubtypes, setRequiredSubtypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [otpInput, setOtpInput] = useState(false);
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")

  const handleSignUp = async () => {
    setLoading(true);
    const formData = new FormData(form.current);
    if (validateEmail(formData.get("email"))) {
      if (formData.get("confirm_password") !== formData.get("password")) {
        setPasswordError('Passwords do not match. Retry.');
        setLoading(false);
        return;
      }
      formData.append("user_type", serviceType);
      let data = {};
      formData.forEach((value, key) => (data[key] = value));
      console.log(data);
      try {
        const response = await authActions.signup(data);
        if (response.data) {
          setOtpInput(true);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      setLoading(false);
      setEmailError('Please enter a valid email address.');
    }
  };

  const fetchUserSubtypes = async () => {
    try {
      const response = await axios.get(`/user/rest/user-subtypes/`);
      if (response.data) {
        setUserSubtypes(response?.data);
      } else {
        notify("Error while fetching user subtypes. Please try again later", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setRequiredSubtypes(
      userSubtypes.filter((item) => item.user_type === serviceType)
    );
  }, [userSubtypes, serviceType]);

  useEffect(() => {
    fetchUserSubtypes();
  }, []);

  return (
    <>
      {serviceType === "architect" ?
        <DesignerSignup
          form={form}
          requiredSubtypes={requiredSubtypes}
          step={step}
          setStep={setStep}
          otpInput={otpInput}
          handleSignUp={handleSignUp}
          verifyOtpLogin={verifyOtpLogin}
          loading={loading}
          emailError={emailError}
          setEmailError={setEmailError}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
          setUsername={setUsername}
          otp={otp}
          setOtp={setOtp}
        />
        : serviceType === "brand" ?
          <BrandSignup
            form={form}
            step={step}
            setStep={setStep}
            otpInput={otpInput}
            handleSignUp={handleSignUp}
            verifyOtpLogin={verifyOtpLogin}
            loading={loading}
            emailError={emailError}
            setEmailError={setEmailError}
            passwordError={passwordError}
            setPasswordError={setPasswordError}
            setUsername={setUsername}
            otp={otp}
            setOtp={setOtp}
          />
          : serviceType === "builder" ?
            <BuilderSignup
              form={form}
              step={step}
              setStep={setStep}
              otpInput={otpInput}
              handleSignUp={handleSignUp}
              verifyOtpLogin={verifyOtpLogin}
              loading={loading}
              emailError={emailError}
              setEmailError={setEmailError}
              passwordError={passwordError}
              setPasswordError={setPasswordError}
              setUsername={setUsername}
              otp={otp}
              setOtp={setOtp}
            />
            : serviceType === "others" ?
              <OtherSignup
                requiredSubtypes={requiredSubtypes}
                step={step}
                setStep={setStep}
              />
              :
              <></>
      }
    </>
  );
}

export default PartnerSignup;
