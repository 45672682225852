import { useState } from "react";
import { InputGroup, Modal, Form, Button, Spinner, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { interiokeys_full_logo } from "assets";

import * as authActions from "reduxFiles/actions/authActions";
import { login } from "reduxFiles/reducer/authSlices";
import { notify } from "utilities/notifications/Notify";
import { validateEmail } from "utilities/validateEmail";

import { LuMail } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import FacebookGoogleLogin from "./FacebookGoogleLogin";
import { FaAngleRight } from "react-icons/fa6";

const LoginModal = ({ showLoginModal, setShowLoginModal, isHomeOwner, setIsHomeOwner }) => {
  const [formType, setFormType] = useState("login");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLargeScreen = useMediaQuery({
    query: "(min-width: 992px) and (max-width: 1300px)",
  });

  const handleSendOtp = async () => {
    setLoading(true);
    if (validateEmail(email)) {
      try {
        const response = await authActions.sendResetOtp({ email });
        if (response?.status === "SUCCESSFUL") {
          notify("OTP sent successfully!", "success");
          setOtpSent(true);
        } else {
          notify(response?.msg || "Failed to send OTP. Try again.", "error");
        }
      } catch (error) {
        console.error(error);
        notify("Error while sending OTP. Please try again.", "error");
      }
    } else {
      notify("Please enter a valid email ID!", "error");
    }
    setLoading(false);
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    if (!otp) {
      notify("Please enter the OTP.", "error");
      setLoading(false);
      return;
    }

    try {
      const data = {
        username: email,
        email_verified_otp: otp,
        login_type: isHomeOwner ? "client" : "brand",
      };
      const response = await authActions.verfiyOtpLogin(data);
      if (response?.data) {
        dispatch(login(response.data));
        setShowLoginModal(false);
        setEmail("");
        setOtp("");
        setOtpSent(false);
        navigate("/");
        notify("Logged in successfully!", "success");
      } else {
        notify(response?.msg || "Failed to verify OTP. Try again.", "error");
      }
    } catch (error) {
      console.error(error);
      notify("Error while verifying OTP. Please try again.", "error");
    }
    setLoading(false);
  };

  return (
    <Modal
      className="d-flex justify-content-center align-items-center"
      dialogClassName="custom-width-modal"
      show={showLoginModal}
      onHide={() => {
        setShowLoginModal(false);
        setFormType("login");
        setIsHomeOwner(true);
        setOtpSent(false);
        setEmail("");
        setOtp("");
      }}
    >
      <div className="py-3 px-4">
        <Modal.Header className="px-0 pb-0 position-relative" style={{ border: 0 }}>
          <div
            className="cursor-pointer position-absolute icon-hover"
            style={{ right: 0 }}
            onClick={() => {
              setShowLoginModal(false);
              setOtpSent(false);
              setEmail("");
              setOtp("");
            }}
          >
            <IoClose size={24} />
          </div>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="d-flex flex-column justify-content-center align-items-center text-center">
            <div
              className="inter-700 text-gray-900"
              style={{ fontSize: "30px", color: "black" }}
            >
              <Image
                src={interiokeys_full_logo}
                alt="Interiokeys logo"
                style={{ maxWidth: !isLargeScreen ? 180 : 150 }}
              />
            </div>
            <div className="body-text1 inter-600">
              {isHomeOwner ? "Homeowners" : "Professionals"}
            </div>
          </div>
          <div className="body-text2 text-gray-600">
            {formType === "login" ?
              "Select from a collection of top-notch designs created by our professionals for your home interiors"
              : formType === "forgot-password" ?
                "Reset your password using an OTP sent to your Email ID."
                : formType === "reset-password" ?
                  `An email has been sent to ${email} with the otp.`
                  :
                  ""
            }
          </div>
          <Form className="my-3">
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 body-text2 inter-600">
                Email ID<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <InputGroup>
                <InputGroup.Text
                  style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: 3 }}
                >
                  <LuMail size={20} color="#1A1C1F" />
                </InputGroup.Text>
                <Form.Control
                  className="border-start-0 p-2"
                  type="email"
                  name="email"
                  required
                  placeholder="example@gmail.com"
                  value={email}
                  style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
            </Form.Group>

            {otpSent && (
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-600">
                  Enter OTP<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                  className="p-2"
                  type="text"
                  name="email_verified_otp"
                  required
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
              </Form.Group>
            )}

            <Button
              onClick={otpSent ? handleVerifyOtp : handleSendOtp}
              variant="primary"
              className="py-2 body-text2 border-0"
              style={{
                fontWeight: "600",
                color: "#fff",
                width: "100%",
                borderRadius: 3,
              }}
            >
              {loading && <Spinner animation="border" size="sm" />}{" "}
              {otpSent ? "Verify OTP" : "Send OTP"}
            </Button>
          </Form>

          <div className="text-gray-900 text-center my-2">
            Not a member?&nbsp;
            <span
              className="inter-500 body-text2 cursor-pointer"
              onClick={() => {
                if (isHomeOwner) {
                  window.location.assign("/signup#homeowner");
                } else {
                  window.location.assign("/signup#professional");
                }
                setShowLoginModal(false);
              }}
            >
              {isHomeOwner ? "Homeowner" : "Professional"} Registration
            </span>
          </div>
          {formType === "login" &&
            <>
              <FacebookGoogleLogin userType={isHomeOwner ? "customer" : "professional"} />
              <div className="text-gray-900" style={{ fontSize: '14px' }}>
                Are you a {isHomeOwner ? "Professional" : "Homeowner"}?
              </div>
              <Button
                className="py-2 mt-2 border-0 w-100 d-flex justify-content-between align-items-center"
                style={{
                  fontWeight: "600",
                  backgroundColor: "#F1F3F4",
                  color: "#5F6368",
                  borderRadius: 3,
                  fontSize: '14px'
                }}
                onClick={() => {
                  setIsHomeOwner(!isHomeOwner);
                }}
              >
                <span className="body-text2">{isHomeOwner ? "Professionals" : "Homeowner"} Login</span>
                <FaAngleRight />
              </Button>
            </>
          }
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default LoginModal;
