import React, { useEffect, useState } from "react";
import { Card, Image, Spinner } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";

const BrandMegaMenu = ({ showBrands, setShowBrands, toggled, setToggled }) => {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const brandCategories = [
    { id: 1, title: "Timbers, Plywood and Laminates", slug: "timbers_plywood_and_laminates" },
    { id: 2, title: "Flooring", slug: "flooring" },
    { id: 3, title: "Other Brands", slug: "others" },
  ]
  const [activeTab, setActiveTab] = useState(brandCategories[0]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchBrandsByCategories(activeTab.slug);
      if (response.data) {
        setData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (activeTab) {
      fetchData()
    }
  }, [activeTab])

  return (
    <>

      {small && <Card
        className={`${showBrands ? "position-absolute huge-dropdown-container d-flex p-0 shadow-none" : "d-none"}`}
        style={{ left: 0, top: 70 }}
      >
        <Card.Body
          className="p-0"
          style={{ backgroundColor: "#FFFFFF", borderRadius: 5 }}
        >
          <div className="pt-2" style={{ padding: "0px 50px 30px" }}>
            <div className="body-text2 d-flex mb-4">
              {brandCategories.map((item) => {
                return (
                  <div className="me-2 cursor-pointer" onClick={() => setActiveTab(item)}>
                    <div className={`my-1 mx-2 inter-600 ${item.slug === activeTab.slug ? "text-primary" : "text-gray-900"}`}>{item.title}</div>
                    <div className={`mt-1 w-100 ${item.slug === activeTab.slug ? "bg-primary" : ""}`} style={{ height: 2 }} />
                  </div>
                )
              })}
            </div>
            {loading ?
              <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '10vh' }}>
                <Spinner />
              </div>
              :
              <>
                <div
                  className="body-text3 d-flex align-items-center inter-500 mb-2"
                  onClick={() => navigate(`/brands`)}
                >
                  <span className="me-2">View All Brands</span>
                  <FaChevronRight size={12} />
                </div>
                <div className="d-flex flex-column">
                  {Object.keys(data).map((categoryKey, index) => (
                    <div key={index}>
                      <div className={`mt-3 inter-500 text-gray-900`}>{categoryKey}</div>
                      <div className="d-flex flex-wrap">
                        {data[categoryKey].length ?
                          data[categoryKey]?.map((brand, index) => {
                            return (
                              <div
                                key={index}
                                className="d-flex flex-column justify-content-center align-items-center body-text3 nav-link p-0 cursor-pointer me-3 small-image"
                                onClick={() => {
                                  setShowBrands(false)
                                  navigate(`/brands/${brand.slug}`)
                                }}
                                style={{ width: "120px", height: "46px" }}
                              >
                                <Image
                                  src={brand?.image}
                                  alt={brand.company_name}
                                  // className=""
                                  width="108px"
                                  height="34px"
                                />
                              </div>
                            );
                          })
                          :
                          <div
                            className="d-flex justify-content-center align-items-center small-image"
                            style={{ width: "120px", height: "46px" }}
                          >
                            No Brands!
                          </div>
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </>
            }
          </div>
        </Card.Body>
      </Card>}
      {!small && showBrands && <div className="d-flex flex-column">
        {brandCategories.map((item) => {
          return (
            <div className="me-2 cursor-pointer"
              onClick={() => {
                navigate('/brands')
                setToggled(!toggled)
              }}
              style={{ padding: '6px 16px' }}
            // onClick={() => setActiveTab(item)}
            >
              <div className={`body-text1 inter-400 text-gray-900`}>{item.title}</div>
            </div>
          )
        })}
      </div>}
    </>
  );
};

export default BrandMegaMenu;
