import React from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { no_location_image, interiokeys_logo } from "assets";
import { MdOutlineArrowForward } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const LocationModal = ({ showLocationModal, setShowLocationModal, allLocations, setCurrentLocation }) => {

    const navigate = useNavigate()

    return (
        <Modal
            className="d-flex justify-content-center align-items-center"
            dialogClassName="location-modal-width"
            show={showLocationModal}
            onHide={() => {
                setShowLocationModal(false);
            }}
        >
            <Image src={no_location_image} alt="No Location Image" style={{ borderRadius: "6px 6px 0 0" }} />
            <div className="text-center my-3">
                <Image src={interiokeys_logo} alt="Interiokeys Logo" width={30} height={30} />
            </div>
            <div className="px-3">
                <h3 className="inter-600 text-center">Hey there, we are unserviceable.</h3>
                <div className="text-gray-600 text-center">
                    Our <span className="inter-600">turnkey services are currently available only</span> in the following cities:
                </div>
                {allLocations.map((location, index) => {
                    return (
                        <div
                            key={index + 1}
                            className="w-100 d-flex justify-content-between align-items-center body-text1 p-2 my-2 cursor-pointer"
                            style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                            onClick={() => {
                                setCurrentLocation(location?.title)
                                setShowLocationModal(false)
                            }}
                        >
                            <span>{location.title}</span>
                            <MdOutlineArrowForward size={20} />
                        </div>
                    )
                })}
                <hr style={{ color: "#BDC1C6" }} />
                <div className="text-gray-600 text-center">
                    But, you can always check out the top inspirations, designers and properties only our PAN-INDIA Site!
                </div>
                <Button
                    className="body-text1 inter-400 w-100 my-3 d-flex justify-content-between align-items-center"
                    style={{ borderRadius: 3 }}
                    onClick={() => {
                        setShowLocationModal(false);
                        navigate(`/projects`);
                    }}
                >
                    <span>Explore Inspirations on PAN-INDIA Site</span>
                    <MdOutlineArrowForward size={20} />
                </Button>
            </div>
        </Modal>
    )
}

export default LocationModal;