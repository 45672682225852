import {
  wcu_img1,
  wcu_img2,
  wcu_img3,
  wcu_img4,
  wcu_img5,
  wcu_img6,
  wcu_img7,
} from "assets";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

// const WhyChooseUs = () => {
//   const large = useMediaQuery({ query: "(min-width: 992px)" });

//   return (
//     <Container fluid className="mt-5 pt-4 px-0">
//       <section className="d-flex flex-column alignt-items-center">
//         <div className="h1 inter-600 text-center mb-lg-5 mb-4 mt-lg-5 mt-4">Why Choose Us?</div>
//         <Row
//           className="align-items-center py-5"
//           style={{
//             background: "#F0F3F9",
//             // padding: `3% ${large ? "20%" : "10%"}`,
//             paddingLeft: `${large ? "20%" : "10%"}`,
//             paddingRight: `${large ? "20%" : "10%"}`,
//           }}
//         >
//           <Col sm={4}>
//             <img className="img-fluid" src={wcu_img1} alt="" />
//           </Col>
//           <Col sm={8} className="mt-lg-0 mt-3">
//             <div className="h3 inter-600 text-primary">
//               Endless Design Options
//             </div>
//             <div className="body-text2 geist">
//               Explore a wide range of design styles and budgets, with options to suit any taste or project.
//             </div>
//           </Col>
//         </Row>
//         <Row
//           className="align-items-center py-5"
//           style={{
//             paddingLeft: `${large ? "20%" : "10%"}`,
//             paddingRight: `${large ? "20%" : "10%"}`
//           }}
//         >
//           <Col sm={4}>
//             <img className="img-fluid" src={wcu_img2} alt="" />
//           </Col>
//           <Col sm={8} className="mt-lg-0 mt-3">
//             <div className="h3 inter-600 text-primary">
//               Pre-aligned and Ready to Execute Designs
//             </div>
//             <div className="body-text2 geist">
//               Speed up projects with pre-designed inspirations from top designers, ensuring fast execution without sacrificing quality.
//             </div>
//           </Col>
//         </Row>
//         <Row
//           className="align-items-center py-5"
//           style={{
//             background: "#F0F3F9",
//             paddingLeft: `${large ? "20%" : "10%"}`,
//             paddingRight: `${large ? "20%" : "10%"}`,
//           }}
//         >
//           <Col sm={4}>
//             <img className="img-fluid" src={wcu_img3} alt="" />
//           </Col>
//           <Col sm={8} className="mt-lg-0 mt-3">
//             <div className="h3 inter-600 text-primary">
//               Transparent Pricing and Cost Control
//             </div>
//             <div className="body-text2 geist">
//               <div>
//                 Clear pricing with no hidden fees, real-tme cost updates and full control of your budget throughout the project.
//               </div>
//             </div>
//           </Col>
//         </Row>
//         <Row
//           className="align-items-center py-5"
//           style={{
//             paddingLeft: `${large ? "20%" : "10%"}`,
//             paddingRight: `${large ? "20%" : "10%"}`
//           }}
//         >
//           <Col sm={4}>
//             <img className="img-fluid" src={wcu_img4} alt="" />
//           </Col>
//           <Col sm={8} className="mt-lg-0 mt-3">
//             <div className="h3 inter-600 text-primary">
//               Convenient and Time-Saving
//             </div>
//             <div className="body-text2 geist">
//               Simplify the design process with ready-to execute options and easy collaboration, saving time and reducing delays.
//             </div>
//           </Col>
//         </Row>
//         <Row
//           className="align-items-center py-5"
//           style={{
//             background: "#F0F3F9",
//             paddingLeft: `${large ? "20%" : "10%"}`,
//             paddingRight: `${large ? "20%" : "10%"}`,
//           }}
//         >
//           <Col sm={4}>
//             <img className="img-fluid" src={wcu_img5} alt="" />
//           </Col>
//           <Col sm={8} className="mt-lg-0 mt-3">
//             <div className="h3 inter-600 text-primary">
//               Multilayer Warranty System
//             </div>
//             <div className="body-text2 geist">
//               Enjoy an extra 2-year warranty above the standard warranties for our brand partners for added peace of mind.
//             </div>
//           </Col>
//         </Row>
//         <Row
//           className="align-items-center py-5"
//           style={{
//             paddingLeft: `${large ? "20%" : "10%"}`,
//             paddingRight: `${large ? "20%" : "10%"}`
//           }}
//         >
//           <Col sm={4}>
//             <img className="img-fluid" src={wcu_img6} alt="" />
//           </Col>
//           <Col sm={8} className="mt-lg-0 mt-3">
//             <div className="h3 inter-600 text-primary">
//               Trusted Network of Professionals
//             </div>
//             <div className="body-text2 geist">
//               Work with top-quality professionals vetted by Interiokeys for reliable and high-standard services.
//             </div>
//           </Col>
//         </Row>
//         <Row
//           className="align-items-center py-5"
//           style={{
//             background: "#F0F3F9",
//             paddingLeft: `${large ? "20%" : "10%"}`,
//             paddingRight: `${large ? "20%" : "10%"}`,
//           }}
//         >
//           <Col sm={4}>
//             <img className="img-fluid" src={wcu_img7} alt="" />
//           </Col>
//           <Col sm={8} className="mt-lg-0 mt-3">
//             <div className="h3 inter-600 text-primary">
//               Genuine Rating System
//             </div>
//             <div className="body-text2 geist">
//               Get transparent ratings from clients and professionals, along with Interiokeys performance ratings to showcase work quality.
//             </div>
//           </Col>
//         </Row>
//       </section>
//     </Container>
//   );
// };

// export default WhyChooseUs;

const WhyChooseUs = () => {
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const reasonsToChoose = [
    { img: wcu_img1, title: "Endless Design Options", description: "Explore a wide range of design styles and budgets, with options to suit any taste or project.", bgColor: "#F0F3F9" },
    { img: wcu_img2, title: "Pre-aligned and Ready to Execute Designs", description: "Speed up projects with pre-designed inspirations from top designers, ensuring fast execution without sacrificing quality.", bgColor: "white" },
    { img: wcu_img3, title: "Transparent Pricing and Cost Control", description: "Clear pricing with no hidden fees, real-time cost updates and full control of your budget throughout the project.", bgColor: "#F0F3F9" },
    { img: wcu_img4, title: "Convenient and Time-Saving", description: "Simplify the design process with ready-to-execute options and easy collaboration, saving time and reducing delays.", bgColor: "white" },
    { img: wcu_img5, title: "Multilayer Warranty System", description: "Enjoy an extra 2-year warranty above the standard warranties for our brand partners for added peace of mind.", bgColor: "#F0F3F9" },
    { img: wcu_img6, title: "Trusted Network of Professionals", description: "Work with top-quality professionals vetted by Interiokeys for reliable and high-standard services.", bgColor: "white" },
    { img: wcu_img7, title: "Genuine Rating System", description: "Get transparent ratings from clients and professionals, along with Interiokeys performance ratings to showcase work quality.", bgColor: "#F0F3F9" }
  ]

  return (
    <Container fluid className="mt-5 pt-4 px-0">
      <section className="d-flex flex-column align-items-center">
        <div className="h1 inter-600 text-center mb-lg-5 mb-4 mt-lg-5 mt-4">Why Choose Us?</div>
        {reasonsToChoose.map(({ img, title, description, bgColor }, index) => (
          <Row
            key={index}
            className="w-100 align-items-center py-5"
            style={{
              background: bgColor,
              paddingLeft: `${large ? "10%" : "5%"}`,
              paddingRight: `${large ? "10%" : "5%"}`
            }}
          >
            <Col xs={12} sm={4} className="text-center mb-3 mb-sm-0">
              <img className="img-fluid" src={img} alt={title} />
            </Col>
            <Col xs={12} sm={8}>
              <div className="h3 inter-600 text-primary ps-3 ps-lg-0">{title}</div>
              <div className="body-text2 geist ps-3 ps-lg-0">{description}</div>
            </Col>
          </Row>
        ))}
      </section>
    </Container>
  );
};

export default WhyChooseUs;
