import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const Pagination = ({
  currentPage,
  setCurrentPage,
  totalPages,
  setTotalPages,
  nextUrl,
  previousUrl,
  fetchFunction,
}) => {
  return (
    <div className="pagination-controls my-3">
      <Button
        className="p-0 me-2"
        style={{ background: "white", border: "none" }}
        size="sm"
        onClick={() => {
          if (previousUrl && currentPage > 1) {
            fetchFunction(previousUrl);
            setCurrentPage(currentPage - 1);
          }
        }}
        disabled={currentPage === 1}
      >
        <MdKeyboardArrowLeft
          color={currentPage === 1 ? "grey" : "black"}
          size={24}
        />
      </Button>
      {Array.from({ length: totalPages }, (_, index) => (
        <Button
          className="text-white me-2"
          style={{
            background: index + 1 !== currentPage && "lightgray",
            border: "none",
            width: 35,
            height: 35,
          }}
          size="sm"
          key={index}
          onClick={() => setCurrentPage(index + 1)}
        >
          {index + 1}
        </Button>
      ))}
      <Button
        className="p-0"
        style={{ background: "white", border: "none" }}
        size="sm"
        onClick={() => {
          if (nextUrl && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            fetchFunction(nextUrl);
          }
        }}
        disabled={currentPage === totalPages}
      >
        <MdKeyboardArrowRight
          color={currentPage === totalPages ? "grey" : "black"}
          size={24}
        />
      </Button>
    </div>
  );
};

export default Pagination;
