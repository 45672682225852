import { Badge, Button, Card, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ConsultaionCard from "./ConsultationCard";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";

const PropertyGridCard = ({
  id,
  property_name,
  property_img,
  flats,
  layout_types,
  project_type,
  location,
  builder,
  recommended,
  new_property,
  boxShadow,
  imgHeight = "250px",
}) => {
  const navigate = useNavigate();
  const xsmall = useMediaQuery({ query: "(max-width: 576px)" });
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  return (
    <Card
      className="p-0 overflow-hidden h-100"
      style={{
        width: "100%",
        cursor: "pointer",
        borderRadius: 16,
        boxShadow: boxShadow,
      }}
      // onClick={() => navigate(`/properties/${id}`)}
    >
      <Card.Img
        className="img-fluid"
        variant="top"
        src={property_img}
        style={{
          maxHeight: imgHeight,
          minHeight: imgHeight,
          objectFit: "cover",
        }}
      />
      <Card.Body>
        <Card.Title
          className="inter-700"
          style={{ color: "#344054", fontSize: "16px" }}
        >
          <div className="h4 inter-700">
            {property_name} / {builder}
          </div>
          <Stack direction={!xsmall && "horizontal"} gap={2}>
            {recommended && <Badge bg="primary">Interiokeys Recommended</Badge>}
            {new_property && (
              <Badge bg="info text-primary">New Property!</Badge>
            )}
          </Stack>
        </Card.Title>

        <Card.Text className="body-text2 mb-0">
          <span className="inter-600">Number of Flats: </span>
          {flats}
        </Card.Text>
        <Card.Text className="body-text2 mb-0">
          <span className="inter-600">Layout Types: </span>
          {layout_types ?? "-"}
        </Card.Text>
        <Card.Text className="body-text2 mb-0">
          <span className="inter-600">Project Type: </span>
          {project_type}
        </Card.Text>
        <Card.Text className="body-text2 mb-0">
          <span className="inter-600">Location: </span>
          {location}
        </Card.Text>
        {/* <div className="body-text2">
          <div className="inter-600">Type: {type}</div>
          {architect && <div>Architect: {architect}</div>}
          {location && <div>Location: {location}</div>}
          {builders && <div>Builders: {builders}</div>}
        </div> */}
      </Card.Body>
    </Card>
  );
};

export default PropertyGridCard;
