import { add_multiple_products, add_single_product, project_drawing_only, turnkey_product } from "assets";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FaRegStar } from "react-icons/fa";

const ProductRequirement = ({ uploadType, setUploadType, currentStep, setCurrentStep
}) => {


    const products = [
        {
            id: 1,
            image: add_single_product,
            title: "Add Single Product",
            slug: "single",
            description: "Add a single product to your library.",
            turnkey: true,
        },
        {
            id: 2,
            image: add_multiple_products,
            title: "Add Multiple Products/Collection",
            slug: "multiple",
            description: "Add more than one product or a collection.",
            turnkey: false,
        }
    ]

    return (
        <Row className={`d-flex flex-wrap my-2 mb-0`}>
            <div className="text-gray-900 body-text2 inter-500 mb-1">Selected Service</div>
            {products.map((product) => {
                return (
                    <Col xs={12}
                        sm={6}
                        md={6} 
                        lg={6}>
                        <Card
                            key={product.id}
                            className={`p-0 shadow-none ${uploadType === product.slug ? "" : "cursor-pointer"}`}
                            style={{
                                position: "relative",
                                height: "100%",
                                borderRadius: 6,
                                border: uploadType === product.slug ? "1px solid #BDC1C6" : "1px solid #E8EAED"
                            }}
                            onClick={() => {
                                setUploadType
                                    (product.slug)
                                setCurrentStep(2)
                            }}
                        >
                            {!(uploadType === product.slug) && <div
                                className="position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center justify-content-center"
                                style={{ borderRadius: 6, }}
                            />}
                            <Card.Img
                                className="img-fluid"
                                variant="top"
                                src={product.image}
                                style={{ height: 200, objectFit: "cover", borderRadius: "6px 6px 0 0" }}
                            />
                            <Card.Body className="d-flex flex-column justify-content-center py-2">
                                <div className="body-title inter-600" style={{ color: uploadType === product.slug ? "#C53D3D" : "#1A1C1F" }}>
                                    {product.title}
                                </div>
                                <div className="body-text2 inter-400 text-gray-600">
                                    {product.description}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                )
            })}
            {/* <div className="mt-4 d-flex">
                <Button
                    className="d-flex align-items-center text-white inter-500 body-text2 me-2"
                    style={{ borderRadius: 3 }}
                    onClick={() => {
                        navigate(`/consultation#${productType}`)
                    }}
                >
                    Continue
                </Button>
            </div> */}
        </Row>
    );
};

export default ProductRequirement;
