import ProductListCard from "components/Cards/ProductListCard";
import Pagination from "components/Pagination";
import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { CgSortAz } from "react-icons/cg";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";

const ProductLibrary = () => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";

  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  // let searchparams = new URLSearchParams(document.location.search);
  // let productCategory = searchparams.get("product_category");
  // let productType = searchparams.get("productType");
  // let productSubType = searchparams.get('productSubType')
  // let sortBy = searchparams.get("sort_by");
  // let brand = searchparams.get('brand')

  const navigate = useNavigate()

  const [productCategories, setProductCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productSubTypes, setProductSubTypes] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [brands, setBrands] = useState([])
  const [productCategory, setProductCategory] = useState("");
  const [productType, setProductType] = useState("");
  const [productSubType, setProductSubType] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [brand, setBrand] = useState("");

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchProductsPagewise();
      if (response.data) {
        setProducts(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchProductsByBrand = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchProductsByBrandUser(user?.id);
      if (response.data) {
        setProducts(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchCategories = async () => {
    try {
      const response = await authActions.fetchProductCategories();
      console.log(response);
      if (response?.data) {
        setProductCategories(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };


  const fetchProductTypes = async (categoryId) => {
    try {
      const response = await authActions.fetchProductTypes(categoryId)
      console.log(response);
      if (response?.data) {
        setProductTypes(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const fetchProductSubTypes = async (typeId) => {
    try {
      const response = await authActions.fetchProductSubTypes(typeId)
      console.log(response);
      if (response?.data) {
        setProductSubTypes(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);

    const category = searchParams.get("product_category");
    const type = searchParams.get("productType");
    const subType = searchParams.get("productSubType");
    const sort = searchParams.get("sort_by");
    const brandParam = searchParams.get("brand");

    // Set state based on the URL parameters
    if (category) setProductCategory(category);
    if (type) setProductType(type);
    if (subType) setProductSubType(subType);
    if (sort) setSortBy(sort);
    if (brandParam) setBrand(brandParam);
  }, []);


  const onChangeFilters = (value, type) => {
    // Initialize an object to hold the filters
    const filterParams = {
      category: type === "category" ? value : productCategory,
      product_type: type === "product_type" ? value : productType,
      product_sub_type: type === "product_sub_type" ? value : productSubType,
    };

    // Remove filters with null or empty values
    const queryString = Object.entries(filterParams)
      .filter(([key, val]) => val) // Only include filters with non-null, non-empty values
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&");

    // Navigate to the updated URL
    navigate(`/filtered-products?${queryString}`);
  };


  useEffect(() => {
    if (param1 === "architect") {
      fetchProducts();
    } else if (param1 === "brand") {
      fetchProductsByBrand();
    }
  }, [param1, user]);

  useEffect(() => {
    fetchCategories()
    fetchProductTypes()
    fetchProductSubTypes(user.id)
  }, [user])

  console.log(productTypes);


  return (
    <Container fluid>
      <Container className="mt-5 pt-5">
        <div className="d-flex">
          <div
            className="card m-1"
            style={{
              width: "150px",
              border: "1px solid #BDC1C6",
              borderRadius: "5px",
              cursor: "pointer",
              padding: '0px'
              // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          // onClick={() => onChangeFilters(productCat?.slug, "productCategory")}
          >
            <img
              // src={productCat?.image || "default-image-url"} 
              alt='all products'
              className="card-img-top"
              style={{
                width: '100%',
                height: "100px",
                objectFit: "cover",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                margin: '0px'
              }}
            />
            <div className="card-body text-center p-1">
              <h6 className="card-title text-gray-700 mb-0">All Products</h6>
            </div>
          </div>
          {productCategories.map((productCat, index) => (
            <div
              key={index}
              className="card m-1"
              style={{
                width: "150px",
                border: "1px solid #BDC1C6",
                borderRadius: "5px",
                cursor: "pointer",
                padding: '0px'
                // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
              onClick={() => {
                setProductCategory(productCat.id);
                onChangeFilters(productCat.id, "category");
              }}
            >
              <img
                src={productCat?.image || "default-image-url"} // Replace with the correct image path
                alt={productCat?.title}
                className="card-img-top"
                style={{
                  width: '100%',
                  height: "100px",
                  objectFit: "cover",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                  margin: '0px'
                }}
              />
              <div className="card-body text-center p-1">
                <h6 className="card-title text-gray-700 mb-0">{productCat?.title}</h6>
              </div>
            </div>
          ))}
        </div>

        <div className="body-title my-4">
          <span className="inter-600">Products Available({products?.length})</span>
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex">
            {/* {(productCategory && productCategory !== "null") ?
              <div
                className="d-flex justify-content-center align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
              >
                <span className="me-2">
                  {productCategory}
                </span>
                <div className="cursor-pointer" onClick={() => onChangeFilters(null, "productCategory")}>
                  <IoClose size={20} />
                </div>
              </div>
              :
              <Form.Select
                className="bg-transparent text-gray-700 me-2 cursor-pointer"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                onChange={(e) => onChangeFilters(e.target.value, "productCategory")}
                value={productCategory && productCategory !== "null" ? productCategory : ""}
              >
                <option value="" disabled>
                  Product Category
                </option>
                {productCategories.map((productCat, index) => {
                  return (
                    <option key={index + 1} value={productCat?.slug}>{productCat?.title}</option>
                  )
                })}
              </Form.Select>
            } */}
            {(productType && productType !== "null") ?
              <div
                className="d-flex justify-content-center align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
              >
                <span className="me-2">
                  {productType}
                </span>
                {/* <div className="cursor-pointer" onClick={() => onChangeFilters(null, "productCategory")}>
                  <IoClose size={20} />
                </div> */}
              </div>
              :
              <Form.Select
                className="bg-transparent text-gray-700 me-2 cursor-pointer"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                // onChange={(e) => onChangeFilters(e.target.value, "productType")}
                value={productType && productType !== "null" ? productType : ""}
              >
                <option value="" disabled>
                  Product Type
                </option>
                {productTypes.map((prodType, index) => {
                  return (
                    <option key={index + 1} value={prodType.value}>{prodType.label}</option>
                  )
                })}
              </Form.Select>
            }

            {(productSubType && productSubType !== "null") ?
              <div
                className="d-flex justify-content-center align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
              >
                <span className="me-2">
                  {productSubType}
                </span>
                {/* <div className="cursor-pointer" onClick={() => onChangeFilters(null, "productCategory")}>
                  <IoClose size={20} />
                </div> */}
              </div>
              :
              <Form.Select
                className="bg-transparent text-gray-700 me-2 cursor-pointer"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                // onChange={(e) => onChangeFilters(e.target.value, "productType")}
                value={productSubType && productSubType !== "null" ? productSubType : ""}
              >
                <option value="" disabled>
                  Product Sub Type
                </option>
                {productSubTypes?.map((prodSubType, index) => {
                  return (
                    <option key={index + 1} value={prodSubType.value}>{prodSubType.label}</option>
                  )
                })}
              </Form.Select>
            }
          </div>
          <div>
            <div className="custom-select-wrapper">
              <Form.Select
                className="bg-transparent text-gray-700 cursor-pointer custom-select"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                // onChange={(e) => onChangeFilters(e.target.value, "sort_by")}
                value={sortBy && sortBy !== "null" ? sortBy : ""}
              >
                <option value="" disabled>Sort By</option>
                <option value="created_at">Featured (New to Old)</option>
                <option value="-created_at">Featured (Old to New)</option>
              </Form.Select>
              <CgSortAz size={24} className="custom-dropdown-icon" />
            </div>
          </div>
        </div>
        <div className="mb-3">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ minHeight: "50vh" }}
            >
              <Spinner />
            </div>
          ) : products?.length > 0 ? (
            <div className="d-flex flex-column align-items-center" style={{ minHeight: "80vh" }}>
              <Row className="w-100">
                {products.map((product, index) => (
                  <Col
                    key={index}
                    lg={4}
                    md={6}
                    sm={12}
                    className={`d-flex flex-column py-2 mb-2 justify-content-start ${large ? (index % 3 === 0 ? "ps-0" : index % 3 === 2 ? "pe-0" : "") : medium ? (index % 2 === 0 ? "ps-0" : "pe-0") : "p-0"}`}
                  >
                    <ProductListCard
                      {...product}
                      image={product.media[0]?.file ?? product.product_category_data?.image}
                      product_type={product.product_category_data?.title}
                      className={`cursor-pointer`}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center bg-gray-25 rounded w-100"
              style={{ height: '60vh' }}
            >
              No products available
            </div>
          )}
        </div>
      </Container>
    </Container>
  );
};

export default ProductLibrary;
