import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        // Update state to render fallback UI
        return { hasError: true, error };
    }

    componentDidCatch(error, info) {
        // Log the error to an error tracking service or console
        console.error("Error Boundary Caught an Error:", error, info);
    }

    render() {
        if (this.state.hasError) {
            // Fallback UI when an error occurs
            return (
                <div style={{ textAlign: "center", marginTop: "50px" }}>
                    <h1>Something went wrong.</h1>
                    <p>{this.state.error?.toString()}</p>
                    <button onClick={() => window.location.reload()}>Reload</button>
                </div>
            );
        }

        // Render children if no error
        return this.props.children;
    }
}

export default ErrorBoundary;