import React from "react";
import { FiTrash2 } from "react-icons/fi";

const ProjectReferenceCard = ({ project, className }) => {
  return (
    <div
      className={`d-flex mb-3 ${className}`}
      style={{ borderRadius: 3, border: "1px solid #BDC1C6" }}
    >
      <div className="">
        <img
          className="overflow-hidden"
          src={project?.rooms[0]?.media[0]?.file}
          style={{ maxHeight: 120, maxHeight: 72, objectFit: "cover", borderRadius: "3px 0 0 3px" }}
        />
      </div>
      <div className="d-flex justify-content-between align-items-center w-100 mx-3">
        <div className="d-flex flex-column justify-content-center text-gray-900">
          <div className="body-text1 inter-500 mb-0">
            {project?.project_name}/{project?.bhk}/{project?.property_data?.property_name}
          </div>
          <div className="body-text2 inter-400 d-flex flex-column align-items-start">
            Architect: {project?.company_name}
          </div>
          <div className="body-text2 inter-400 d-flex flex-column align-items-start">
            {project?.property_data?.builder_data && (
              <span className="ms-0">
                Builders: {project?.property_data?.builder_data?.company_name}
              </span>
            )}
          </div>
        </div>
        <FiTrash2 size={20} color="#3C4043" />
      </div>
    </div>
  );
};

export default ProjectReferenceCard;
