import { useState, useEffect } from 'react';

const useScrollBottom = () => {
    const [bottom, setBottom] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = document.querySelector("#infinitescroll");
            if (element) {
                const rect = element.getBoundingClientRect();
                const isVisible = rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                setBottom(isVisible);
            }
        };
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return bottom;
};

export default useScrollBottom;