import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { BuilderAccountEditForm, ChangePasswordForm } from "components";
import Library from "pages/User/Library";

const BuilderPersonalProfile = () => {

  return (
    <Container
      fluid
      className="mt-5 pt-4 px-0"
      style={{ minHeight: "100vh" }}
    >
      <Container>
        <BuilderAccountEditForm />
        {/* <ChangePasswordForm /> */}
        <Library
          showSpace={false}
          showProfessional={false}
        />
      </Container>
    </Container>
  );
};

export default BuilderPersonalProfile;
