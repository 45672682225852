import LoginModal from "components/Auth/LoginModal";
import ShareModal from "components/General/ShareModal";
import React, { useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { FaRegHeart } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import { useSelector } from "react-redux";
import { notify } from "utilities/notifications/Notify";
import * as authActions from "reduxFiles/actions/authActions";
import { useNavigate } from "react-router-dom";


const MobileProjectCard = ({ id,
    slug,
    project_name,
    image,
    bhk,
    builder_name,
    architect,
    property_name,
    isConsultation,
    boxShadow,
    imgHeight = "300px",
    className,
    style, }) => {
    const user = useSelector((state) => state.auth.user);

    const [showShare, setShowShare] = useState(false);
    const [hover, setHover] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [isHomeOwner, setIsHomeOwner] = useState(true);
    const navigate = useNavigate()

    const handleSaveProject = async () => {
        try {
            const response = await authActions.saveProject(user.id, id);
            if (response.data) {
                setIsLiked(true);
                notify("Project saved!", "success");
            } else {
                notify("Failed to save project, try again later", "success");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleUnsaveProject = async () => {
        try {
            const response = await authActions.unsaveProject(user.id, id);
            if (response) {
                setIsLiked(false);
                notify("Project unsaved!", "success");
            } else {
                notify("Failed to unsave project, try again later", "success");
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div
                className="overflow-hidden shadow-sm"
                // style={{
                //     borderRadius: "6px",
                //     border: "1px solid #f8f9fa",
                // }}
                onClick={() => navigate(`/projects/${slug}`)}
            >
                {/* Image Section */}
                <div className="position-relative">
                    <img
                        src={image}
                        alt={project_name}
                        className="img-fluid"
                        style={{ objectFit: "cover", width: "100%", height: "200px" }}
                    />
                    <div
                        className="d-flex position-absolute"
                        style={{ zIndex: 999, right: 15, top: 10 }}
                    >
                        <Button
                            className="d-flex justify-content-center align-items-center share-like-btn p-0"
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowShare(true);
                            }}
                        >
                            <FiSend size={20} />
                        </Button>

                        <Button
                            className={`d-flex justify-content-center align-items-center share-like-btn ms-2 p-0 ${isLiked ? "bg-primary text-white" : ""
                                }`}
                            onClick={(e) => {
                                e.stopPropagation();

                                if (!user) {
                                    setShowLoginModal(true)
                                } else {
                                    isLiked ? handleUnsaveProject() : handleSaveProject();
                                }

                            }}
                        >
                            <FaRegHeart size={20} color={isLiked ? "#FFFFFF" : ""} />
                        </Button>

                    </div>
                </div>

                {/* Content Section */}
                <div className="m-2">
                    <div className="h3 inter-600 text-gray-800 mb-0">{project_name}</div>
                    <div className="body-text1 inter-400 text-gray-800">
                        Architects: {architect}
                    </div>
                    <div className="body-text1 inter-400 text-gray-800text-muted small">
                        Builders: {builder_name}
                    </div>
                </div>
            </div>
            <ShareModal
                show={showShare}
                onHide={() => setShowShare(false)}
                id={id}
                image={image}
                title={`${project_name}/${bhk}/${project_name}`}
                urlPath={`/projects/${slug}`}
            />
            <LoginModal
                showLoginModal={showLoginModal}
                setShowLoginModal={setShowLoginModal}
                isHomeOwner={isHomeOwner}
                setIsHomeOwner={setIsHomeOwner}
            />
        </>
    );
}

export default MobileProjectCard;
