import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import DropFileInputProduct from "components/DragInput/DropFileInputProduct";
import ReactSelect from "react-select";
import LoadingOverlay from "components/General/LoadingOverlay";
import { notify } from "utilities/notifications/Notify";
import * as authActions from "reduxFiles/actions/authActions";
import { FaCircleMinus, FaXmark } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import ProductRequirement from "./ProductRequirement";
import { FiUpload, FiUploadCloud } from "react-icons/fi";
import DropFileInputProject from "components/DragInput/DropFileInputProject";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import DropExcelFileInput from "components/DragInput/DropExcelFileInput";

function AddProduct() {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const { status } = useParams();
  const user = useSelector((state) => state.auth.user);

  const productInfoRef = useRef();
  const [uploadType, setUploadType] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [brandInfo, setBrandInfo] = useState(null)
  const [selectedImages, setSelectedImages] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState({
    uploadType: uploadType,
    product_category: "",
    collection_name: "",
    brand: "",
    product_type: "",
    product_sub_type: "",
    size: "",
    thickness: "",
    weight: "",
    material: "",
    color: "",
    pricing: "",
    surfaceFinish: "",
    description: "",
    features: "",
    brochure: null,
    images: [],
  });

  const [multipleProductDetails, setMultipleProductDetails] = useState({
    uploadType: uploadType,
    product_category: "",
    collection_name: "",
    description: "",
    features: "",
    brochure: null,
    products: []
  });

  const docRef = useRef(null)

  const [productCategories, setProductCategories] = useState([])
  const [productTypes, setProductTypes] = useState([])
  const [productSubTypes, setProductSubTypes] = useState([])
  const [extractedData, setExtractedData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null)


  const fetchCategories = async () => {
    try {
      const response = await authActions.fetchProductCategories();
      console.log(response);
      if (response?.data) {
        setProductCategories(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };


  const handleFileUpload = () => {
    docRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && file.type === "application/pdf") {
      setProductDetails((prevDetails) => ({
        ...prevDetails,
        brochure: file,
      }));
    } else {
      alert("Please upload a valid PDF file")
      e.target.value = null
    }
  };

  const handleFileChange2 = (e) => {
    const file = e.target.files[0];

    if (file && file.type === "application/pdf") {
      setMultipleProductDetails((prevDetails) => ({
        ...prevDetails,
        brochure: file,
      }));
    } else {
      notify("Please upload a valid PDF file", 'error')
      e.target.value = null
    }
  }

  const handleImageChange = (files) => {

    setProductDetails((prevDetails) => ({
      ...prevDetails,
      images: files, // Assuming 'files' is an array of uploaded images
    }));
  };

  const handleExcelAndContinue = async () => {

    if (!selectedFile) {
      notify("Please select a file before continuing!", "error", 1);
      return;
    }

    const formData = new FormData()
    console.log({ selectedFile })
    formData.append("file", selectedFile?.[0])
    console.log({ formData });

    try {
      const response = await authActions.loadAndRetrieveDataFromFile(formData)
      if (response?.data) {
        console.log("Response data from excel: ", response?.data);
        // const data = await response.json()
        setExtractedData(response.data.products);

        setMultipleProductDetails((prevDetails) => ({
          ...prevDetails,
          products: response.data.products, // Populate products field
        }));
      }


      setCurrentStep(prev => prev + 1);

    } catch (e) {
      console.log(e);

    }
  };

  useEffect(() => {
    console.log({ multipleProductDetails });
  }, [multipleProductDetails])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductDetails((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'product_category') {

      triggerProductTypes(value)
    }

    if (name === 'product_type') {
      triggerProductSubTypes(value)
    }
  };

  const triggerProductTypes = async (id) => {
    try {
      const response = await authActions.fetchProductTypes(id)
      console.log(response);
      if (response?.data) {
        setProductTypes(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const triggerProductSubTypes = async (id) => {
    try {
      const response = await authActions.fetchProductSubTypes(id)
      console.log(response);
      if (response?.data) {
        setProductSubTypes(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setMultipleProductDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePricing = (value, type) => {

    const currentPricing = productDetails.pricing.split(" ");
    const price = type === "price" ? value : currentPricing[0] || "";
    const unit = type === "unit" ? value : currentPricing[1] || "Per Sft";
    setProductDetails({ ...productDetails, pricing: `${price} ${unit}` });

  }

  const handleContinue = () => {

    console.log("Saved Product Details:", productDetails);
    setCurrentStep(prev => prev + 1);
  };


  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   setProductDetails({ ...productDetails, brochure: file });
  // };

  const handleSubmitProduct = async (e) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();
    formData.append('uploadType', uploadType);
    formData.append('product_category', productDetails.product_category);
    formData.append('title', productDetails.collection_name);
    formData.append('brand', brandInfo?.id);
    formData.append('product_type', productDetails.product_type);
    formData.append('product_sub_type', productDetails.product_sub_type);
    formData.append('size', productDetails.size);
    formData.append('thickness', productDetails.thickness);
    formData.append('weight', productDetails.weight);
    formData.append('material', productDetails.material);
    formData.append('color', productDetails.color);
    formData.append('pricing', productDetails.pricing);
    formData.append('surfaceFinish', productDetails.surfaceFinish);
    formData.append('description', productDetails.description);
    formData.append('features', productDetails.features);

    // Attach images
    productDetails.images.forEach((image, index) => {
      formData.append('images', image);
    });

    // Attach brochure if exists
    if (productDetails.brochure) {
      formData.append('brochure', productDetails.brochure);
    }

    console.log({ productDetails, uploadType })

    try {
      const response = await authActions.createProduct(formData);
      if (response.status === 201) {
        notify("Product Created!", "success");
        navigate("/brand/product-library");
      }
    } catch (error) {
      notify(error.response.data.msg || "An error occured!", "error", 1);
    }
  }

  const handleSubmitProducts = async (e) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();
    formData.append('uploadType', uploadType);
    formData.append('product_category', multipleProductDetails.product_category);
    formData.append('title', multipleProductDetails.collection_name);
    formData.append('brand', brandInfo?.id);
    formData.append('description', multipleProductDetails.description);
    formData.append('features', multipleProductDetails.features);

    // Attach brochure if it exists
    if (multipleProductDetails.brochure) {
      formData.append('brochure', multipleProductDetails.brochure);
    }

    const productsArray = multipleProductDetails.products.map((product, index) => {
      const productObject = {
        name: product.name,
        product_type: product.product_type,
        product_sub_type: product.product_sub_type,
        size: product.size,
        thickness: product.thickness,
        weight: product.weight,
        material: product.material,
        color: product.color,
        cost: product.cost,
        texture: product.texture,
      };

      // Attach image if it exists
      if (product.image) {
        productObject.image = product.image; // Append the image field
      }

      return productObject;
    });

    formData.append('products', JSON.stringify(productsArray));

    try {
      console.log({ multipleProductDetails, uploadType });

      const response = await authActions.createProduct(formData);
      if (response.status === 201) {
        notify("Products Submitted!", "success");
        navigate("/brand/product-library");
      }
    } catch (error) {
      notify(error.response?.data?.msg || "An error occurred!", "error", 1);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    fetchCategories()
  }, []);

  useEffect(() => {
    const fetchBrandByUser = async () => {
      try {
        const response = await authActions.fetchBrandByUser(user.id);
        if (response.data) {
          if (response.data?.results[0]) {
            setBrandInfo(response.data?.results[0]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchBrandByUser();
  }, [user]);

  return (

    <Container fluid className="mt-5 pt-4 px-0 d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
      {processing && (
        <LoadingOverlay isLoading={processing} setLoading={setProcessing} />
      )}
      <div className="d-flex flex-column flex-lg-row my-md-4" style={large ? { maxWidth: 1000, width: '100%' } : { width: '100%' }}>
        <div className="d-flex justify-content-center px-2 px-lg-0 py-4 py-lg-0" style={{ minWidth: 250 }}>
          <div className="d-flex flex-lg-column mt-2 mt-md-4 mt-lg-3 ml-2 mb-1 px-3 px-lg-0">
            <div className="d-flex align-items-center mb-0 mb-lg-4 me-2">
              <div
                className={`p-1 text-center rounded-btn ${currentStep === 1
                  ? "bg-black text-white"
                  : currentStep === 2 || currentStep === 3 || currentStep === 4
                    ? "bg-success text-white"
                    : "bg-gray-25"
                  }`}
              >
                {currentStep === 1 ? "1" : <FaCheck size={12} />}
              </div>
              <div
                className={`ms-1 ms-lg-2 inter-500 body-text1 ${currentStep !== 1 ? "text-success" : ""
                  }`}
              >
                Upload Type
              </div>
            </div>
            <div className="d-flex align-items-center mb-0 mb-lg-4 me-2">
              <div
                className={`p-1 text-center rounded-btn ${currentStep === 2
                  ? "bg-black text-white"
                  : currentStep === 3 || currentStep === 4
                    ? "bg-success text-white"
                    : "text-gray-300 bg-gray-25 border border-gray-100"
                  }`}
              >
                {currentStep === 1 || currentStep === 2 ? (
                  "2"
                ) : (
                  <FaCheck size={12} />
                )}
              </div>
              <div
                className={`ms-1 ms-lg-2 inter-500 body-text1 ${currentStep !== 1 && currentStep !== 2 ? "text-success" : currentStep === 2 ? "" : "text-gray-300"
                  }`}
              >
                Product Details
              </div>
            </div>
            <div className="d-flex align-items-center mb-0 mb-lg-4 me-2">
              <div
                className={`p-1 text-center rounded-btn ${currentStep === 3
                  ? "bg-black text-white"
                  : currentStep === 4
                    ? "bg-success text-white"
                    : "text-gray-300 bg-gray-25 border border-gray-100"
                  }`}
              >
                {currentStep === 1 || currentStep === 2 || currentStep === 3 ? (
                  "3"
                ) : (
                  <FaCheck size={12} />
                )}
              </div>
              <div
                className={`ms-1 ms-lg-2 inter-500 body-text1 ${currentStep !== 1 && currentStep !== 2 && currentStep !== 3 ? "text-success" : currentStep === 2 ? "" : "text-gray-300"
                  }`}
              >Product Images</div>
            </div>
            <div className="d-flex align-items-center mb-0 mb-lg-4 me-2">
              <div
                className={`p-1 text-center rounded-btn ${currentStep === 4 ? "bg-black text-white" : "text-gray-300 bg-gray-25 border border-gray-100"
                  }`}
              >
                4
              </div>
              <div className={`ms-1 ms-lg-2 inter-500 body-text1 ${currentStep === 4 ? "text-gray-900" : "text-gray-300"}`}>Product Preview</div>
            </div>
          </div>
        </div>
        <div className="p-3 border mx-2 mx-lg0" style={{ borderRadius: 6 }}>
          {currentStep === 1 && (
            <div style={large ? { minWidth: 600 } : {}}>
              <h4 className="inter-600">{currentStep}. Upload Type</h4>
              <ProductRequirement uploadType={uploadType} setUploadType={setUploadType} currentStep={currentStep}
                setCurrentStep={setCurrentStep} />
              <Button
                className="bg-transparent border-gray-300 text-gray-900 body-text2 mt-3"
                style={{ borderRadius: 3, padding: '8px 14px' }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
          )}
          {currentStep == 2 && uploadType === 'single' && (
            <div style={large ? { width: 600 } : {}}>
              <h4 className="inter-600">{currentStep}. Product Preview</h4>
              <Form ref={productInfoRef}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Category<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Select
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Select Category"
                    name="product_category"
                    value={productDetails?.product_category}
                    onChange={handleInputChange}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <option value="">Select</option>
                    {productCategories?.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Collection Name<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    name="collection_name"
                    value={productDetails?.collection_name}
                    onChange={handleInputChange}
                    placeholder="Luxury Laminates"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Name<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    name="brand"
                    value={productDetails?.brand}
                    onChange={handleInputChange}
                    placeholder="Sb 1806"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Type<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Select
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Select Product Type"
                    name="product_type"
                    value={productDetails?.product_type}
                    onChange={handleInputChange}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <option value="">Select</option>
                    {productTypes?.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Sub-Type<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Select
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Select Product Sub Type"
                    name="product_sub_type"
                    value={productDetails?.product_sub_type}
                    onChange={handleInputChange}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <option value="">Select</option>
                    {productSubTypes?.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <div className="d-flex w-100 mb-3">
                  <Form.Group className="w-50">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Product Size
                    </Form.Label>
                    <Form.Control

                      className="py-2 body-text2"
                      type="number"
                      name="size"
                      value={productDetails?.size}
                      onChange={handleInputChange}
                      placeholder="Size"
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                  </Form.Group>
                  <Form.Group className="w-50 ms-3">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Thickness
                    </Form.Label>
                    <Form.Control

                      className="py-2 body-text2"
                      type="number"
                      name="thickness"
                      value={productDetails?.thickness}
                      onChange={handleInputChange}
                      placeholder="Thickness"
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                  </Form.Group>
                  <Form.Group className="w-50 ms-3">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Weight
                    </Form.Label>
                    <Form.Control
                      className="py-2 body-text2"
                      type="number"
                      name="weight"
                      value={productDetails?.weight}
                      onChange={handleInputChange}
                      placeholder="Weight"
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                  </Form.Group>
                </div>
                <div className="d-flex w-100 mb-3">
                  <Form.Group className="w-50">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Core Material
                    </Form.Label>
                    <Form.Control

                      className="py-2 body-text2"
                      type="text"
                      name="material"
                      value={productDetails?.material}
                      onChange={handleInputChange}
                      placeholder="Chandelier"
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                  </Form.Group>
                  <Form.Group className="w-50 ms-3">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Product Color
                    </Form.Label>
                    <Form.Control

                      className="py-2 body-text2"
                      type="text"
                      name="color"
                      value={productDetails.color}
                      onChange={handleInputChange}
                      // placeholder="Chandelier"
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                  </Form.Group>
                  <Form.Group className="w-50 ms-3">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Surface Finish/Texture
                    </Form.Label>
                    <Form.Control
                      className="py-2 body-text2"
                      type="text"
                      name="surfaceFinish"
                      value={productDetails?.surfaceFinish}
                      onChange={handleInputChange}
                      placeholder="Chandelier"
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                  </Form.Group>
                </div>
                <div className="w-100 mb-3">
                  <Form.Group>
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Pricing
                    </Form.Label>
                    <InputGroup className="d-flex">
                      <div className="d-flex flex-grow-1" style={{ flexBasis: "85%" }}>
                        <InputGroup.Text className="body-text2 inter-500">Rs</InputGroup.Text>
                        <Form.Control
                          className="body-text2 inter-500"
                          style={{ border: "1px solid #BDC1C6", flexGrow: 1 }}
                          type="number"
                          placeholder="300"
                          value={productDetails.pricing.split(" ")[0]} // Extract price
                          onChange={(e) => handlePricing(e.target.value, "price")}
                        />
                      </div>
                      <Form.Select
                        className="body-text3 inter-500 bg-gray-50 text-gray-700"
                        style={{ border: "1px solid #BDC1C6", flexBasis: "15%" }}
                        value={productDetails.pricing.split(" ")[1] || "Per Sft"} // Extract unit
                        onChange={(e) => handlePricing(e.target.value, "unit")}
                      >
                        <option value="Per Sft">Per Sft</option>
                        <option value="Per Unit">Per Unit</option>
                        <option value="Per Kg">Per Kg</option>
                      </Form.Select>
                    </InputGroup>

                  </Form.Group>
                </div>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label className="body-text2 inter-500">
                    Brochure/Product Document
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="No document uploaded"
                      name="brochure"
                      value={productDetails?.brochure ? productDetails.brochure.name : ""}
                      readOnly
                      className="body-text2 inter-500"
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                    <Button
                      className="d-flex align-items-center body-text3 inter-500 bg-gray-50 text-gray-700"
                      onClick={handleFileUpload}
                    >
                      Upload Document
                      <FiUpload className="ms-1" />
                    </Button>
                    <input
                      type="file"
                      ref={docRef}
                      accept=".pdf"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Description
                  </Form.Label>
                  <Form.Control

                    className="py-2 body-text2"
                    as="textarea"
                    rows={3}
                    name="description"
                    value={productDetails.description}
                    onChange={(e) => handleInputChange(e)}
                    placeholder="Enter your product description here."

                    // defaultValue={projectDetails?.project_description}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3, resize: 'none' }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Features
                  </Form.Label>
                  <Form.Control
                    className="py-2 body-text2"
                    as="textarea"
                    rows={3}
                    name="features"
                    value={productDetails.features}
                    onChange={(e) => handleInputChange(e)}
                    placeholder="Enter your product features here."
                    // defaultValue={projectDetails?.project_description}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3, resize: 'none' }}
                  />
                </Form.Group>
              </Form>
              <div className="d-flex">
                <Button
                  className="d-flex align-items-center text-white body-text2 me-2"
                  style={{ borderRadius: 3 }}
                  onClick={handleContinue}
                >
                  Continue
                </Button>
                <Button
                  className="bg-transparent border-gray-200 text-gray-900 body-text2"
                  style={{ borderRadius: 3 }}
                  onClick={() => setCurrentStep(1)}
                >
                  Back
                </Button>
              </div>
            </div>
          )}
          {currentStep == 2 && uploadType === 'multiple' && (
            <div style={large ? { width: 600 } : {}}>
              <h4 className="inter-600">{currentStep}. Product Details</h4>
              <Form ref={productInfoRef}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Category<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Select
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Select Layout"
                    name="product_category"
                    value={multipleProductDetails?.product_category}
                    onChange={(e) => { handleInputChange2(e) }}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <option value="">Select</option>
                    {productCategories?.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Collection Name<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    name="collection_name"
                    value={multipleProductDetails?.collection_name}
                    onChange={(e) => { handleInputChange2(e) }}
                    placeholder="Luxury Laminates"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  />
                </Form.Group>

                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label className="body-text2 inter-500">
                    Brochure/Product Document <span className="text-danger">*</span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="No document uploaded"
                      name="brochure"
                      value={multipleProductDetails?.brochure ? multipleProductDetails.brochure.name : ""}
                      readOnly
                      className="body-text2 inter-500"
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                    <Button
                      className="d-flex align-items-center body-text3 inter-500 bg-gray-50 text-gray-700 border-none"
                      onClick={handleFileUpload}
                    >
                      Upload Document
                      <FiUpload className="ms-1" />
                    </Button>
                    <input
                      type="file"
                      ref={docRef}
                      accept=".pdf"
                      style={{ display: "none" }}
                      onChange={handleFileChange2}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Description<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    as="textarea"
                    rows={3}
                    name="description"
                    placeholder="Describe the project..."
                    value={multipleProductDetails?.description}
                    onChange={(e) => { handleInputChange2(e) }}
                    // defaultValue={projectDetails?.project_description}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3, resize: 'none' }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Features<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    as="textarea"
                    rows={3}
                    name="features"
                    placeholder="Add project features"
                    value={multipleProductDetails?.features}
                    onChange={(e) => { handleInputChange2(e) }}
                    // defaultValue={projectDetails?.project_description}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3, resize: 'none' }}
                  />
                </Form.Group>
              </Form>
              <div className="d-flex">
                <Button
                  className="d-flex align-items-center text-white body-text2 me-2"
                  style={{ borderRadius: 3 }}
                  onClick={() => setCurrentStep(3)}
                >
                  Continue
                </Button>
                <Button
                  className="bg-transparent border-gray-200 text-gray-900 body-text2"
                  style={{ borderRadius: 3 }}
                  onClick={() => setCurrentStep(1)}
                >
                  Back
                </Button>
              </div>
            </div>
          )}
          {currentStep == 3 && uploadType == 'single' && (
            <div style={large ? { width: 600 } : {}}>
              <h4 className="inter-600">{currentStep}. Product Images</h4>
              <div className="inter-400 body-text3 text-gray-600 mb-1">Tip: Please upload the images (.jpg/.jpeg/.png) relevant to your product only.</div>
              <DropFileInputProduct onFileChange={handleImageChange}
              />
              <div className="d-flex mt-3">
                <Button
                  className="d-flex align-items-center text-white body-text2 me-2"
                  style={{ borderRadius: 3 }}
                  onClick={handleContinue}
                >
                  Continue
                </Button>
                <Button
                  className="bg-transparent border-gray-200 text-gray-900 body-text2"
                  style={{ borderRadius: 3 }}
                  onClick={() => setCurrentStep(2)}
                >
                  Back
                </Button>
              </div>
            </div>
          )}

          {currentStep == 3 && uploadType == 'multiple' && (
            <div style={large ? { width: 600 } : {}}>
              <h4 className="inter-600">{currentStep}. Product Images</h4>
              <div className="inter-400 body-text3 text-gray-600 mb-1">Tip: Please upload our template excel (.csv) file to add all your products.</div>
              <DropExcelFileInput onFileChange={(file) => { setSelectedFile(file) }}
              />
              <div className="mt-1 body-text3">
                <p className="text-gray-600">
                  Don't have the CSV format?{" "}
                  <a
                    href="/format.xlsx"
                    download="format.xlsx"
                    className="text-primary cursor-pointer"
                    style={{ textDecoration: 'none' }}
                  >
                    Download Format Here
                  </a>
                </p>
              </div>
              <div className="d-flex mt-3">
                <Button
                  className="d-flex align-items-center text-white body-text2 me-2"
                  style={{ borderRadius: 3 }}
                  onClick={handleExcelAndContinue}
                >
                  Continue
                </Button>
                <Button
                  className="bg-transparent border-gray-200 text-gray-900 body-text2"
                  style={{ borderRadius: 3 }}
                  onClick={() => setCurrentStep(2)}
                >
                  Back
                </Button>
              </div>
            </div>
          )}
          {/* {currentStep == 4 && uploadType === 'single' && (
            <div style={large ? { width: 600 } : {}}>
              <h4 className="inter-600">{currentStep}. Product Preview</h4>
              <div className="d-flex">
                <Button
                  className="d-flex align-items-center text-white body-text2 me-2"
                  style={{ borderRadius: 3 }}
                  onClick={handleSubmitProduct}
                >
                  Submit Product
                </Button>
                <Button
                  className="bg-transparent border-gray-200 text-gray-900 body-text2"
                  style={{ borderRadius: 3 }}
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  Back
                </Button>
              </div>
            </div>
          )} */}
          {currentStep == 4 && uploadType === 'single' && (
            <div style={large ? { width: 600 } : {}}>
              <h4 className="inter-600">{currentStep}. Product Preview</h4>

              {/* Product Image Section */}
              <img
                src={productDetails.images[0] ? URL.createObjectURL(productDetails.images[0]) : ""}
                alt="Product"
                style={{
                  width: "100%",
                  maxHeight: "300px",
                  objectFit: "cover",
                  borderRadius: "6px",
                }}
              />
              <div className="position-relative my-3 d-flex align-items-cen">
                <div className="h4 inter-600 text-gray-900">Product Details</div>
                <button
                  className="position-absolute btn btn-light"
                  onClick={() => setCurrentStep(1)}
                  style={{ top: "10px", right: "10px", borderRadius: "50" }}
                >
                  <RiEditLine />
                </button>
              </div>

              {/* Product Details Section */}
              <div className="mb-3">
                <p className="body-text1 inter-400 text-gray-900">Product Category:   {productCategories.find((category) => category.id === productDetails.product_category)?.title || ""}
                </p>
                <p className="body-text1 inter-400 text-gray-900">Collection Name: {productDetails.collection_name}</p>
                <p className="body-text1 inter-400 text-gray-900">Product Name: {productDetails.brand}</p>
                <p className="body-text1 inter-400 text-gray-900">Product Type: {productTypes.find((type) => type.id === productDetails.product_type)?.title || ""}</p>
                <p className="body-text1 inter-400 text-gray-900">Product Sub-Type: {productSubTypes.find((subtype) => subtype.id === productDetails.product_sub_type)?.title || ""}</p>
                <hr />
                <p className="body-text1 inter-400 text-gray-900">Product Size: {productDetails.size}</p>
                <p className="body-text1 inter-400 text-gray-900">Thickness: {productDetails.thickness}</p>
                <p className="body-text1 inter-400 text-gray-900">Weight: {productDetails.weight || "--"}</p>
                <p className="body-text1 inter-400 text-gray-900">Core Material: {productDetails.material}</p>
                <p className="body-text1 inter-400 text-gray-900">Product Color: {productDetails.color}</p>
                <p className="body-text1 inter-400 text-gray-900">Surface Finish/Texture: {productDetails.surfaceFinish}</p>
                <p className="body-text1 inter-400 text-gray-900">Pricing: {productDetails.pricing}</p>
                <hr />
              </div>

              {/* Product Description */}
              <h5 className="body-text1 inter-400 text-gray-900">Product Description</h5>
              <p className="body-text1 inter-400 text-gray-900">{productDetails.description}</p>

              {/* Product Features */}
              <div className="body-text1 inter-400 text-gray-900">Product Features</div>
              <ul className="body-text1 inter-400 text-gray-900">
                {productDetails.features.split(",").map((feature, index) => (
                  <li key={index}>{feature.trim()}</li>
                ))}
              </ul>

              {/* Product Brochure */}
              <h5 className="body-text1 inter-400 text-gray-900">Product Brochure</h5>
              <div
                className="d-flex flex-column justify-content-center align-items-start"
                style={{
                  padding: "0.5rem",
                  borderRadius: 5,
                  height: '100%', // Optional: ensure the container has a height to properly center vertically
                }}
              >
                <div className="d-flex justify-content-between w-100 align-items-center">
                  <div className="d-flex flex-column ">
                    <span className="body-text2 inter-400 text-gray-900">
                      {productDetails.brochure?.name || "No document uploaded"}
                    </span>
                    {productDetails.brochure && (
                      <span className="inter-400 text-gray-600" style={{ fontSize: '10px' }}>
                        {(productDetails.brochure.size / 1024).toFixed(2)} KB
                      </span>
                    )}
                  </div>

                  {/* Delete Icon */}
                  {productDetails.brochure && (
                    <RiDeleteBin6Line className="align-self-center" style={{ cursor: 'pointer' }} />
                  )}
                </div>
              </div>



              {/* Submit and Back Buttons */}
              <div className="d-flex mt-3">
                <Button
                  className="d-flex align-items-center text-white body-text2 me-2"
                  style={{ borderRadius: 3 }}
                  onClick={handleSubmitProduct}
                >
                  Submit Product
                </Button>
                <Button
                  className="bg-transparent border-gray-200 text-gray-900 body-text2"
                  style={{ borderRadius: 3 }}
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  Back
                </Button>
              </div>
            </div>
          )}

          {currentStep == 4 && uploadType === 'multiple' && (
            <div style={large ? { width: 600 } : {}}>
              <div className="h4 inter-600">{currentStep}. Product Preview</div>
              <div className="position-relative my-3 d-flex align-items-center">
                <button
                  className="position-absolute btn btn-light"
                  onClick={() => setCurrentStep(1)}
                  style={{ top: "10px", right: "10px", borderRadius: "50" }}
                >
                  <RiEditLine />
                </button>
              </div>

              <p className="body-text1 inter-400 text-gray-900">Product Category:   {productCategories.find((category) => category.id === multipleProductDetails.product_category)?.title || ""}</p>
              <p className="body-text1 inter-400 text-gray-900">Collection Name: {multipleProductDetails.collection_name}</p>
              <hr />
              <div className="body-text1 inter-400 text-gray-900">Product Features</div>
              <ul className="body-text1 inter-400 text-gray-900">
                {multipleProductDetails.features.split(",").map((feature, index) => (
                  <li key={index}>{feature.trim()}</li>
                ))}
              </ul>

              <h5 className="body-text1 inter-400 text-gray-900">Product Brochure</h5>
              <div
                className="d-flex flex-column justify-content-center align-items-start"
                style={{
                  padding: "0.5rem",
                  borderRadius: 5,
                  height: '100%', // Optional: ensure the container has a height to properly center vertically
                }}
              >
                <div className="d-flex justify-content-between w-100 align-items-center">
                  <div className="d-flex flex-column ">
                    <span className="body-text2 inter-400 text-gray-900">
                      {multipleProductDetails.brochure?.name || "No document uploaded"}
                    </span>
                    {productDetails.brochure && (
                      <span className="inter-400 text-gray-600" style={{ fontSize: '10px' }}>
                        {(multipleProductDetails.brochure.size / 1024).toFixed(2)} KB
                      </span>
                    )}
                  </div>

                  {/* Delete Icon */}
                  {productDetails.brochure && (
                    <RiDeleteBin6Line className="align-self-center" style={{ cursor: 'pointer' }} />
                  )}
                </div>
              </div>

              <Table bordered hover responsive>
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Sub-Type</th>
                    <th>Size</th>
                    <th>Thickness</th>
                    <th>Pricing</th>
                    <th>Material</th>
                    <th>Color</th>
                    <th>Texture</th>
                    <th>Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {extractedData.map((product, index) => {
                    console.log(product)
                    return (<tr key={index}>
                      <td>
                        <img
                          src={`data:image/png;base64,${product.image}`}
                          alt={product.name}
                          style={{ width: 50, height: 50, borderRadius: 5 }}
                        />
                      </td>
                      <td>{product.name}</td>
                      <td>{product.product_type}</td>
                      <td>{product.product_sub_type}</td>
                      <td>{product.size}</td>
                      <td>{product.thickness}</td>
                      <td>{product.weight}</td>
                      <td>{product.material}</td>
                      <td>{product.color}</td>
                      <td>{product.texture}</td>
                      <td>{product.cost}</td>
                    </tr>)
                  })}
                </tbody>
              </Table>
              <div className="d-flex mt-3">
                <Button
                  className="d-flex align-items-center text-white body-text2 me-2"
                  style={{ borderRadius: 3 }}
                  onClick={handleSubmitProducts}
                >
                  Submit Products
                </Button>
                <Button
                  className="bg-transparent border-gray-200 text-gray-900 body-text2"
                  style={{ borderRadius: 3 }}
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  Back
                </Button>
              </div>
            </div>)}

        </div>
      </div>
    </Container>
  );
}

export default AddProduct;
