import React, { useState } from 'react';
import { Card, Image, Button, Modal } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const TeamMember = ({ id, name, role, image, onDelete, user, handleDelete }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const authUser = useSelector(state => state.auth.user);

  const handleDeleteClick = () => setShowConfirmModal(true);
  const handleCancelDelete = () => setShowConfirmModal(false);
  const handleConfirmDelete = async() => {
    await handleDelete(id);
    setShowConfirmModal(false);
  };

  return (
    <div className="inter-400 text-center pe-4 position-relative">
      {/* Trash Button */}
      {authUser?.id == user && <div
        size="sm"
        className="position-absolute"
        style={{ top: -10, right: -20, zIndex: 2, width: '100px', height: '100px' }}
        
      >
        <FaTrash onClick={handleDeleteClick} className='text-secondary'/>
      </div>}

      {/* Profile Image */}
      <Image
        src={image}
        roundedCircle
        style={{ width: '100px', height: '100px', border: '1px solid #E8EAED' }}
        alt={`${name}'s profile`}
      />
      <Card.Body>
        <Card.Title className="body-title" style={{ marginTop: '10px' }}>
          {name}
        </Card.Title>
        <Card.Text className="text-muted body-text-2">{role}</Card.Text>
      </Card.Body>

      {/* Confirmation Modal */}
      <Modal
        show={showConfirmModal}
        onHide={handleCancelDelete}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this team member?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() =>handleConfirmDelete()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TeamMember;
