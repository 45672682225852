import React from 'react';
import { Spinner } from 'react-bootstrap';

const InfiniteScroll = ({ bottom, noNextItem }) => {
    return (
        <>
            <div id="infinitescroll" style={{ width: "100%", height: 10, background: "#FFFFFF" }} />
            {bottom && !noNextItem && <div className="d-flex justify-content-center align-items-center mt-3 mb-5">
                <Spinner size='lg' />
            </div>}
        </>
    )
}

export default InfiniteScroll