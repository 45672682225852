import axios from "axios";
import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import { FaTrash } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { notify } from "utilities/notifications/Notify";

// const items = [
//   { type: "image", src: "https://via.placeholder.com/600x400?text=Image+1" },
//   { type: "image", src: "https://via.placeholder.com/150?text=Image+2" },
//   { type: "video", src: "https://www.w3schools.com/html/mov_bbb.mp4" },
//   { type: "image", src: "https://via.placeholder.com/150?text=Image+4" },
//   { type: "image", src: "https://via.placeholder.com/150?text=Image+5" },
//   { type: "video", src: "https://www.w3schools.com/html/movie.mp4" },
//   { type: "image", src: "https://via.placeholder.com/150?text=Image+7" },
//   { type: "image", src: "https://via.placeholder.com/150?text=Image+8" },
//   { type: "video", src: "https://www.w3schools.com/html/mov_bbb.mp4" },
//   { type: "image", src: "https://via.placeholder.com/150?text=Image+10" },
//   { type: "image", src: "https://via.placeholder.com/150?text=Image+11" },
//   { type: "video", src: "https://www.w3schools.com/html/movie.mp4" },
//   { type: "image", src: "https://via.placeholder.com/150?text=Image+13" },
//   { type: "video", src: "https://www.w3schools.com/html/mov_bbb.mp4" },
//   { type: "image", src: "https://via.placeholder.com/150?text=Image+15" },
// ];

const Gallery = ({ items, fetchGalleries, enableEdit=false }) => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const authUser = useSelector((state) => state.auth.user);

  const handleAddMediaClick = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleSubmit = async () => {
    if (!selectedFiles.length) {
      notify("Please select files to upload.", "error");
      return;
    }

    const formData = new FormData();
    formData.append("user_id", authUser.id);
    Array.from(selectedFiles).forEach((file) => formData.append("files", file));

    setIsSubmitting(true);
    try {
      const response = await axios.post(
        "/userprofile/rest/gallery/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        notify("Files uploaded successfully!", "success");
        setShowModal(false);
        fetchGalleries();
        // Optionally, refresh the gallery items here
      } else {
        notify("Failed to upload files.", "error");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      notify("An error occurred while uploading files", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    if (!itemToDelete) return;

    try {
      const response = await axios.delete(
        `/userprofile/rest/gallery/${itemToDelete.id}/`
      );
      if (response.status === 204) {
        notify("Media deleted successfully!", "success");
        fetchGalleries(); // Refresh the gallery items
      } else {
        notify("Failed to delete media.", "error");
      }
    } catch (error) {
      console.error("Error deleting media:", error);
      notify("An error occurred while deleting media", "error");
    } finally {
      setShowDeleteModal(false);
      setItemToDelete(null);
    }
  };


  return (
    <Container fluid className="p-0">
      {/* First Gallery Item */}
      {items?.length > 0 && (
        <Row className="mb-3">
          <Col xs={12} md={6}>
            <Card className="h-100 p-0 overflow-hidden position-relative">
              {authUser?.id == items[0].user && <Button
                variant="danger"
                size="sm"
                className="position-absolute"
                style={{ top: "5px", right: "5px", zIndex: 10 }}
                onClick={() => handleDeleteClick(items[0])}
              >
                <FaTrash />
              </Button>}
              {items[0].media_type === "image" ? (
                <Card.Img
                  variant="top"
                  src={items[0].file}
                  style={{ objectFit: "cover", height: "300px" }}
                />
              ) : (
                <video
                  controls
                  style={{ width: "100%", height: "300px", objectFit: "cover" }}
                >
                  <source src={items[0].file} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              {items?.slice(1, 5).map((item, index) => (
                <Col xs={6} key={index}>
                  <Card className="mb-3 p-0 overflow-hidden position-relative">
                    {authUser?.id == item.user && <Button
                      variant="danger"
                      size="sm"
                      className="position-absolute"
                      style={{ top: "5px", right: "5px", zIndex: 10 }}
                      onClick={() => handleDeleteClick(item)}
                    >
                      <FaTrash />
                    </Button>}
                    {item.media_type === "image" ? (
                      <Card.Img
                        variant="top"
                        src={item.file}
                        style={{ objectFit: "cover", height: "140px" }}
                      />
                    ) : (
                      <video
                        controls
                        style={{
                          width: "100%",
                          height: "140px",
                          objectFit: "cover",
                        }}
                      >
                        <source src={item.file} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      )}

      {/* Additional Gallery Items */}
      <Row>
        {items?.slice(5).map((item, index) => (
          <Col xs={6} md={3} key={index}>
            <Card className="mb-3 p-0 overflow-hidden position-relative">
              {authUser?.id == item.user && <Button
                variant="danger"
                size="sm"
                className="position-absolute"
                style={{ top: "5px", right: "5px", zIndex: 10 }}
                onClick={() => handleDeleteClick(item)}
              >
                <FaTrash />
              </Button>}
              {item.media_type === "image" ? (
                <Card.Img
                  variant="top"
                  src={item.file}
                  style={{ objectFit: "cover", height: "140px" }}
                />
              ) : (
                <video
                  controls
                  style={{
                    width: "100%",
                    height: "140px",
                    objectFit: "cover",
                  }}
                >
                  <source src={item.file} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </Card>
          </Col>
        ))}

        {/* Add Media Card */}
        {enableEdit && <Col xs={6} md={3}>
          <Card
            className="d-flex align-items-center justify-content-center mb-3"
            style={{
              height: "140px",
              cursor: "pointer",
              border: "2px dashed #aaa",
            }}
            onClick={handleAddMediaClick}
          >
            <Card.Body className="text-center d-flex align-items-center">
              <span>+ Add Media</span>
            </Card.Body>
          </Card>
        </Col>}
      </Row>

      {/* Modal for Adding Media */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Media</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Upload Images or Videos</Form.Label>
              <Form.Control
                type="file"
                multiple
                accept="image/*,video/*"
                onChange={handleFileChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Uploading..." : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Delete Confirmation */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this media?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Gallery;
