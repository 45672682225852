import { interiokeys_full_logo, interiokeys_logo } from "assets";
import axios from "axios";
import LoginModal from "components/Auth/LoginModal";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row, Spinner } from "react-bootstrap";
import { FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";

const Footer = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [projects, setProjects] = useState([]);
  const [roomCategories, setRoomCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [professionalTypes, setProfessionalTypes] = useState([]);
  const [builders, setBuilders] = useState();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hovered, setHovered] = useState(false);
  const [partnerHovered, setPartnerHovered] = useState(false);
  const [servicesHovered, setServicesHovered] = useState(false);
  const [projectHovered, setProjectHovered] = useState(false);
  const [brandHovered, setBrandHovered] = useState(false);
  const [spaceHovered, setSpaceHovered] = useState(false);
  const [designerHovered, setDesignerHovered] = useState(false);
  const [builderHovered, setBuilderHovered] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const company_links = [
    { id: 11, title: "About Us", link: "about-us" },
    { id: 12, title: "Contact Us", link: "contact-us" },
    { id: 13, title: "Terms & Privacy", link: "terms-and-privacy" },
    { id: 14, title: "Our Blogs", link: "blogs" },
    { id: 15, title: "Careers", link: "careers" },
    { id: 16, title: "Trademark", link: "trademark" },
    { id: 17, title: "Why Choose Us", link: "why-choose-us" },

  ];

  const partner_with_us = [
    {
      id: 21,
      title: "Join as Professional",
      link: "signup#professional|architect",
    },
    { id: 22, title: "Join as Builder", link: "signup#professional|builder" },
    { id: 23, title: "Join as Brand", link: "signup#professional|brand" },
    { id: 24, title: "Join as OEM’s", link: "signup#professional|others" },
    { id: 25, title: "Join as PMC", link: "signup#professional|others" },
    { id: 26, title: "Join as Contractor", link: "signup#professional|others" },
  ];

  const services = [
    { id: 31, title: "Book Consultation", link: "consultation" },
    { id: 32, title: "Real Inspirations", link: "projects" },
    { id: 33, title: "Project Drawings", link: "project-drawings" },
  ];

  const mobile_footer_links = [
    { id: 41, title: "Inspiration", link: "projects" },
    { id: 42, title: "Brands", link: "signup#professional|brand" },
    { id: 43, title: "Designers", link: "signup#professional|other" },
    { id: 44, title: "Builders", link: "signup#professional|builder" },
    { id: 45, title: "Company", link: "about-us" },
    { id: 46, title: "Partner with Us", link: "partner" },
    { id: 47, title: "Services", link: "#" },
  ];

  const fetchProjects = async () => {
    try {
      const response = await authActions.fetchRecommendedProjects();
      if (response.data) {
        setProjects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRoomCategories = async () => {
    try {
      const response = await authActions.fetchRoomCategoriesData();
      if (response.data) {
        setRoomCategories(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBrandCategoriesByTypes = async () => {
    try {
      const response = await authActions.fetchBrandCategoriesByTypes();
      if (response.data) {
        setBrands([
          {
            id: 1,
            title: "Timber, Ply & Laminates",
            data: response.data.timbers_plywood_and_laminates,
          },
          { id: 2, title: "Flooring", data: response.data.flooring },
          { id: 3, title: "Other Brands", data: response.data.others },
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProfessionalsByUserSubType = async () => {
    const userSubTypes = [
      "architecture-firm",
      "interior-designers",
      "architects",
    ];
    const queryString = userSubTypes
      .map((slug) => `slugs=${encodeURIComponent(slug)}`)
      .join("&");
    try {
      const response = await authActions.fetchProfessionalsByUserSubType(
        queryString
      );
      if (response.data) {
        setProfessionalTypes([
          {
            id: 1,
            title: "Architecture Firm",
            data: response.data["architecture-firm"],
          },
          {
            id: 2,
            title: "Interior Designers",
            data: response.data["interior-designers"],
          },
          { id: 3, title: "Architects", data: response.data.architects },
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBuilders = async () => {
    try {
      const response = await authActions.fetchBuilders();
      if (response.data) {
        setBuilders(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchRoomCategories();
    fetchBrandCategoriesByTypes();
    fetchProfessionalsByUserSubType();
    fetchBuilders();
  }, []);

  const handleSendEmail = async () => {
    if (!email) {
      setMessage("Please enter a valid email address.");
      return;
    }

    try {
      setLoading(true);
      setMessage("");

      // Make API call to send email
      const response = await axios.post("/marketing/notify/", { email });

      if (response.status === 201) {
        setMessage("Email sent successfully! Thank you for subscribing.");
        notify(
          "Email sent successfully! Thank you for subscribing.",
          "success"
        );
        setEmail("");
      } else {
        setMessage("Something went wrong. Please try again.");
        notify("Something went wrong. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setMessage("Error sending email. Please try again.");
      notify("Error sending email. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <footer className="bg-gray-25">
      {small ? <Container>
        <Row className="justify-content-between py-5">
          <Col lg={4}>
            <div className="h3 inter-700">
              <Image
                src={interiokeys_full_logo}
                alt="Interiokeys logo"
                style={{ maxWidth: 150 }}
              />
            </div>
            <div className="mt-4">
              <div className="text-primary-500 body-title inter-600">
                Want to understand how our process works?
              </div>
              <div className="text-gray-600 body-text2 inter-400 mb-2">
                Get our PDF sent to your Email ID for the step-by-step explanation.
              </div>
              <div className="body-text2 d-flex flex-row border rounded p-1 mb-2 bg-white">
                <Form.Control
                  className="body-text2 geist w-50 flex-grow border-0"
                  size="sm"
                  placeholder="yourname@gmail.com"
                  style={{
                    padding: "10px",
                  }}
                ></Form.Control>
                <Button size="sm" className="text-white py-1" style={{ width: 100 }}>
                  Send Email
                </Button>
              </div>
            </div>
            <div>
              <a href="https://facebook.com/interiokeys" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={24} color="#1877F2" />
              </a>
              <a href="https://linkedin.com/company/interiokeys" target="_blank" rel="noopener noreferrer" className="mx-3">
                <FaLinkedin size={24} color="#0A66C2" />
              </a>
              <a href="https://instagram.com/interiokeys" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={24} />
              </a>
              <a href="https://youtube.com/@Interiokeys" target="_blank" rel="noopener noreferrer" className="mx-3">
                <FaYoutube size={24} color="#FF0302" />
              </a>
            </div>
            {!user || user.user_type === 'customer' ? <Row className="mt-5">
              <Col>
                <div className="text-gray-900 body-title inter-600 text-primary">Company</div>
                <div className="mt-1">
                  {company_links.map((company_link, index) => {
                    return (
                      <a
                        key={index + 1}
                        href={`${company_link.link}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-decoration-none"
                        onMouseEnter={() => {
                          setHoveredIndex(company_link.id)
                          setHovered(prev => !prev)
                        }
                        }
                        onMouseLeave={() => {
                          setHoveredIndex(null)
                          setHovered(prev => !prev)
                        }}
                      >
                        <p className={`body-text2 inter-400 mb-0 ${(hoveredIndex === company_link.id) && hovered ? 'text-primary' : 'text-gray-900'}`} >{company_link.title}</p>
                      </a>
                    )
                  })}
                </div>
              </Col>
              <Col>
                <div className="text-gray-900 body-title inter-600 text-primary">Partner With Us</div>
                <div className="mt-1">
                  {partner_with_us.map((partner, index) => {
                    return (
                      <a
                        key={index + 1}
                        href={`${partner.link}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-decoration-none"
                        onMouseEnter={() => {
                          setHoveredIndex(partner.id)
                          setPartnerHovered(prev => !prev)
                        }
                        }
                        onMouseLeave={() => {
                          setHoveredIndex(null)
                          setPartnerHovered(prev => !prev)
                        }}
                      >
                        <p className={`body-text2 inter-400 mb-0 ${(hoveredIndex === partner.id) && partnerHovered ? 'text-primary' : 'text-gray-900'}`}>{partner.title}</p>
                      </a>
                    )
                  })}
                </div>
              </Col>
              <Col>
                <div className="text-gray-900 body-title inter-600 text-primary">Services</div>
                <div className="mt-1">
                  {services.map((service, index) => {
                    return (
                      <a
                        key={index + 1}
                        href={`${service.link}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-decoration-none"
                        onMouseEnter={() => {
                          setHoveredIndex(service.id)
                          setServicesHovered(prev => !prev)
                        }
                        }
                        onMouseLeave={() => {
                          setHoveredIndex(null)
                          setServicesHovered(prev => !prev)
                        }}
                      >
                        <p className={`body-text2 inter-400 mb-0 ${(hoveredIndex === service.id) && servicesHovered ? 'text-primary' : 'text-gray-900'}`}>{service.title}</p>
                      </a>
                    )
                  })}
                </div>
              </Col>
            </Row> : <></>}
          </Col>
          {
            !user || user.user_type === 'customer' ? <>
              {small && (
                <Col className="d-flex justify-content-end">
                  <div className="me-5">
                    <div className="text-gray-900 body-title inter-600 text-primary">Inspirations</div>
                    <div className="mt-1 mb-2">
                      <div className="text-gray-900 body-text2 inter-600">Projects</div>
                      {projects?.map((item, index) => (
                        <div
                          key={index + 1}
                          className={`cursor-pointer body-text2 inter-400 ${(hoveredIndex === item.id) && projectHovered ? 'text-primary' : 'text-gray-900'}`}
                          onClick={() =>
                            navigate(`/projects?property=${item.property_data.slug}`)
                          }
                          onMouseEnter={() => {
                            setHoveredIndex(item.id)
                            setProjectHovered(prev => !prev)
                          }
                          }
                          onMouseLeave={() => {
                            setHoveredIndex(null)
                            setProjectHovered(prev => !prev)
                          }}
                        >
                          {item?.project_name}
                        </div>
                      ))}
                    </div>
                    <div>
                      <div className="text-gray-900 body-text2 inter-600">Spaces</div>
                      {roomCategories?.map((item, index) => (
                        <div
                          key={index + 1}
                          className={`cursor-pointer body-text2 inter-400 ${(hoveredIndex === item.id) && spaceHovered ? 'text-primary' : 'text-gray-900'}`}
                          onClick={() =>
                            navigate(`/spaces?room-caterogry=${item.slug}`)
                          }
                          onMouseEnter={() => {
                            setHoveredIndex(item.id)
                            setSpaceHovered(prev => !prev)
                          }
                          }
                          onMouseLeave={() => {
                            setHoveredIndex(null)
                            setSpaceHovered(prev => !prev)
                          }}
                        >
                          {item?.title}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="me-5">
                    <div className="text-gray-900 body-title inter-600 text-primary">Brands</div>
                    {brands.map((brand, i) => {
                      return (
                        <div key={i + 1} className={`${i === 0 ? "mt-1" : ""} mb-2`}>
                          <div className="text-gray-900 body-text2 inter-600">{brand.title}</div>
                          {brand?.data?.map((item, index) => (
                            <div
                              key={index + 1}
                              className={`cursor-pointer body-text2 inter-400 ${(hoveredIndex === item.name) && brandHovered ? 'text-primary' : 'text-gray-900'}`}
                              onClick={() =>
                                navigate(`/brands/${item.slug}`)
                              }
                              onMouseEnter={() => {
                                setHoveredIndex(item.name)
                                setBrandHovered(prev => !prev)
                              }
                              }
                              onMouseLeave={() => {
                                setHoveredIndex(null)
                                setBrandHovered(prev => !prev)
                              }}
                            >
                              {item?.name}
                            </div>
                          ))}
                        </div>
                      )
                    })}
                  </div>
                  <div className="me-5">
                    <div className="text-gray-900 body-title inter-600 text-primary">Designers</div>
                    {professionalTypes?.map((professional, i) => {
                      return (
                        <div key={i + 1} className={`${i === 0 ? "mt-1" : ""} mb-2`}>
                          <div className="text-gray-900 body-text2 inter-600">{professional.title}</div>
                          {professional?.data?.map((item, index) => (
                            <div
                              key={index + 1}
                              className={`cursor-pointer body-text2 inter-400 ${(hoveredIndex === item.company_name) && designerHovered ? 'text-primary' : 'text-gray-900'}`}
                              onClick={() =>
                                navigate(`/professionals/architect/${item.slug}`)
                              }
                              onMouseEnter={() => {
                                setHoveredIndex(item.company_name)
                                setDesignerHovered(prev => !prev)
                              }
                              }
                              onMouseLeave={() => {
                                setHoveredIndex(null)
                                setDesignerHovered(prev => !prev)
                              }}
                            >
                              {item?.company_name}
                            </div>
                          ))}
                        </div>
                      )
                    })}
                  </div>
                  <div>
                    <div className="text-gray-900 body-title inter-600 text-primary">Builders</div>
                    {builders?.map((builder, index) => {
                      return (
                        <div
                          key={index + 1}
                          className={`cursor-pointer body-text2 inter-400 ${(hoveredIndex === builder.company_name) && builderHovered ? 'text-primary' : 'text-gray-900'}`}
                          onMouseEnter={() => {
                            setHoveredIndex(builder.company_name)
                            setBuilderHovered(prev => !prev)
                          }
                          }
                          onMouseLeave={() => {
                            setHoveredIndex(null)
                            setBuilderHovered(prev => !prev)
                          }}
                          onClick={() =>
                            navigate(`/professionals/builders/${builder.slug}`)
                          }
                        >
                          {builder?.company_name}
                        </div>
                      )
                    })}
                  </div>
                </Col>
              )}
            </> : <></>
          }

        </Row>
      </Container> : (
        <Container className="py-5" style={{ padding: '20px' }}>
          <Row className="mb-3">
            <Col className="text-center">
              <Image
                src={interiokeys_logo}
                alt="Interiokeys logo"
                width={32}
                height={32}
              />
            </Col>
          </Row>
          {/* Signup Section */}
          <Row className="mb-3">
            <Col className="text-center">
              <div
                className="inter-600 text-gray-900"
                style={{ fontSize: "18px" }}
              >
                Signup to explore the best <br /> inspirations
              </div>
            </Col>
          </Row>

          {/* Continue with Email Button */}
          <Row className="mb-3">
            <Col className="text-center">
              <Button
                variant="danger"
                block
                onClick={() => setShowLoginModal((prev) => !prev)}
              >
                Continue with Email
              </Button>
            </Col>
          </Row>

          {/* Social Login Buttons */}
          <Row className="mb-3">
            <Col className="text-center">
              <FaFacebook className="mx-2" size={32} color="#1877F2" />
              <FcGoogle className="mx-2" size={32} color="#D95656" />
            </Col>
          </Row>

          {/* Already a Member Link */}
          <Row className="mb-3">
            <div className="d-flex justify-content-center align-items-center">
              <span className="body-text1 inter-500 text-gray-700 me-1">
                Already a member?
              </span>
              <span
                className="body-text1 inter-500 text-gray-700"
                style={{
                  fontSize: "inherit",
                  textDecoration: "none",
                }}
                onClick={() => {
                  setShowLoginModal((prev) => !prev);
                }}
              >
                login
              </span>
            </div>
          </Row>

          {/* Terms and Privacy Policy Text */}
          <Row>
            <Col className="text-center">
              <p
                className="inter-400 text-gray-600"
                style={{ fontSize: "12px" }}
              >
                By continuing you agree to Interiorkey’s Terms and <br />{" "}
                Conditions and acknowledge that you’ve read our <br /> Privacy
                Policy.
              </p>
            </Col>
          </Row>

          {/* Footer Links */}
          <Row className="px-2">
            <Col className="text-center">
              <div className="d-flex flex-wrap justify-content-center">
                {mobile_footer_links.map((item) => (
                  <a
                    key={item.id}
                    href={item.link}
                    className="mx-2 body-text1 inter-500 text-gray-700"
                    style={{ textDecoration: "none" }}
                  >
                    {item.title}
                  </a>
                ))}
              </div>
            </Col>
          </Row>
          <LoginModal
            showLoginModal={showLoginModal}
            setShowLoginModal={setShowLoginModal}
            isHomeOwner={isHomeOwner}
            setIsHomeOwner={setIsHomeOwner}
          />
        </Container>
      )}
    </footer>
  );
};

export default Footer;
