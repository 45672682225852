import { default_pfp } from "assets";
import * as authActions from "reduxFiles/actions/authActions";
import PropertyCard from "components/Cards/PropertyCard";
import RangeSlider from "components/Forms/RangeSlider";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notify } from "utilities/notifications/Notify";
import { formatNumber } from "utilities/numberFormatter";
import { LuCheck, LuPencil } from "react-icons/lu";
import { userUpdate } from "reduxFiles/reducer/authSlices";
import PropertyGridCard from "components/Cards/PropertyGridCard";

function BuilderPublicProfile() {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [propertiesLoading, setPropertiesLoading] = useState(false);
  const [properties, setProperties] = useState();
  const [builderInfo, setBuilderInfo] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const navigate = useNavigate();
  const [rangeValue, setRangeValue] = useState({
    min: 3000000,
    max: 500000000,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isPfpEdit, setIsPfpEdit] = useState(false);

  const builderInfoRef = useRef();

  const fetchBuilderData = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchBuildersDataByUser(user.id);
      if (response.data) {
        console.log(response.data?.results);
        setBuilderInfo(response.data?.results[0]);
        setRangeValue({
          min: response.data?.results[0]?.job_cost_minimum,
          max: response.data?.results[0]?.job_cost_maximum,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchProperties = async () => {
    setPropertiesLoading(true);
    try {
      const response = await authActions.fetchPropertiesByBuilderUser(user.id);
      if (response.data) {
        setProperties(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    setPropertiesLoading(false);
  };

  useEffect(() => {
    fetchBuilderData();
    fetchProperties();
  }, []);

  const handleProfileImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePic(file);
    }
  };

  const handleButtonClick = () => {
    document.getElementById("profileUpload").click();
    setIsPfpEdit(true);
  };

  const handleProfilePicUpdate = async () => {
    const formData = new FormData();
    formData.append("profile_picture", profilePic);
    try {
      const response = await authActions.updateProfilePic(user.id, formData);
      if (response.data) {
        notify("Profile Picture Updated!", "success");
        setIsPfpEdit(false);
        dispatch(userUpdate(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(builderInfoRef.current);
    let data = {};
    formData.append("user", user.id);
    formData.append("job_cost_minimum", rangeValue.min);
    formData.append("job_cost_maximum", rangeValue.max);
    formData.forEach((value, key) => (data[key] = value));
    console.log(data);
    try {
      if (builderInfo) {
        const response = await authActions.updateBuilder(builderInfo?.id, data);
        console.log(response);
        if (response.data) {
          notify("Info updated!", "success");
        }
        setIsEdit(false);
      } else {
        const response = await authActions.addBuilder(data);
        if (response.data) {
          notify("Info updated!", "success");
        }
      }
    } catch (error) {
      notify(error.response.data.msg || "An error occured!", "error", 1);
    }
  };

  return (
    <Container
      className="mt-5 pt-4 px-0"
    >
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "68vh" }}
        >
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="d-flex align-items-center my-3">
            <div className="position-relative">
              <img
                style={{
                  border: "1px solid black",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                width={150}
                height={150}
                src={
                  profilePic
                    ? URL.createObjectURL(profilePic)
                    : user?.profile_picture || default_pfp
                }
                alt="avatar"
              />
              <input
                type="file"
                id="profileUpload"
                style={{ display: "none" }}
                onChange={handleProfileImageChange}
              />
              {isPfpEdit && profilePic ? (
                <Button
                  variant="success"
                  className="position-absolute d-flex align-items-center rounded-btn text-white"
                  style={{ bottom: "0px", right: "5px" }}
                  onClick={handleProfilePicUpdate}
                >
                  <LuCheck />
                </Button>
              ) : (
                <Button
                  className="position-absolute d-flex align-items-center rounded-btn text-white"
                  style={{ bottom: "0px", right: "5px" }}
                  onClick={handleButtonClick}
                >
                  <LuPencil />
                </Button>
              )}
            </div>
            <div
              className="inter-600 ms-auto"
              style={{ fontSize: "30px" }}
            >
              <Button
                variant="info"
                className="text-gray-900"
                onClick={() => navigate(`/builders/${builderInfo?.slug}`)}
              >
                See Public View
              </Button>
            </div>
          </div>
          <Row className="my-4 signup-card" style={{ boxShadow: "none" }}>
            <div className="d-flex">
              <div className="h3 inter-700">About</div>
              <Button
                className="d-flex align-items-center rounded-btn text-white ms-auto"
                onClick={() => setIsEdit(!isEdit)}
              >
                <LuPencil />
              </Button>
            </div>
            <Col lg={6}>
              <Form ref={builderInfoRef} onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Company Name<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    className="border-dark"
                    defaultValue={builderInfo?.company_name}
                    name="company_name"
                    disabled={!isEdit}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Location<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    className="border-dark"
                    defaultValue={builderInfo?.location}
                    name="location"
                    disabled={!isEdit}
                  />
                </Form.Group>
                <div>
                  <div className="d-flex">
                    <div>
                      <span className="inter-600">Min: </span>
                      {formatNumber(rangeValue.min)}
                    </div>
                    <div className="ms-auto">
                      <span className="inter-600">Max: </span>
                      {formatNumber(rangeValue.max)}
                    </div>
                  </div>
                  <RangeSlider
                    min={3000000}
                    max={500000000}
                    step={500000}
                    value={rangeValue}
                    onChange={setRangeValue}
                    disabled={!isEdit}
                  />
                </div>
                <Row>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Projects Count<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Control
                        className="border-dark"
                        name="project_count"
                        defaultValue={builderInfo?.project_count}
                        disabled={!isEdit}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Years of Experience<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Control
                        className="border-dark"
                        name="years_of_experience"
                        defaultValue={builderInfo?.years_of_experience}
                        disabled={!isEdit}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Cities<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Control
                        className="border-dark"
                        name="no_of_cities"
                        defaultValue={builderInfo?.no_of_cities}
                        disabled={!isEdit}
                      />
                    </Form.Group>
                  </Col>
                  {isEdit && (
                    <Col>
                      <Button type="submit" className="me-3 text-white">
                        Save
                      </Button>
                      <Button
                        className="bg-gray"
                        onClick={() => setIsEdit(false)}
                      >
                        Cancel
                      </Button>
                    </Col>
                  )}
                </Row>
              </Form>
            </Col>
          </Row>
          {propertiesLoading ? (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ minHeight: "50vh" }}
            >
              <Spinner />
            </div>
          ) : (
            <div>
              {properties && (
                <div className="mb-5">
                  <h3>{`Properties Created(${properties?.length})`}</h3>
                  <Row
                    md={2}
                    lg={3}
                    className="my-4 signup-card flex-wrap"
                    style={{ boxShadow: "none" }}
                  >
                    {properties.map((property, index) => (
                      <Col key={index} className="mb-4">
                        <PropertyGridCard
                          key={index}
                          {...property}
                          builder={property?.builder_data?.company_name}
                          project_type={property.project_type_data.title}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Container>
  );
}

export default BuilderPublicProfile;
