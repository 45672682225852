import { useRef } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import TestimonialCard from "components/Cards/TestimonialCard";

// items should contain image and title
const VerticalSlider = ({ items, rtl }) => {
  let sliderRef = useRef(null);
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    rtl: rtl,
  };

  return (
    <Container className="px-0">
      <Slider
        ref={(slider) => {
          sliderRef = slider;
        }}
        {...settings}
      >
        {items.map((item, index) => (
          <TestimonialCard
            key={index}
            testimonial={item}
            fixedHeight={true}
            className="mb-2"
          />
        ))}
      </Slider>
    </Container>
  );
};

export default VerticalSlider;
