import { useEffect, useRef, useState } from "react";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FaRegHeart, FaStar } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import SpaceCard from "components/Cards/SpaceCard";
import Review from "components/Review/Review";
import * as authActions from "reduxFiles/actions/authActions";
import SpacesSliderView from "pages/Projects/SpacesSliderView";
import { useSelector } from "react-redux";
import RatingStarsView from "components/General/RatingStarsView";
import ShareModal from "components/General/ShareModal";
import { notify } from "utilities/notifications/Notify";
import LoginModal from "components/Auth/LoginModal";

const BrandDetails = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const form = useRef();

  const [brandDetails, setBrandDetails] = useState(null);
  const [projects, setProjects] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [showSpaceView, setShowSpaceView] = useState(false);
  const [activeSpaceIndex, setActiveSpaceIndex] = useState(0);
  const [showShare, setShowShare] = useState(false);
  const [roomData, setRoomData] = useState({});
  const [activeFilter, setActiveFilter] = useState("all_products");
  const [status, setStatus] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [rating, setRating] = useState(5);
  const [isLiked, setIsLiked] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);



  const fetchBrand = async (page) => {
    try {
      const response = await authActions.fetchBrandsBySlug(slug);
      if (response.data) {
        console.log(response.data);
        setBrandDetails(response.data.results[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjects = async () => {
    try {
      const resProjects = await authActions.fetchProjects();
      if (resProjects.data) {
        setProjects(resProjects.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchReviews = async () => {
    try {
      const resReviews = await authActions.fetchReviewsByArchitectUser();
      if (resReviews.data) {
        setReviews(resReviews.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkProfessionalIfSaved = async () => {
    try {
      const response = await authActions.checkProfessionalIfSaved(
        user.id,
        brandDetails.user
      );
      console.log(response);
      if (response) {
        setIsLiked(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProfessional = async () => {
    try {
      const response = await authActions.saveProfessional(
        user.id,
        brandDetails.user
      );
      if (response.data) {
        setIsLiked(true);
        notify("Professional saved successfully", "success");
      } else {
        notify("Failed to save professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProfessional = async () => {
    console.log(brandDetails.user);
    if (brandDetails?.user) {
      try {
        const response = await authActions.unsaveProfessional(
          user.id,
          brandDetails.user
        );
        if (response) {
          setIsLiked(false);
          notify("Professional unsaved successfully", "success");
        } else {
          notify("Failed to unsave professional, try again later", "success");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const submitReview = async (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    formData.append("for_user", slug);
    formData.append("by_user", user.id);
    formData.append("rating", rating);
    if (formData.get("review")) {
      // let data = {};
      // formData.forEach((value, key) => (data[key] = value));
      const response = await authActions.addReview(formData);
      if (response.data) {
        notify("Review submitted successfully!", "success");
        fetchReviews();
        form.current.reset();
      }
    } else {
      notify("Review cannot be empty!", "error");
    }
  };

  const downloadBrochure = (fileUrl) => {
    window.open(fileUrl, '_blank', 'noopener,noreferrer');
  };

  const sendEnquiry = async (e) => {
    const response = await authActions.submitBrandEnquiry({
      brand: slug,
    });
    // name, email, pincode, request_type {QUOTE, CATALOGUE, INFORMATION}, message
    console.log(response);
    if (response.data) {
      notify("Brand enquiry submitted successfully!", "success");
      fetchReviews();
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchReviews();
  }, [activeFilter, status, sortBy]);

  useEffect(() => {
    if (slug && slug !== "undefined") {
      fetchBrand();
    } else {
      navigate(-1);
    }
  }, [slug]);

  useEffect(() => {
    if (brandDetails?.id) {
      checkProfessionalIfSaved();
    }
  }, [brandDetails]);

  useEffect(() => {
    fetchProjects();
    fetchReviews();
  }, []);

  return (
    <Container fluid className="pt-5 mt-4 p-0">
      <Container fluid className="bg-gray-25 p-0 border-bottom">
        <Container className="d-flex flex-column flex-md-row geist py-md-5">
          <div className="me-md-4">
            <div className="d-flex justify-content-start w-100">
              <div style={{ width: 200, height: 200, marginTop: '12px' }}>
                <img
                  className="p-2 bg-white img-fluid ms-auto h-100"
                  style={{
                    width: 200,
                    // height: 200,
                    border: "1px solid #D0D5DD",
                    borderRadius: "10px",
                    objectFit: "contain",
                  }}
                  // src={brandDetails?.user_data?.profile_picture}
                  src={brandDetails?.image}
                  alt="brand Image"
                />
              </div>
            </div>
          </div>
          <div className="flex-grow">
            <div className="pt-2 pt-lg-0 d-flex justify-content-between align-items-center">
              <div className="h2 inter-600">
                {brandDetails?.company_name}
              </div>
              <div className="d-flex align-items-center ms-3">
                <Button
                  className="d-flex justify-content-center align-items-center social-btn py-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowShare(true);
                  }}
                >
                  <FiSend size={18} />
                </Button>
                {/* {user && ( */}
                <Button
                  className={`d-flex justify-content-center align-items-center social-btn py-2 ms-2 ${isLiked ? "bg-primary text-white" : ""
                    }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!user) {
                      setShowLoginModal(true)
                    } else {
                      isLiked
                        ? handleUnsaveProfessional()
                        : handleSaveProfessional();
                    }
                  }}
                >
                  <FaRegHeart size={18} />
                </Button>
                {/* )} */}
              </div>
            </div>
            <div className="body-text inter-600">
              {brandDetails?.brand_category_data?.name}
            </div>
            <div className="d-flex align-items-center body-text inter-600">
              Ratings: {brandDetails?.rating} <FaStar color="#D95656" />
              {/* { brandDetails?.rating ? (<span> {brandDetails.rating} <FaStar color="#D95656" /></span>) : ( "Unavailable" )} */}
            </div>
            <div>
              <span className="body-text inter-600">Warranty:</span>{" "}
              {brandDetails?.warranty ?? "-"}
            </div>
            <div>
              <span className="body-text inter-600">Average Price:</span>{" "}
              {brandDetails?.average_pricing ?? "-"}
            </div>
            <div className="mt-3 mb-4">
              {brandDetails?.brochure &&
                <Button
                  className="text-primary bg-info border-0 me-3"
                  style={{ borderRadius: 3 }}
                  onClick={() => downloadBrochure(brandDetails?.brochure)}
                >
                  Download Brochures
                </Button>
              }
              {/* <Button
                className="text-white"
                style={{ borderRadius: 3 }}
                onClick={sendEnquiry}
              >
                Send Enquiry
              </Button> */}
            </div>
          </div>
        </Container>
      </Container>
      {brandDetails?.youtube_embeded &&
        <Container fluid className="bg-gray-25 p-0 border-bottom">
          <Container className="d-flex flex-column flex-md-row geist py-md-4">
            <div className="d-flex justify-content-center me-md-4">
              <iframe
                maxWidth="300"
                height="200"
                src={brandDetails?.youtube_embeded}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
                className="mb-2 mb-lg-0"
              >
              </iframe>
            </div>
            <div className="d-flex flex-column justify-content-center m">
              <h4 className="inter-700 m-0">“We can finally showcase all the possibilities of our product”</h4>
              <div className="body-text1 inter-600">{brandDetails?.company_name}</div>
              <div className="mt-3 mb-3 mb-lg-0" style={{ textAlign: 'justify' }}>
                "We are thrilled to partner with Interiokeys, a dynamic platform that seamlessly integrates
                our range of {brandDetails?.company_name} products into their innovative interior designs. It's incredibly
                gratifying to see our paints and finishes featured so prominently in the stunning designs
                showcased on the Interiokeys website”
              </div>
            </div>
          </Container>
        </Container>
      }
      <Container className="py-3">
        <div className="geist body-title mb-2">
          <span className="inter-700">5,000 real space designs</span> created
        </div>
        <Row className="mb-2">
          <Col lg={9}>
            <div className="d-flex mb-2 overflow-scroll scrollbar-none">
              <div className="pe-2 me-2 border-end">
                <Button
                  className={`border inter-500 ${activeFilter == "all_products"
                    ? "bg-primary text-white"
                    : "bg-gray-25 text-dark"
                    }`}
                  onClick={() => setActiveFilter("all_products")}
                >
                  All Products
                </Button>
              </div>
              <Button
                className={`border me-2 inter-500 ${activeFilter == "all_rounder"
                  ? "bg-primary text-white"
                  : "bg-gray-25 text-dark"
                  }`}
                onClick={() => setActiveFilter("all_rounder")}
              >
                All Rounder
              </Button>
              <Button
                className={`border me-2 inter-500 ${activeFilter == "aquabond"
                  ? "bg-primary text-white"
                  : "bg-gray-25 text-dark"
                  }`}
                onClick={() => setActiveFilter("aquabond")}
              >
                Aquabond
              </Button>
              <Button
                className={`border me-2 inter-500 ${activeFilter == "champion_super"
                  ? "bg-primary text-white"
                  : "bg-gray-25 text-dark"
                  }`}
                onClick={() => setActiveFilter("champion_super")}
              >
                Champion Super
              </Button>
              <Button
                className={`border me-2 inter-500 ${activeFilter == "foam_bond"
                  ? "bg-primary text-white"
                  : "bg-gray-25 text-dark"
                  }`}
                onClick={() => setActiveFilter("foam_bond")}
              >
                Foam Bond
              </Button>
              <Button
                className={`border me-2 inter-500 ${activeFilter == "lamino"
                  ? "bg-primary text-white"
                  : "bg-gray-25 text-dark"
                  }`}
                onClick={() => setActiveFilter("lamino")}
              >
                Lamino
              </Button>
              <Button
                className={`border me-2 inter-500 ${activeFilter == "pvc_xtra"
                  ? "bg-primary text-white"
                  : "bg-gray-25 text-dark"
                  }`}
                onClick={() => setActiveFilter("pvc_xtra")}
              >
                PVC-Xtra
              </Button>
              <Button
                className={`border me-2 inter-500 ${activeFilter == "water_shield"
                  ? "bg-primary text-white"
                  : "bg-gray-25 text-dark"
                  }`}
                onClick={() => setActiveFilter("water_shield")}
              >
                Water Sheild
              </Button>
            </div>
          </Col>
          <Col lg={3}>
            <Row>
              <Col className="px-2">
                <Form.Select
                  defaultValue=""
                  className="bg-gray-25"
                  onSelect={(e) => setStatus(e.target.value)}
                >
                  <option value="" disabled>
                    Status
                  </option>
                  <option value="completed">Complete</option>
                  <option value="ongoing">Ongoing</option>
                  <option value="upcoming">Upcoming</option>
                </Form.Select>
              </Col>
              <Col className="px-2">
                <Form.Select
                  defaultValue=""
                  className="bg-gray-25"
                  onSelect={(e) => setSortBy(e.target.value)}
                >
                  <option value="" disabled>
                    Sort By
                  </option>
                  <option value="popularity">Popularity (High to Low)</option>
                  <option value="cost_high_low">Cost (High to Low)</option>
                  <option value="cost_low_high">Cost (Low to High)</option>
                  <option value="featured_new_old">
                    Featured (New to Old)
                  </option>
                  <option value="featured_old_new">
                    Featured (Old to New)
                  </option>
                </Form.Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <div>
          <Row>
            {projects?.map((project) =>
              project?.rooms?.map((room, index) => (
                <Col
                  key={index}
                  md={6}
                  lg={4}
                  className="d-flex justify-content-around my-3"
                >
                  <SpaceCard
                    {...room?.room_data}
                    id={room.id}
                    projectId={room.project}
                    type={room.type}
                    image={room?.media[0]?.file}
                    title={`${room?.room_type_data?.title}/${room?.project_data?.property_name}`}
                    project={room?.project_data?.project_name}
                    area={room?.project_data?.property_area}
                    setShow={setShowSpaceView}
                    setRoomData={setRoomData}
                    onClick={() => {
                      setRoomData({
                        id: room.id,
                        projectId: room.project,
                        roomImage: room?.media[0]?.file,
                      });
                      showSpaceView(true);
                    }}
                  />
                </Col>
              ))
            )}
          </Row>
          <SpacesSliderView
            ativeRoomIndex={activeSpaceIndex}
            setActiveRoomIndex={setActiveSpaceIndex}
            show={showSpaceView}
            setShow={setShowSpaceView}
            roomData={roomData}
          />
        </div>
        <div className="my-4">
          {user&&user.user_type==='customer'&&<>
            <div className="h3 inter-600 mb-3">Add Review</div>
            <div className="d-flex justify-content-end mb-3">
              <RatingStarsView rating={rating} setRating={setRating} />
            </div>
            <Form ref={form} onSubmit={submitReview}>
              <Form.Group>
                <Form.Control
                  name="review"
                  as="textarea"
                  rows={5}
                  placeholder="I love interiokeys because..."
                  style={{ resize: 'none' }}
                />
              </Form.Group>
              <div className="w-100 my-3 d-flex justify-content-end">
                <Button className="text-white" type="submit">
                  Submit Review
                </Button>
              </div>
            </Form>
          </>}
          <div className="h4 inter-600">Reviews and Ratings</div>
          {reviews?.length > 0 ? (
            reviews?.map((review, index) => (
              <Review
                key={index}
                id={review?.id}
                image={review?.image}
                name={review?.name}
                type={review?.type}
                content={review?.review}
              />
            ))
          ) : (
            <div
              className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
              style={{ height: 160 }}
            >
              No reviews available
            </div>
          )}
        </div>
      </Container>
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={brandDetails?.id}
        image={brandDetails?.image}
        title={brandDetails?.company_name}
        urlPath={slug !== "undefined" ? `/brands/${slug}` : null}
      />
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      />
    </Container>
  );
};

export default BrandDetails;
