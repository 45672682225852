import { useRef } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import { truncateText } from "utilities/truncateText";

// items should contain image and title
const HeroBanner = ({ items, card_info }) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });

  let sliderRef = useRef(null);
  var settings = {
    customPaging: function (i) {
      return (
        <div className="custom-dot mt-3" />
      );
    },
    dotsClass: "slick-dots",
    dots: small ? false : true,
    arrows: small ? false : true,
    infinite: large ? false : true,
    autoplay: large ? false : true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: large ? 3 : 1,
    slidesToScroll: 1,
    nextArrow:  <></>,
    prevArrow: <></>
  };

  return (
    <Container>
      <Row className="mb-4">
        <Slider
          ref={(slider) => {
            sliderRef = slider;
          }}
          {...settings}
        >
          {items.map((item, index) => (
            <Col key={index} className="px-2">
              <Card
                className="position-relative border-0 overflow-hidden shadow-none p-0"
                style={{ height: xxlarge ? 290 : xlarge ? 250 : large ? 200 : medium ? 400 : small ? 300 : 200, borderRadius: 6 }}
              >
                <Image
                  src={item?.rooms[0]?.media[0]?.file}
                  alt={item?.project_name}
                  height="100%"
                  width="100%"
                />
                <div
                  className="position-absolute py-1 px-2 inter-400 body-text1"
                  style={{
                    bottom: 10,
                    right: 10,
                    background: "#E8EAEDCC",
                    borderRadius: 3
                  }}
                >
                  {truncateText(item?.project_name, 18)}
                </div>
              </Card>
              <Card
                key={card_info[index]['id']}
                className="bg-transparent shadow-none mt-4"
                style={{ border: "1px solid #E8EAED" }}
              >
                <div className="body-title inter-600 text-gray-900">
                  {card_info[index]['title']}
                </div>
                <div className="body-text2 inter-400 text-gray-600">
                  {truncateText(card_info[index]['description'], xxlarge ? 110 : xlarge ? 90 : large ? 83 : medium ? 110 : small ? 90 : 110)}
                </div>
              </Card>
            </Col>
          ))}
        </Slider>
      </Row>
    </Container>
  );
};

export default HeroBanner;
