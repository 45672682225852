import React from "react";
import { Card, Row, Col } from "react-bootstrap";

const TestimonialCardMobile = ({ testimonial }) => {
    return (
        <Card className="p-2 shadow-none" style={{borderRadius:'6px', border:'1px solid #e8eaed'}}>
            <Card.Body>
                {/* Testimonial Text */}
                <Card.Text className="body-title text-gray-600 inter-400">
                    {testimonial?.testimonial}
                </Card.Text>

               <hr style={{backgroundColor:'#e8eaed'}}/>
                <Row className="align-items-center pe-4">

                    <Col xs={10}>
                        {/* Client Name and Designation */}
                        <div className="h6 inter-600 text-gray-900 mb-0">{testimonial.name}</div>
                        <div className="body-text1 inter-400 text-gray-600">{testimonial.title}</div>
                    </Col>
                    <Col xs={2}>
                        {/* Client Image */}
                        <img
                            src={testimonial.image}
                            alt={testimonial.name}
                            className="rounded-circle"
                            style={{ width: "48px", height: "48px", objectFit: "cover" }}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default TestimonialCardMobile;
