import React from "react";
import { Container } from "react-bootstrap";

const PageNotFound = () => {
  return (
    <Container fluid className="mt-5 pt-4">
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "50vh" }}
      >
        <div className="h2 fw-bold">404 - Page not found</div>
      </Container>
    </Container>
  );
};

export default PageNotFound;
