import React, { useState } from "react";

const CustomAccordion = ({ accordionItems, onToggle }) => {
    const [activeIndex, setActiveIndex] = useState(0);



    const toggleAccordion = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className="custom-accordion">
            {accordionItems.map((item, index) => (
                <div
                    key={index}
                    className={`accordion-item ${activeIndex === index ? "active" : ""}`}
                >
                    <div
                        className="accordion-title"
                        onClick={() => {
                            toggleAccordion(index)
                            onToggle(item.id)
                        }}
                    >
                        <span
                            className={`body-title inter-600 ${activeIndex === index ? "active-title" : "text-gray-600"
                                }`}
                        >
                            {item.title}
                        </span>
                    </div>
                    <div
                        className={`accordion-body ${activeIndex === index ? "body-visible" : "body-hidden"
                            }`}
                    >
                        <p className="content body-text2 inter-400 text-gray-900">{item.content}</p>
                        <p className="body-title inter-500 text-gray-900">{item.step}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CustomAccordion;
