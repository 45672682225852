import axios from "axios";
import { Flip } from "react-toastify";
import { notify } from "utilities/notifications/Notify";

export const toastConf = {
  position: "top-right",
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  pauseOnFocusLoss: false,
  hideProgressBar: true,
  className: "my-toast",
  theme: "dark",
  transition: Flip,
};

// export const notify = (msg, type) => {
//   switch (type) {
//     case "success":
//       toast.success(msg, toastConf);
//       break;
//     case "error":
//       toast.error(msg, toastConf);
//       break;
//     case "warning":
//       toast.warning(msg, toastConf);
//       break;
//     case "info":
//       toast.info(msg, toastConf);
//       break;
//     default:
//       toast.error(msg, toastConf);
//   }
//   // alert();
// };

/////////////// -------- AUTH -------- ///////////////

export const login = async (data) => {
  try {
    const response = await axios.post("/user/login/", data);
    if (response.status === 200) {
      console.log("login response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("login error", error);
    notify(error?.response?.data?.msg, "error");
    // return error;
  }
};

export const signup = async (data) => {
  try {
    const response = await axios.post("/user/signup/", data);
    if (response.status === 200 || response.status === 201) {
      console.log("signup response: ", response.data);
      return response;
    } else if (response?.msg) {
      notify(response.msg, "error");
    }
  } catch (error) {
    console.log("signup error", error);
    notify(error.response.data.msg, "error");
    return error;
  }
};

export const logout = async (data) => {
  try {
    const response = await axios.post("/user/logout/", data);
    if (response.status === 204) {
      console.log("logout response: ", response.data);
      return true;
    }
  } catch (error) {
    console.log("logout error", error);
    // notify("Error while logging out", "error");
    return error;
  }
};

/////////////// -------- OTP --------   ///////////////

export const sendResetOtp = async (data) => {
  try {
    const response = await axios.post("/user/send_reset_otp/", data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg, "error");
    return err?.response?.data;
  }
};

/////////////// -------- RESET PASSWORD --------   ///////////////

export const reset_password = async (data) => {
  try {
    const response = await axios.post("/user/reset_password/", data);
    return response.data;
  } catch (err) {
    return err?.response?.data;
  }
};

/////////////// -------- VERIFICATION -------- ///////////////
export const verfiyOtpLogin = async (data) => {
  try {
    const response = await axios.post("/user/verify/", data);
    if (response.status === 200 || response.status === 201) {
      console.log("verfiyOtpLogin response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("verfiyOtpLogin error", error);
    notify("Error while verifying OTP", "error");
    return error;
  }
};

/////////////// -------- USERS / PROFESSIONALS -------- ///////////////

export const fetchProfessionalsPagewise = async (page = 1) => {
  try {
    // const response = await axios.get("/user/rest/users/?user_subtype=");
    const response = await axios.get(`/user/rest/professionals/?page=${page}`);
    if (response.status === 200) {
      console.log("fetchProfessionalsPagewise response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProfessionalsPagewise error", error);
    return error;
  }
};

export const fetchProfessionalTypes = async () => {
  try {
    const response = await axios.get("/user/rest/user-subtypes/");
    if (response.status === 200) {
      console.log("fetchProfessionalTypes response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProfessionalTypes error", error);
    return error;
  }
};

export const fetchProfessionalsBySlug = async (slug, page = 1) => {
  try {
    const response = await axios.get(
      `/user/rest/users/?user_subtype_slug=${slug}&page=${page}`
    );
    if (response.status === 200) {
      console.log("fetchProfessionalsBySlug response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProfessionalsBySlug error", error);
    notify("Error while fetching professionals", "error");
    return error;
  }
};

export const fetchProfessionalsByUserSubType = async (queryString) => {
  try {
    const response = await axios.get(`/user/rest/user-subtypes/architect_by_subtype/?${queryString}`);
    if (response.status === 200) {
      console.log("fetchProfessionalsBySlug response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProfessionalsBySlug error", error);
    notify("Error while fetching professionals", "error");
    return error;
  }
};

/////////////// -------- PROFILE -------- ///////////////

export const updateProfile = async (userId, data) => {
  try {
    const response = await axios.put(`/user/rest/users/${userId}/`, data);
    if (response.status === 200) {
      console.log("updateProfile response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("updateProfile error", error);
    notify(error.response.data.msg || "Error while updating profile!", "error");
    return error;
  }
};

export const updateProfilePic = async (userId, formData) => {
  try {
    const response = await axios.put(
      `/user/rest/users/${userId}/update_profile_pic/`,
      formData,
      "updateProfilePic response: ",
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    if (response.status === 200) {
      console.log("updateProfilePic response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("updateProfilePic error", error);
    notify(
      error.response.data.msg || "Error while updating profile pic!",
      "error"
    );
    return error;
  }
};

/////////////// -------- CITIES -------- ///////////////

export const fetchAllCities = async () => {
  try {
    const response = await axios.get("/user/rest/city/");
    if (response.status === 200) {
      console.log("fetchAllCities response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchAllCities error", error);
    notify("Error while fetching all cities", "error");
    return error;
  }
};

/////////////// -------- ORDERS -------- ///////////////

export const fetchUserOrders = async (userId) => {
  try {
    const response = await axios.post("/order/orders/get_user_orders/", { user: userId });
    // const response = await axios.get(`/order/orders/?user=${userId}&page=${page}`);
    if (response.status === 200) {
      console.log("fetchUserOrders response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchUserOrders error", error);
    notify("Error while fetching user's projects", "error");
    return error;
  }
};

/////////////// -------- SEND PDF -------- ///////////////

export const sendPdfToEmail = async (email) => {
  try {
    const response = await axios.post(`/user/send_pdf/`, { email: email });
    if (response.status === 200) {
      console.log("sendPdfToEmail response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("sendPdfToEmail error", error);
    notify("Error while sending PDF to email", "error");
    return error;
  }
};


/////////////// -------- ARCHITECTS -------- ///////////////

export const fetchProfessionals = async (type) => {
  try {
    const response = await axios.get(`/user/rest/architects/?user_subtype_slug=${type}`);
    if (response.status === 200) {
      console.log("fetchArchitects response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchArchitects error", error);
    notify("Error while fetching architects", "error");
    return error;
  }
};

export const fetchAllProfessionalsByText = async (text) => {
  try {
    // Define the URLs
    const urls = [
      `/user/rest/architects/?company_name=${text}`,
      `/user/rest/builders/?company_name=${text}`,
      `/user/rest/brand-info/?company_name=${text}`
    ];

    // Fetch all data concurrently
    const responses = await Promise.all(urls.map(url => axios.get(url)));

    // Check for successful responses and extract `results`
    const [architects, builders, brands] = responses.map(response => {
      if (response.status === 200) {
        return response.data.results || []; // Extract 'results' array or return empty array if not found
      } else {
        return [];
      }
    });

    // Consolidate the data into a single object
    const consolidatedResponse = {
      architects,
      builders,
      brands,
    };

    console.log("Consolidated Response: ", consolidatedResponse);
    return consolidatedResponse;
  } catch (error) {
    console.log("Error fetching data: ", error);
    notify("Error while fetching data", "error");
    return { architects: [], builders: [], brands: [] }; // Return empty arrays in case of failure
  }
};

export const fetchArchitectsByTypeSlug = async (slug, page = 1) => {
  try {
    const response = await axios.get(
      `/user/rest/architects/?user_subtype_slug=${slug}&page=${page}`
    );
    if (response.status === 200) {
      console.log("fetchArchitectsByTypeSlug response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchArchitectsByTypeSlug error", error);
    notify("Error while fetching professionals", "error");
    return error;
  }
};

export const fetchArchitectsBySlug = async (slug, page = 1) => {
  try {
    const response = await axios.get(
      `/user/rest/architects/?slug=${slug}&page=${page}`
    );
    if (response.status === 200) {
      console.log("fetchArchitectsBySlug response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchArchitectsBySlug error", error);
    notify("Error while fetching professionals", "error");
    return error;
  }
};

export const fetchArchitectsPagewise = async (page = 1) => {
  try {
    const response = await axios.get(`/user/rest/architects/?page=${page}`);
    if (response.status === 200) {
      console.log("fetchArchitectsPagewise response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchArchitectsPagewise error", error);
    notify("Error while fetching architects", "error");
    return error;
  }
};

export const fetchRecommendedArchitects = async () => {
  try {
    const response = await axios.get(
      `/user/rest/architects/?recommended=true`
    );
    if (response.status === 200) {
      console.log("fetchRecommendedArchitects response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchRecommendedArchitects error", error);
    notify("Error while fetching architects", "error");
    return error;
  }
};

export const fetchArchitectByUser = async (userId) => {
  try {
    const response = await axios.get(`/user/rest/architects/?user=${userId}`);
    if (response.status === 200) {
      console.log("fetchArchitectByUser response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchArchitectByUser error", error);
    notify("Error while fetching architect", "error");
    return error;
  }
};

export const fetchArchitect = async (professionalId) => {
  try {
    const response = await axios.get(
      `/user/rest/architects/${professionalId}/`
    );
    if (response.status === 200) {
      console.log("fetchArchitect response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchArchitect error", error);
    notify("Error while fetching architect", "error");
    return error;
  }
};

export const addArchitect = async () => {
  try {
    const response = await axios.post(`/user/rest/architects/`);
    if (response.status === 201) {
      console.log("addArchitect response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("addArchitect error", error);
    notify("Error while adding architects", "error");
    return error;
  }
};

export const updateArchitect = async (architectId) => {
  try {
    const response = await axios.put(`/user/rest/architects/${architectId}/`);
    if (response.status === 200) {
      console.log("updateArchitect response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("updateArchitect error", error);
    notify("Error while updating architects", "error");
    return error;
  }
};

/////////////// -------- BUILDER -------- ///////////////

export const fetchBuilders = async () => {
  try {
    const response = await axios.get(`/user/rest/builders/`);
    if (response.status === 200) {
      console.log("fetchBuilders response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchBuilders error", error);
    notify("Error while fetching builders", "error");
    return error;
  }
};

export const fetchBuildersByTypeSlug = async (slug, page = 1) => {
  try {
    const response = await axios.get(
      `/user/rest/builders/?user_subtype_slug=${slug}&page=${page}`
    );
    if (response.status === 200) {
      console.log("fetchBuildersByTypeSlug response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchBuildersByTypeSlug error", error);
    notify("Error while fetching builders", "error");
    return error;
  }
};

export const fetchBuildersPagewise = async (page = 1) => {
  try {
    const response = await axios.get(`/user/rest/builders/?page=${page}`);
    if (response.status === 200) {
      console.log("fetchBuildersPagewise response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchBuildersPagewise error", error);
    notify("Error while fetching builders", "error");
    return error;
  }
};

export const fetchRecommendedBuilders = async () => {
  try {
    const response = await axios.get(
      `/user/rest/builders/?recommended=${true}`
    );
    if (response.status === 200) {
      console.log("fetchRecommendedBuilders response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchRecommendedBuilders error", error);
    notify("Error while fetching builders", "error");
    return error;
  }
};

export const fetchBuilder = async (builderId) => {
  try {
    const response = await axios.get(`/user/rest/builders/${builderId}/`);
    if (response.status === 200) {
      console.log("fetchBuilder response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchBuilder error", error);
    notify("Error while fetching builder", "error");
    return error;
  }
};

export const addBuilder = async (data) => {
  try {
    const response = await axios.post(`/user/rest/builders/`, data);
    if (response.status === 201) {
      console.log("addBuilder response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("addBuilder error", error);
    notify("Error while adding builder", "error");
    return error;
  }
};

export const updateBuilder = async (builderId, data) => {
  try {
    const response = await axios.put(`/user/rest/builders/${builderId}/`, data);
    if (response.status === 200) {
      console.log("updateBuilder response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("updateBuilder error", error);
    notify("Error while updating builder", "error");
    return error;
  }
};

export const fetchBuildersDataByUser = async (userId) => {
  try {
    const response = await axios.get(`/user/rest/builders/?user=${userId}`);
    if (response.status === 200) {
      console.log("fetchBuildersDataByUser response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchBuildersDataByUser error", error);
    notify("Error while fetching builder's data", "error");
    return error;
  }
};

/////////////// -------- REVIEWS -------- ///////////////

export const fetchReviewsByArchitect = async (architectId) => {
  try {
    const response = await axios.get(
      `/marketing/review-ratings/?architect__user=${architectId}`
    );
    if (response.status === 200) {
      console.log("fetchReviewsByArchitect response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchReviewsByArchitect error", error);
    notify("Error while fetching reviews for this architect", "error");
    return error;
  }
};

export const fetchReviewsByArchitectUser = async (userId) => {
  try {
    const response = await axios.get(
      `/marketing/review-ratings/?architect__user=${userId}`
    );
    if (response.status === 200) {
      console.log("fetchReviewsByArchitectUser response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchReviewsByArchitectUser error", error);
    notify("Error while fetching reviews for this architect", "error");
    return error;
  }
};

export const fetchReviewsByBrand = async (brandId) => {
  try {
    const response = await axios.get(
      `/marketing/review-ratings/?brand=${brandId}`
    );
    if (response.status === 200) {
      console.log("fetchReviewsByBrand response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchReviewsByBrand error", error);
    notify("Error while fetching reviews for this brand", "error");
    return error;
  }
};

export const fetchReviewsByBrandUser = async (userId) => {
  try {
    const response = await axios.get(
      `/marketing/review-ratings/?brand=${userId}`
    );
    if (response.status === 200) {
      console.log("fetchReviewsByBrandUser response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchReviewsByBrandUser error", error);
    notify("Error while fetching reviews for this brand", "error");
    return error;
  }
};

export const fetchReviewsByBuilder = async (builderId) => {
  try {
    const response = await axios.get(
      `/marketing/review-ratings/?builder__user=${builderId}`
    );
    if (response.status === 200) {
      console.log("fetchReviewsByBuilder response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchReviewsByBuilder error", error);
    notify("Error while fetching reviews for this builder", "error");
    return error;
  }
};

export const fetchReviewsByBuilderUser = async (userId) => {
  try {
    const response = await axios.get(
      `/marketing/review-ratings/?builder__user=${userId}`
    );
    if (response.status === 200) {
      console.log("fetchReviewsByBuilderUser response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchReviewsByBuilderUser error", error);
    notify("Error while fetching reviews for this builder", "error");
    return error;
  }
};

// add review
export const addReview = async (data) => {
  try {
    const response = await axios.post(`/marketing/review-ratings/`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 201) {
      console.log("addReview response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("addReview error", error);
    notify("Error while submitting review", "error");
    return error;
  }
};

/////////////// -------- TESTIMONIALS -------- ///////////////

export const fetchTestimonialsWithType = async () => {
  try {
    const response = await axios.get(
      `/marketing/testimonials/testimonials_by_type/`
    );
    if (response.status === 200) {
      console.log("fetchTestimonialsWithType response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchTestimonialsWithType error", error);
    notify("Error while fetching testimonials", "error");
    return error;
  }
};

/////////////// -------- CATEGORIES -------- ///////////////

// Note: categories in frontend is same as product-type in backend
export const fetchProductTypes = async (id) => {
  try {
    const response = await axios.get(
      `/product/product-types/${id}/producttypes_no_pagination/`
    );
    if (response.status === 200) {
      console.log("fetchProductTypes response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProductTypes error", error);
    notify("Error while fetching categories", "error");
    return error;
  }
};

export const fetchProductSubTypes = async (id) => {
  try {
    const response = await axios.get(
      `/product/product-sub-types/${id}/productsubtypes_no_pagination/`
    );
    if (response.status === 200) {
      console.log("fetchProductSubTypes response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProductTypes error", error);
    notify("Error while fetching categories", "error");
    return error;
  }
};


export const fetchProductCategories = async () => {
  try {
    const response = await axios.get(
      `/product/product-category/categories_no_pagination/`
    );
    if (response.status === 200) {
      console.log("fetchProductCategories response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProductTypes error", error);
    notify("Error while fetching categories", "error");
    return error;
  }
};


/////////////// -------- BRANDS -------- ///////////////

export const fetchBrands = async () => {
  try {
    const response = await axios.get(`/user/rest/brand-info/`);
    if (response.status === 200) {
      console.log("fetchBrands response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchBrands error", error);
    notify("Error while fetching brands", "error");
    return error;
  }
};

export const fetchBrandsBySlug = async (slug, page = 1) => {
  try {
    const response = await axios.get(
      `/user/rest/brand-info/?slug=${slug}&page=${page}`
    );
    if (response.status === 200) {
      console.log("fetchBrandsBySlug response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchBrandsBySlug error", error);
    notify("Error while fetching brands", "error");
    return error;
  }
};

export const fetchBrandsPagewise = async (page = 1) => {
  try {
    const response = await axios.get(`/user/rest/brand-info/?page=${page}`);
    if (response.status === 200) {
      console.log("fetchBrandsPagewise response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchBrandsPagewise error", error);
    notify("Error while fetching brands", "error");
    return error;
  }
};

export const fetchBrandsByCategories = async (type) => {
  try {
    const response = await axios.get(`/user/rest/brand-info/brands_by_categories/?type=${type}`);
    if (response.status === 200) {
      console.log("fetchBrandsByCategories response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchBrandsByCategories error", error);
    return error;
  }
};

export const fetchBrandCategoriesByTypes = async () => {
  try {
    const response = await axios.get(`/user/rest/brand-info/brands_categories_by_type/`);
    if (response.status === 200) {
      console.log("fetchBrandsByCategories response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchBrandsByCategories error", error);
    return error;
  }
};

export const fetchBrandInfo = async (brandInfoId) => {
  try {
    const response = await axios.get(`/user/rest/brand-info/${brandInfoId}/`);
    if (response.status === 200) {
      console.log("fetchBrand response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchBrand error", error);
    notify("Error while fetching brand details", "error");
    return error;
  }
};

export const fetchBrandByUser = async (brandUserId) => {
  try {
    const response = await axios.get(
      `/user/rest/brand-info/?user=${brandUserId}`
    );
    if (response.status === 200) {
      console.log("fetchBrandByUser response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchBrandByUser error", error);
    notify("Error while fetching brand details", "error");
    return error;
  }
};

export const fetchRecommendedBrands = async () => {
  try {
    // const response = await axios.get(`/user/rest/brand-info/recommended/`);
    const response = await axios.get(
      `/user/rest/brand-info/?recommended=${true}`
    );
    if (response.status === 200) {
      console.log("fetchRecommendedBrands response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchRecommendedBrands error", error);
    notify("Error while fetching brands", "error");
    return error;
  }
};

export const saveBrand = async (data) => {
  try {
    const response = await axios.post(`/user/rest/save-brand/`, data);
    if (response.status === 200) {
      console.log("saveBrand response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("saveBrand error", error);
    return error;
  }
};

export const unsaveBrand = async (data) => {
  try {
    const response = await axios.post(`/user/rest/unsave-brand/`, data);
    if (response.status === 200) {
      console.log("unsaveBrand response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("unsaveBrand error", error);
    return error;
  }
};

/////////////// -------- ENQUIRY -------- ///////////////

export const submitBrandEnquiry = async (data) => {
  try {
    const response = await axios.post(`/user/rest/brand-enquiry/`, data);
    if (response.status === 200) {
      console.log("submitBrandEnquiry response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("submitBrandEnquiry error", error);
    return error;
  }
};

/////////////// -------- CONSULTATION CALLBACK -------- ///////////////

export const requestConsultationCallback = async (data) => {
  try {
    const response = await axios.post(`/order/orders/`, data);
    if (response.status === 201 || response.status === 200) {
      console.log("requestConsultationCallback response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("requestConsultationCallback error", error);
    return error;
  }
};

/////////////// -------- PROPERTY -------- ///////////////

export const loadCustomPaginatedProperties = async (
  url, projectType, constructionStatus, propertySegment, sortBy, location
) => {
  let changedUrl = url;
  const params = [];

  if (projectType && projectType !== null && projectType !== "null") {
    params.push(`project_type__slug=${projectType}`);
  }
  if (constructionStatus && constructionStatus !== null && constructionStatus !== "null") {
    params.push(`construction_status=${constructionStatus}`);
  }
  if (propertySegment && propertySegment !== null && propertySegment !== "null") {
    params.push(`property_segment=${propertySegment}`);
  }
  if (sortBy && sortBy !== null && sortBy !== "null") {
    params.push(`ordering=${sortBy}`);
  }
  if (location && location !== null && location !== "null") {
    params.push(`ordering=${location}`);
  }

  if (params.length > 0) {
    changedUrl += "&" + params.join("&");
  }

  try {
    const response = await axios.get(changedUrl);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    notify("Error while fetching properties!", "error");
    return error;
  }
};

export const fetchProperties = async () => {
  try {
    const response = await axios.get(
      `/property/properties/?status=APPROVED`
    );
    if (response.status === 200) {
      console.log("fetchProperties response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProperties error", error);
    notify("Error while fetching properties", "error");
    return error;
  }
};

export const fetchAllProperties = async () => {
  try {
    const response = await axios.get(
      `/property/properties/property_no_pagination/`
    );
    if (response.status === 200) {
      console.log("fetchAllProperties response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchAllProperties error", error);
    notify("Error while fetching properties", "error");
    return error;
  }
};

export const fetchPropertyById = async (propertyId) => {
  try {
    const response = await axios.get(`/property/properties/${propertyId}/`);
    if (response.status === 200) {
      console.log("fetchPropertyById response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchPropertyById error", error);
    notify("Error while fetching property", "error");
    return error;
  }
};

export const fetchPropertyBySlug = async (propertySlug) => {
  try {
    const response = await axios.get(`/property/properties/?slug=${propertySlug}`);
    if (response.status === 200) {
      console.log("fetchPropertyBySlug response: ", response.data.results);
      return response;
    }
  } catch (error) {
    console.log("fetchPropertyBySlug error", error);
    notify("Error while fetching property", "error");
    return error;
  }
};

export const fetchPropertiesByBuilderNoPagination = async (userId) => {
  try {
    const response = await axios.post(
      `/property/properties/property_no_pagination/`, { 'user_id': userId }
    );
    if (response.status === 200) {
      console.log("fetchPropertiesByBuilderNoPagination response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchPropertiesByBuilderNoPagination error", error);
    notify("Error while fetching properties by builder", "error");
    return error;
  }
}

export const fetchPropertiesByBuilderUser = async (url, propertyName) => {
  if (propertyName) {
    url += `&property_name__icontains=${propertyName}`
  }
  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      // console.log("fetchPropertiesByBuilderUser response: ", response.data);
      return response.data;
    }
  } catch (error) {
    // console.log("fetchPropertiesByBuilderUser error", error);
    notify("Error while fetching properties by builder!", "error");
    return error;
  }
};

export const createProperty = async (formData) => {
  try {
    const response = await axios.post(
      "/property/properties/custom_create/",
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    if (response.status === 201) {
      console.log("createProperty response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("createProperty error", error);
    notify(error.response.data.msg || "Error while creating Property!", "error");
    return error;
  }
};

export const updateProperty = async (id, formData) => {
  try {
    const response = await axios.put(
      `/property/properties/${id}/`,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    if (response.status === 201) {
      console.log("updateProperty response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("updateProperty error", error);
    notify(error.response.data.msg || "Error while updating Property!", "error");
    return error;
  }
};

export const archivePropertyOrCancelRequest = async (id, data) => {
  try {
    const response = await axios.patch(
      `/property/properties/${id}/patch_actions/`,
      data,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    if (response.status === 200) {
      console.log("archiveoPropertyOrCancelRequest response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("archiveoPropertyOrCancelRequest error", error);
    notify(error.response.data.msg || "There was an error encountered during the request!", "error");
    return error;
  }
};

export const saveProperty = async (userId, propertyId) => {
  try {
    const response = await axios.post(`/property/saved-property/save_property/`, {
      user: userId,
      property: propertyId,
    });
    if (response.status === 201 || response.status === 200) {
      console.log("saveProperty response: ", response.data);
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.log("saveProperty error", error);
    notify("Error while saving property", "error");
    // return error;
    return false;
  }
};

export const unsaveProperty = async (userId, propertyId) => {
  try {
    const response = await axios.post(`/property/saved-property/unsave_property/`, {
      user: userId,
      property: propertyId,
    }
    );
    if (response.status === 204 || response.status === 200) {
      console.log("unsaveProperty response: ", response.data);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("unsaveProperty error", error);
    notify("Error while unsaving property", "error");
    // return error;
    return false;
  }
};

export const viewProperty = async (userId, propertyId) => {
  try {
    const response = await axios.post(`/property/viewed-property/view_property/`, {
      user: userId,
      property: propertyId,
    });
    if (response.status === 201 || response.status === 200) {
      console.log("viewProperty response: ", response.data);
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.log("viewProperty error", error);
    return false;
  }
};

export const notInterestedProperty = async (userId, propertyId) => {
  try {
    const response = await axios.post(`/property/not-interested-property/not_interested_property/`, {
      user: userId,
      property: propertyId,
    });
    if (response.status === 201 || response.status === 200) {
      console.log("viewProperty response: ", response.data);
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.log("viewProperty error", error);
    return false;
  }
};

/////////////// -------- PROPERTY RESOURCES -------- ///////////////

export const fetchResourcesByProperty = async (propertyId) => {
  try {
    const response = await axios.get(
      `/property/property-resources/?project_property=${propertyId}/`
    );
    if (response.status === 200) {
      console.log("fetchResourcesByProperty response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchResourcesByProperty error", error);
    notify("Error while fetching resources by property", "error");
    return error;
  }
};

/////////////// -------- PROJECTS -------- ///////////////

export const loadProjects = async (
  nextUrl,
  builderUserId,
  projectType,
  location,
  property,
  bhk,
  sortBy
) => {
  let url = nextUrl ? nextUrl : `/project/projects/`;
  const params = [];

  if (!nextUrl) {
    if (builderUserId) {
      params.push(`project_property__builder__user=${builderUserId}`);
    }
    if (projectType && projectType !== null && projectType !== "null") {
      params.push(`project_type__slug=${projectType}`);
    }
    if (property && property !== null && property !== "null") {
      params.push(`project_property__slug__icontains=${property}`);
    }
    if (location && location !== null && location !== "null") {
      params.push(`project_property__location__icontains=${location}`);
    }
    if (bhk && bhk !== null && bhk !== "null") {
      params.push(`bhk=${bhk}`);
    }
    if (sortBy && sortBy !== null && sortBy !== "null") {
      params.push(`ordering=${sortBy}`);
    }
    if (params.length > 0) {
      url += "?" + params.join("&");
    }
  }

  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      // console.log("fetchProjects response: ", response.data);
      return response.data;
    }
  } catch (error) {
    // console.log("fetchProjects error", error);
    notify("Error while fetching projects", "error");
    return error;
  }
};

export const fetchProjects = async (
  url,
  builderUserId,
  projectType,
  property,
  location,
  bhk,
  sortBy
) => {
  let modifiedUrl = url;
  const params = [];

  if (builderUserId) {
    params.push(`project_property__builder__user=${builderUserId}`);
  }

  if (projectType && projectType !== null && projectType !== "null") {
    params.push(`project_type__slug=${projectType}`);
  }
  if (property && property !== null && property !== "null") {
    params.push(`project_property__slug__icontains=${property}`);
  }

  if (location && location !== null && location !== "null") {
    params.push(`project_property__location__icontains=${location}`);
  }
  if (bhk && bhk !== null && bhk !== "null") {
    params.push(`bhk=${bhk}`);
  }
  if (sortBy && sortBy !== null && sortBy !== "null") {
    params.push(`ordering=${sortBy}`);
  }

  if (params.length > 0) {
    modifiedUrl += "?" + params.join("&");
  }

  try {
    const response = await axios.get(modifiedUrl);
    if (response.status === 200) {
      // console.log("fetchProjects response: ", response.data);
      return response.data;
    }
  } catch (error) {
    // console.log("fetchProjects error", error);
    notify("Error while fetching projects", "error");
    return error;
  }
};

export const fetchProject = async (projectId) => {
  try {
    const response = await axios.get(`/project/projects/${projectId}/`);
    if (response.status === 200) {
      console.log("fetchProject response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProject error", error);
    notify("Error while fetching project", "error");
    return error;
  }
};

export const fetchProjectsBySlug = async (slug, page = 1) => {
  try {
    const response = await axios.get(
      `/project/projects/?project_type_slug=${slug}&page=${page}`
    );
    if (response.status === 200) {
      console.log("fetchProjectsBySlug response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProjectsBySlug error", error);
    notify("Error while fetching projects", "error");
    return error;
  }
};

export const fetchProjectBySlug = async (slug, page = 1) => {
  try {
    const response = await axios.get(
      `/project/projects/?slug=${slug}&page=${page}`
    );
    if (response.status === 200) {
      console.log("fetchProjectBySlug response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProjectBySlug error", error);
    notify("Error while fetching project", "error");
    return error;
  }
};

export const fetchProjectByProperty = async (propertyId) => {
  try {
    const response = await axios.get(
      `/project/projects/?project_property=${propertyId}`
    );
    if (response.status === 200) {
      console.log("fetchProjectByProperty response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProjectByProperty error", error);
    notify("Error while fetching project for this property", "error");
    return error;
  }
};

export const fetchProjectsByArchitect = async (architectId) => {
  try {
    const response = await axios.get(
      `/project/projects/?architect=${architectId}`
    );
    if (response.status === 200) {
      console.log("fetchProjectsByArchitect response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProjectsByArchitect error", error);
    notify("Error while fetching project for this architect", "error");
    return error;
  }
};

export const fetchProjectsByArchitectUser = async (userId) => {
  try {
    const response = await axios.get(
      `/project/projects/?architect__user=${userId}`
    );
    if (response.status === 200) {
      console.log("fetchProjectsByArchitectUser response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProjectsByArchitectUser error", error);
    notify("Error while fetching project for this architect", "error");
    return error;
  }
};

export const fetchProjectsByBuilder = async (builderId) => {
  try {
    const response = await axios.get(`/project/projects/?builder=${builderId}`);
    if (response.status === 200) {
      console.log("fetchProjectsByBuilder response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProjectsByBuilder error", error);
    notify("Error while fetching project for this builder", "error");
    return error;
  }
};

export const fetchProjectsByBuilderUser = async (userId) => {
  try {
    const response = await axios.get(
      `/project/projects/?project_property__builder__user=${userId}`
    );
    if (response.status === 200) {
      console.log("fetchProjectsByBuilderUser response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProjectsByBuilderUser error", error);
    notify("Error while fetching project for this builder", "error");
    return error;
  }
};

export const fetchRecommendedProjects = async () => {
  try {
    const response = await axios.get(
      `/project/projects/?recommended=${true}&status=APPROVED`
    );
    if (response.status === 200) {
      console.log("fetchRecommendedProjects response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchRecommendedProjects error", error);
    notify("Error while fetching recommended projects", "error");
    return error;
  }
};

export const fetchProjectTypes = async () => {
  try {
    const response = await axios.get(`/project/project-types/`);
    if (response.status === 200) {
      console.log("fetchProjectTypes response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProjectTypes error", error);
    notify("Error while fetching project types", "error");
    return error;
  }
};

export const createProject = async (formData) => {
  try {
    const response = await axios.post(
      // "/project/projects/custom_create/",
      "/project/projects/create_project/",
      formData
      // { headers: { "Content-Type": "multipart/form-data" } }
    );
    console.log("createProject response: ", response);
    if (response.status === 201) {
      console.log("createProject response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("createProject error", error);
    notify(error.response.data.msg || "Error while creating project!", "error");
    return error;
  }
};

/////////////// -------- PRODUCTS -------- ///////////////

export const fetchProducts = async () => {
  try {
    const response = await axios.get("/product/products/");
    if (response.status === 200) {
      console.log("fetchProducts response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProducts error", error);
    notify("Error while fetching products", "error");
    return error;
  }
};

export const fetchProduct = async (productId) => {
  try {
    const response = await axios.get(`/product/products/${productId}/`);
    if (response.status === 200) {
      console.log("fetchProduct response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProduct error", error);
    notify("Error while fetching product", "error");
    return error;
  }
};

export const fetchProductsPagewise = async (
  page = 1,
  productTypeId,
  brandId,
  sortBy
) => {
  try {
    let url = `/product/products/?page=${page}`;
    if (productTypeId) {
      url += `&product_type=${productTypeId}`;
    }
    if (brandId) {
      url += `&brand=${brandId}`;
    }
    if (sortBy) {
      url += `&order_by=${sortBy}`;
    }
    const response = await axios.get(url);
    if (response.status === 200) {
      console.log("fetchProductsPagewise response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProductsPagewise error", error);
    notify("Error while fetching products", "error");
    return error;
  }
};

export const fetchProductsByBrandUser = async (brandUser, page = 1) => {
  try {
    const response = await axios.get(
      `/product/products/?brand__user=${brandUser}&page=${page}`
    );
    if (response.status === 200) {
      console.log("fetchProductsByBrandUser response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProductsByBrandUser error", error);
    notify("Error while fetching products for this brand", "error");
    return error;
  }
};

export const fetchProductsByBrandSlug = async (page = 1, brandSlug) => {
  try {
    const response = await axios.get(
      `/project/products/?brand_slug=${brandSlug}&page=${page}`
    );
    if (response.status === 200) {
      console.log("fetchProductsByBrandSlug response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProductsByBrandSlug error", error);
    notify("Error while fetching products for this brand", "error");
    return error;
  }
};

export const fetchProductsByRoom = async (roomId) => {
  try {
    const response = await axios.get(`/project/room-products/?room=${roomId}`);
    if (response.status === 200) {
      console.log("fetchProductsByRoom response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchProductsByRoom error", error);
    notify("Error while fetching products for this room", "error");
    return error;
  }
};
export const createProduct = async (formData) => {
  try {
    const response = await axios.post(
      // "/project/projects/custom_create/",
      "/product/products/create_product/",
      formData
      // { headers: { "Content-Type": "multipart/form-data" } }
    );
    console.log("createProduct response: ", response);
    if (response.status === 201) {
      console.log("createProduct response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("createProduct error", error);
    notify(error.response.data.msg || "Error while creating product!", "error");
    return error;
  }
};

export const loadAndRetrieveDataFromFile = async (formData) => {
  try {
    const response = await axios.post("/product/load-product-data/", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })

    console.log("Extracted Product Data Response: ", response.data);

    if (response.status === 200) {
      console.log("RetrieveDataFromFile response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("RetrieveDataFromFile error", error);
    notify(error.response.data.msg || "Error while retriving data from file!", "error");
    return error;
  }
}

/////////////// -------- SAVE PRODUCTS -------- ///////////////

export const saveProduct = async (userId, productId) => {
  try {
    const response = await axios.post(`/product/saved-product/save_product/`,
      { user: userId, product: productId }
    );
    if (response.status === 201 || response.status === 200) {
      console.log("saveProduct response: ", response.data);
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.log("saveProduct error", error);
    // notify("Error while saving product", "error");
    return false;
  }
};

export const unsaveProduct = async (userId, productId) => {
  try {
    const response = await axios.post(
      `/product/saved-product/unsave_product/`,
      { user: userId, product: productId }
    );
    if (response.status === 204 || response.status === 200) {
      console.log("unsaveProduct response: ", response.data);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("unsaveProduct error", error);
    // notify("Error while unsaving product", "error");
    return false;
  }
};

export const checkProductIfSaved = async (userId, productId) => {
  try {
    const response = await axios.post(
      `/product/saved-product/is_product_saved/`,
      { user: userId, product: productId }
    );
    if (response.status === 200) {
      console.log("checkProductIfSaved response: ", response.data);
      return response.data?.status;
    } else {
      return false;
    }
  } catch (error) {
    console.log("checkProductIfSaved error", error);
    // notify("Error while checking saved product status", "error");
    return false;
  }
};

/////////////// -------- PRODUCT ENQUIRY -------- ///////////////

export const addProductEnquiry = async (data) => {
  try {
    const response = await axios.post(`/product/product-enquiries/`, data);
    if (response.status === 201) {
      console.log("addProductEnquiry response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("addProductEnquiry error", error);
    notify("Erro while adding product enquiry", "error");
    return error;
  }
};

/////////////// -------- ROOMS -------- ///////////////
export const loadRooms = async (
  nextUrl,
  roomType,
  property,
  bhk,
  sortBy
) => {
  let url = `/project/rooms/`|| nextUrl;
  const params = [];

  if (roomType && roomType !== null && roomType !== "null") {
    console.log(roomType)
    params.push(`room_type_slug=${roomType}`);
  }
  if (property && property !== null && property !== "null") {
    params.push(`property_slug=${property}`);
  }
  if (bhk && bhk !== null && bhk !== "null") {
    params.push(`bhk=${bhk}`);
  }
  if (sortBy && sortBy !== null && sortBy !== "null") {
    params.push(`ordering=${sortBy}`);
  }
  // if (page) {
  //   params.push(`page=${page}`);
  // } else {
  //   params.push(`page=1`);
  // }

  if (params.length > 0) {
    url += "?" + params.join("&");
  }

  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      console.log("fetchRooms response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchRooms error", error);
    notify("Error while fetching rooms", "error");
    return error;
  }
};

export const fetchRooms = async (
  page = 1,
  roomType,
  property,
  bhk,
  sortBy
) => {
  let url = `/project/rooms/`;
  const params = [];

  if (roomType && roomType !== null && roomType !== "null") {
    console.log(roomType)
    params.push(`room_type_slug=${roomType}`);
  }
  if (property && property !== null && property !== "null") {
    params.push(`property_slug=${property}`);
  }
  if (bhk && bhk !== null && bhk !== "null") {
    params.push(`bhk=${bhk}`);
  }
  if (sortBy && sortBy !== null && sortBy !== "null") {
    params.push(`ordering=${sortBy}`);
  }
  if (page) {
    params.push(`page=${page}`);
  } else {
    params.push(`page=1`);
  }

  if (params.length > 0) {
    url += "?" + params.join("&");
  }

  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      console.log("fetchRooms response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchRooms error", error);
    notify("Error while fetching rooms", "error");
    return error;
  }
};

export const fetchRoomsByRoomTypeSlug = async (slug, page = 1) => {
  try {
    const response = await axios.get(
      `/project/rooms/?room_type_slug=${slug}&page=${page}`
    );
    if (response.status === 200) {
      console.log("fetchRoomsByRoomTypeSlug response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchRoomsByRoomTypeSlug error", error);
    notify("Error while fetching rooms", "error");
    return error;
  }
};

export const fetchRoomsByCategorySlug = async (slug, page = 1) => {
  try {
    const response = await axios.get(
      `/project/rooms/?room_category_slug=${slug}&page=${page}`
    );
    if (response.status === 200) {
      console.log("fetchRoomsByCategorySlug response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchRoomsByCategorySlug error", error);
    notify("Error while fetching rooms", "error");
    return error;
  }
};

export const fetchRoomTypes = async () => {
  try {
    const response = await axios.get(
      "/project/room-types/roomtypes_no_pagination/"
    );
    if (response.status === 200) {
      console.log("fetchRoomTypes response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchRoomTypes error", error);
    return error;
  }
};

// export const fetchRoomTypesWithCategories = async () => {
//   try {
//     const response = await axios.get(
//       "/project/room-types/roomtypes_by_categories/"
//     );
//     if (response.status === 200) {
//       console.log("fetchRoomTypesWithCategories response: ", response.data);
//       return response;
//     }
//   } catch (error) {
//     console.log("fetchRoomTypesWithCategories error", error);
//     return error;
//   }
// };

export const fetchRoomCategoriesData = async () => {
  try {
    const response = await axios.get(`/project/room-categories/`);
    if (response.status === 200) {
      console.log("fetchRoomCategoriesData response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchRoomCategoriesData error", error);
    return error;
  }
};

export const fetchRoomProductsByRoom = async (roomId) => {
  try {
    const response = await axios.get(`/project/room-products/?room=${roomId}`);
    if (response.status === 200) {
      console.log("fetchRoomProductsByRoom response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchRoomProductsByRoom error", error);
    return error;
  }
};

/////////////// -------- ROOMS -------- ///////////////

export const fetchRoomSubtypes = async () => {
  try {
    const response = await axios.get("/project/room-types/");
    if (response.status === 200) {
      console.log("fetchRoomSubtypes response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchRoomSubtypes error", error);
    return error;
  }
};

export const fetchRoomSubtypesByRoom = async (roomId) => {
  try {
    const response = await axios.get(`/project/room-types/?room=${roomId}`);
    if (response.status === 200) {
      console.log("fetchRoomSubtypesByRoom response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchRoomSubtypesByRoom error", error);
    return error;
  }
};

export const fetchRoomSubtypesByRoomCode = async (roomCode) => {
  try {
    const response = await axios.get(
      `/project/room-types/?room_code=${roomCode}`
    );
    if (response.status === 200) {
      console.log("fetchRoomSubtypesByRoomCode response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchRoomSubtypesByRoomCode error", error);
    return error;
  }
};

/////////////// -------- INSPIRATION -------- ///////////////

export const submitInspiration = async (projectId, data) => {
  try {
    const response = await axios.patch(`/project/projects/${projectId}/`, data);
    if (response.status === 200) {
      console.log("submitInspiration response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("submitInspiration error", error);
    return error;
  }
};

/////////////// -------- LIBRARY -------- ///////////////

export const fetchSavedDataByUser = async (data) => {
  try {
    const response = await axios.post(`/user/user_saved_data/`, data);
    if (response.status === 200) {
      console.log("fetchSavedDataByUser response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchSavedDataByUser error", error);
    notify("Error while fetching saved data", "error");
    return error;
  }
};

export const fetchSavedPropertiesByArchitect = async (data) => {
  try {
    const response = await axios.post(`/user/architect_saved_data/`, data);
    if (response.status === 200) {
      console.log("fetchSavedPropertiesByArchitect response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchSavedPropertiesByArchitect error", error);
    notify("Error while fetching saved data", "error");
    return error;
  }
};

export const fetchSavedProjects = async (userId) => {
  try {
    const response = await axios.get(`/project/saved-project/?user=${userId}`);
    if (response.status === 200) {
      console.log("fetchSavedProjects response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchSavedProjects error", error);
    notify("Error while fetching saved projects", "error");
    return error;
  }
};

export const fetchSavedRooms = async (userId) => {
  try {
    const response = await axios.get(`/project/saved-room/?=user${userId}`);
    if (response.status === 200) {
      console.log("fetchSavedRooms response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchSavedRooms error", error);
    notify("Error while fetching saved rooms", "error");
    return error;
  }
};

export const fetchSavedProfessionals = async (userId) => {
  try {
    const response = await axios.get(
      `/user/rest/saved-professional/?user=${userId}`
    );
    if (response.status === 200) {
      console.log("fetchSavedProfessionals response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchSavedProfessionals error", error);
    notify("Error while fetching saved professionals", "error");
    return error;
  }
};

/////////////// -------- SAVE / LIBRARY -------- ///////////////

export const saveProject = async (userId, projectId) => {
  try {
    const response = await axios.post(`/project/saved-project/save_project/`, {
      user: userId,
      project: projectId,
    });
    if (response.status === 201 || response.status === 200) {
      console.log("saveProject response: ", response.data);
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.log("saveProject error", error);
    notify("Error while saving project", "error");
    // return error;
    return false;
  }
};

export const unsaveProject = async (userId, projectId) => {
  try {
    const response = await axios.post(
      `/project/saved-project/unsave_project/`,
      {
        user: userId,
        project: projectId,
      }
    );
    if (response.status === 204 || response.status === 200) {
      console.log("unsaveProject response: ", response.data);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("unsaveProject error", error);
    notify("Error while unsaving project", "error");
    // return error;
    return false;
  }
};

export const checkProjectIfSaved = async (userId, projectId) => {
  try {
    const response = await axios.post(
      `/project/saved-project/is_project_saved/`,
      {
        user: userId,
        project: projectId,
      }
    );
    if (response.status === 200) {
      console.log("checkProjectIfSaved response: ", response.data);
      return response.data?.status;
    } else {
      return false;
    }
  } catch (error) {
    console.log("checkProjectIfSaved error", error);
    // notify("Error while checking saved project status", "error");
    // return error;
    return false;
  }
};

// ========== ROOM ==========
export const saveRoom = async (userId, roomId) => {
  try {
    const response = await axios.post(`/project/saved-room/save_room/`, {
      user: userId,
      room: roomId,
    });
    if (response.status === 201 || response.status === 200) {
      console.log("saveRoom response: ", response.data);
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.log("saveRoom error", error);
    notify("Error while saving room", "error");
    // return error;
    return false;
  }
};

export const unsaveRoom = async (userId, roomId) => {
  try {
    const response = await axios.post(`/project/saved-room/unsave_room/`, {
      user: userId,
      room: roomId,
    });
    if (response.status === 204 || response.status === 200) {
      console.log("unsaveRoom response: ", response.data);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("unsaveRoom error", error);
    notify("Error while unsaving room", "error");
    // return error;
    return false;
  }
};

export const checkRoomIfSaved = async (userId, roomId) => {
  try {
    const response = await axios.post(`/project/saved-room/is_room_saved/`, {
      user: userId,
      room: roomId,
    });
    if (response.status === 200) {
      console.log("checkRoomIfSaved response: ", response.data);
      return response.data?.status;
    } else {
      return false;
    }
  } catch (error) {
    console.log("checkRoomIfSaved error", error);
    // notify("Error while checking saved room status", "error");
    // return error;
    return false;
  }
};

// ========== PROFESSIONAL ==========
export const saveProfessional = async (userId, roomId) => {
  try {
    const response = await axios.post(
      `/user/rest/saved-professional/save_professional/`,
      {
        user: userId,
        professional: roomId,
      }
    );
    if (response.status === 201 || response.status === 200) {
      console.log("saveProfessional response: ", response.data);
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.log("saveProfessional error", error);
    notify("Error while saving professional", "error");
    // return error;
    return false;
  }
};

export const unsaveProfessional = async (userId, professionalId) => {
  try {
    const response = await axios.post(
      `/user/rest/saved-professional/unsave_professional/`,
      {
        user: userId,
        professional: professionalId,
      }
    );
    if (response.status === 204 || response.status === 200) {
      console.log("unsaveProfessional response: ", response.data);
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.log("unsaveProfessional error", error);
    notify("Error while unsaving professional", "error");
    // return error;
    return false;
  }
};

export const checkProfessionalIfSaved = async (userId, professionalId) => {
  try {
    const response = await axios.post(
      `/user/rest/saved-professional/is_professional_saved/`,
      {
        user: userId,
        professional: professionalId,
      }
    );
    if (response.status === 200) {
      console.log("checkProfessionalIfSaved response: ", response.data);
      return response.data?.status;
    }
  } catch (error) {
    console.log("checkProfessionalIfSaved error", error);
    // notify("Error while checking saved professional status", "error");
    return error;
  }
};

/////////////// -------- OTHERS INFO -------- ///////////////

export const saveOthersInfo = async (data) => {
  try {
    const response = await axios.post(`/user/rest/others-info/`, data);
    if (response.status === 201) {
      console.log("saveOthersInfo response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("saveOthersInfo error", error);
    return error;
  }
};


/////////////// -------- TEAM MEMBERS -------- ///////////////
export const fetchTeamMembers = async (data) => {
  try {
    const response = await axios.post(`/userprofile/rest/team-members/list-by-user/`, data);
    if (response.status === 200) {
      console.log("fetchTeamMembers response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchTeamMembers error", error);
    return error;
  }
};


/////////////// -------- ADD TEAM MEMBER -------- ///////////////
export const addTeamMember = async (data) => {
  try {
    const response = await axios.post(`/userprofile/rest/team-members/`, data);
    if (response.status === 201) {
      console.log("fetchTeamMembers response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchTeamMembers error", error);
    return error;
  }
};

/////////////// -------- DELETE TEAM MEMBER -------- ///////////////
export const deleteTeamMember = async (id) => {
  try {
    const response = await axios.delete(`/userprofile/rest/team-members/${id}/`,);
    if (response.status === 204) {
      return response;
    }
  } catch (error) {
    console.log("fetchTeamMembers error", error);
    return error;
  }
};

/////////////// -------- GET GALLERY -------- ///////////////
export const fetchGallery = async (data) => {
  try {
    const response = await axios.post(`/userprofile/rest/gallery/list-by-user/`, data);
    if (response.status === 200) {
      console.log("fetchGallery response: ", response.data);
      return response;
    }
  } catch (error) {
    console.log("fetchGallery error", error);
    return error;
  }
};