import React from "react";
import { Card, Image } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import { removeUnderscoreAndCapatilise } from "utilities/removeUnderscoreAndCapatilise";

function PreviewPropertyCard({
  property_name,
  builder,
  project_type,
  flats,
  layout_types,
  location,
  property_year,
  construction_status,
  property_segment,
  property_img,
  setCurrentStep,
}) {
  // const medium = useMediaQuery({ query: "(min-width: 768px)" });
  // const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <Card className="p-0 d-flex flex-column text-start my-4 shadow-none border-0">
      <div className="mb-3 border text-center" style={{ borderRadius: 6 }}>
        <Image
          src={property_img}
          style={{ height: 300, width:'100%' }}
        />
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="fw-bold mb-0">Property Details</h4>
        <div className="cursor-pointer" onClick={() => setCurrentStep(1)}>
          <CiEdit size={20} />
        </div>
      </div>
      <Card.Body className="w-100 p-0 body-text2">
        <div className="my-1">Property Name: {property_name} ({builder})</div>
        <div className="my-1">Number of Flats: {flats}</div>
        <div className="my-1">Construction Status: {removeUnderscoreAndCapatilise(construction_status)}</div>
        <div className="my-1">Property Year: {property_year}</div>
        <div className="my-1">Location: {location}</div>
        <div className="my-1">Property Type: {project_type}</div>
        <div className="my-1">Property Segment: {removeUnderscoreAndCapatilise(property_segment)}</div>
        <hr />
        <h4 className="fw-bold mb-0">Layout Types</h4>
        {layout_types?.map((lay) => {
          return <div className="my-1">{lay['layout']}: {lay['area']} sft.</div>
        })}
      </Card.Body>
    </Card>
  );
}

export default PreviewPropertyCard;
