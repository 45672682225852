import { useSelector } from "react-redux";
import ProjectCategoryCard from "components/Cards/ProjectCategoryCard";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import ProjectCard from "components/Cards/ProjectCard";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import * as authActions from "reduxFiles/actions/authActions";
import { useEffect, useState } from "react";
import { Library } from "pages/User/Library";
import { FaChevronRight } from "react-icons/fa";

const LoginHomepage = () => {
  const xsmall = useMediaQuery({ query: "(min-width: 400px)" });
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const [roomTypes, setRoomTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [trendingProjects, setTrendingProjects] = useState([]);

  const fetchRoomTypes = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRoomTypes();
      if (response.data) {
        setRoomTypes(response.data);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchRecommendedProjects = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchRecommendedProjects();
      if (response.data) {
        setTrendingProjects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRoomTypes();
    fetchRecommendedProjects();
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-next-arrow"}
        style={{ ...style, display: "flex", right: -25, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={30} />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-prev-arrow"}
        style={{ ...style, display: "flex", left: -25, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={30} />
      </div>
    );
  }
  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    // slidesToShow: !xsmall ? 2.5 : !small ? 3 : !large ? 4.5 : 5.8,
    slidesToShow: large ? 7.5 : medium ? 5 : small ? 3 : xsmall ? 2 : 1.5,
    slidesToScroll: !small ? 2 : 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Container fluid className="mt-5 py-4 px-0">
      <section className="bg-gray-25">
        <Container className="d-flex flex-column py-4">
          <div className="">
            {isAuthenticated && (
              <div className="h3 mb-0 inter-600" style={{ letterSpacing: "-3%" }}>
                {`Welcome to Interiokeys, ${user?.full_name.split(" ")[0]}!`}
              </div>
            )}
            <div className="body-text1 inter-400 text-gray-600">
              Start exploring projects, brands, professionals and more!
            </div>
          </div>
        </Container>
      </section>
      <Container className="d-flex flex-column py-0 mt-4">
        <div className="my-4">
          <div className="d-flex align-items-center">
            <div className="h3 inter-600 text-gray-900 mb-3">
              Top projects near you!
            </div>
            <div className="ms-auto mb-3">
              <div
                className="text-gray-700"
                onClick={() => navigate("/projects")}
              >
                <span className="body-text2">View All</span>
              </div>
            </div>
          </div>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "50vh" }}
            >
              <Spinner />
            </div>
          ) : trendingProjects?.length > 0 ? (
            <Row>
              <Col lg={4}>
                <Row xs={1} md={2} lg={1} className="p-0">
                  <Col className="">
                    {trendingProjects?.length > 0 && (
                      <div className="mb-4" style={{ height: medium && 300 }}>
                        <ProjectCard
                          {...trendingProjects[0]}
                          architect=""
                          location={
                            trendingProjects[0]?.property_data?.location
                          }
                          builders={
                            trendingProjects[0]?.property_data?.builder_data
                              ?.company_name
                          }
                          image={trendingProjects[0]?.rooms[0]?.media[0]?.file}
                          imgHeight={medium && 220}
                          style={{ height: medium && 300 }}
                        />
                      </div>
                    )}
                  </Col>
                  <Col className="">
                    {trendingProjects?.length > 1 && (
                      <div
                        className="mb-4 mb-lg-0"
                        style={{ height: medium && 300 }}
                      >
                        <ProjectCard
                          {...trendingProjects[1]}
                          architect=""
                          location={
                            trendingProjects[1]?.property_data?.location
                          }
                          builders={
                            trendingProjects[1]?.property_data?.builder_data
                              ?.company_name
                          }
                          image={trendingProjects[1]?.rooms[0]?.media[0]?.file}
                          imgHeight={medium && 220}
                          style={{ height: medium && 300 }}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col lg={8}>
                {trendingProjects?.length > 2 && (
                  // <Card className="cursor-pointer border p-0 w-100 flex-grow shadow-none" onClick={() => navigate(`/projects/${trendingProjects[2].slug}`)}>
                  //   <Card.Img className="img-fluid flex-grow" variant="top" src={trendingProjects[2]?.rooms[0]?.media[0]?.file} />
                  //   <Card.Body>
                  //     <Card.Title className="inter-700" style={{ color: "#344054", fontSize: "16px" }}> {trendingProjects[2]?.project_name} </Card.Title>
                  //     <div className="body-text2">
                  //       {/* <div className="inter-600"> Type: {trendingProjects[2].type} </div> */}
                  //       {/* <div>Architect: {trendingProjects[2].architect}</div> */}
                  //       <div> Location:{" "} {trendingProjects[2]?.property_data?.location} </div>
                  //       <div> Builders:{" "} {trendingProjects[2].property_data?.builder_data?.company_name} </div>
                  //     </div>
                  //   </Card.Body>
                  // </Card>
                  <div className="mb-4 mb-md-0">
                    <ProjectCard
                      {...trendingProjects[2]}
                      architect=""
                      location={trendingProjects[2]?.property_data?.location}
                      builders={
                        trendingProjects[2]?.property_data?.builder_data
                          ?.company_name
                      }
                      image={trendingProjects[2]?.rooms[0]?.media[0]?.file}
                      imgHeight={large ? 545 : medium && 340}
                      style={{ height: large ? 624 : medium && 400 }}
                    />
                  </div>
                )}
              </Col>
            </Row>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
              style={{ height: 160 }}
            >
              No trending projects available
            </div>
          )}
        </div>
        <div className="my-4">
          {roomTypes?.length > 0 && (
            <div className="mb-4">
              <div className="h3 inter-600">Pick the space you want to see!</div>
              <div style={{ width: "100%" }}>
                <Slider {...settings}>
                  {roomTypes?.map((room, index) => (
                    <div key={index} onClick={() => navigate("/spaces")}>
                      <ProjectCategoryCard {...room} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          )}
        </div>
        <Library />
      </Container>
    </Container>
  );
};

export default LoginHomepage;
