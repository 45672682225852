import React from "react";

const LoadingOverlay = ({ isLoading, setLoading }) => {
  return (
    <div className="loading-overlay d-flex flex-column text-center">
      <div className="loading-spinner" />
      <div className="d-flex flex-column justify-content-center align-items-center text-white my-4">
        <h5 className="mb-3">Please wait...</h5>
        {/* <div className="d-flex flex-column justify-content-center align-items-center">
          <h5></h5>
        </div> */}
      </div>
    </div>
  );
};

export default LoadingOverlay;
