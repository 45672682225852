// src/Slider.js
import React, { useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const CustomSlider = ({
  items,
  renderItem,
  currentItemIndex = 0,
  setCurrentIndex,
}) => {
  // const [currentIndex, setCurrentIndex] = useState(currentItemIndex);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const goToPrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + items.length) % items.length
    );
  };

  return (
    <div className="d-flex align-items-center position-relative w-100 overflow-hidden">
      <div className="position-absolute" style={{ left: 10, zIndex: 100 }}>
        <div
          className="d-flex justify-content-center align-items-center bg-gray-25 cursor-pointer rounded-btn"
          style={{ width: 40, height: 40 }}
          onClick={goToPrevious}
        >
          <MdKeyboardArrowLeft size={35} />
        </div>
      </div>
      <div className="cslider">
        <div
          className="d-flex cslider-items"
          style={{ transform: `translateX(-${currentItemIndex * 100}%)` }}
        >
          {items.map((item, index) => (
            <div key={index} className="cslider-item">
              {renderItem(item)}
            </div>
          ))}
        </div>
      </div>
      <div className="position-absolute" style={{ right: 10, zIndex: 100 }}>
        <div
          className="d-flex justify-content-center align-items-center bg-gray-25 cursor-pointer rounded-btn"
          style={{ width: 40, height: 40 }}
          onClick={goToNext}
        >
          <MdKeyboardArrowRight size={35} />
        </div>
      </div>
    </div>
  );
};

export default CustomSlider;
