import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ArchitectsForDisplayCard from "components/Cards/ArchitectsForDisplayCard";

const ArchitectsForDisplay = ({ recommendedArchitects }) => {

    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });

    return (
        <Container
            className="d-flex flex-column justify-content-center align-items-center text-center py-3"
            style={{ minHeight: "90vh" }}
        >
            <div
                className="h2 text-center mb-0 mx-md-5 inter-600"
            >
                Our professionals are certified and highly experienced!
            </div>
            <div className="text-gray-600 body-title inter-400 my-3">
                We have a strong network of 100+ professionals from design to execution to make your dream a reality!
            </div>
            <Button
                className="body-text2 inter-500 text-white mb-4"
                style={{ borderRadius: 3 }}
                onClick={() => navigate("/professionals/architect")}
            >
                Explore Professionals
            </Button>
            <Row className={`mt-3 px-4 px-lg-0 ${large ? "row-cols-4" : "row-cols-2"} justify-content-center`}>
                {recommendedArchitects.map((item, index) => {
                    return (
                        <div
                            // sm={6}
                            // xs={6}
                            // lg={3}
                            className={`mb-4 d-flex justify-content-center ${large ? "px-1" : ""}`}
                            key={index}
                        >
                            <ArchitectsForDisplayCard recommendedArchitect={item} />
                        </div>
                    )
                })}
            </Row>
        </Container>
        // <Container className="d-flex flex-column justify-content-center align-items-center text-center my-5">
        //     <Row
        //         className="w-90"
        //         style={{ margin: `${large ? "200px 0px" : "20px 0px"}` }}
        //     >
        //         <Col lg={6} className="text-end">
        //             <div className="h2 text-end">
        //                 Our architects are{" "}
        //                 <span className="inter-600">
        //                     certified and highly experienced!
        //                 </span>
        //             </div>
        //             <div className="body-title geist">
        //                 We have highly trained and experienced architects working with us
        //                 to make your dream a reality!{" "}
        //                 <span className="inter-600">
        //                     Check their projects, get to know them and choose who you want
        //                     to work with!
        //                 </span>
        //             </div>
        //             <Button
        //                 className="body-title text-white py-2 px-3 mt-4 inter-600"
        //                 style={{ borderRadius: 3 }}
        //                 onClick={() => navigate("/professionals")}
        //             >
        //                 Our Architects
        //             </Button>
        //         </Col>
        //         <Col lg={6}>
        //             {large ? (
        //                 <div className="img-container" style={{ marginLeft: "250px" }}>
        //                     <div
        //                         className="img-box bg-gray rounded overflow-hidden"
        //                         style={{ top: 150, left: -150 }}
        //                     >
        //                         <Button
        //                             className="p-0 position-absolute d-flex justify-content-center align-items-center"
        //                             style={{
        //                                 width: 45,
        //                                 height: 45,
        //                                 borderRadius: 23,
        //                                 right: 12,
        //                                 top: 12,
        //                             }}
        //                         >
        //                             <SlBadge size={24} />
        //                         </Button>
        //                         <div className="w-100">
        //                             <img
        //                                 src={recommendedArchitects[0]?.user_data?.profile_picture}
        //                                 alt={recommendedArchitects[0]?.user_data?.full_name}
        //                                 className="img-fluid w-100"
        //                                 style={{ objectFit: "cover" }}
        //                             />
        //                         </div>
        //                         <div
        //                             className="position-absolute bottom-0 rounded bg-gray text-gray-900 text-start w-95 p-2 m-2 d-flex flex-column"
        //                             style={{ background: "#FFFFFFBF" }}
        //                         >
        //                             <span className="body-title inter-600">
        //                                 {recommendedArchitects[0]?.user_data?.full_name}
        //                             </span>
        //                             <span className="">
        //                                 {recommendedArchitects[0]?.company_name} (
        //                                 {recommendedArchitects[0]?.rating}/5)
        //                             </span>
        //                         </div>
        //                     </div>
        //                     <div className="img-box bg-gray rounded overflow-hidden">
        //                         <Button
        //                             className="p-0 position-absolute d-flex justify-content-center align-items-center"
        //                             style={{
        //                                 width: 45,
        //                                 height: 45,
        //                                 borderRadius: 23,
        //                                 right: 12,
        //                                 top: 12,
        //                             }}
        //                         >
        //                             <SlBadge size={24} />
        //                         </Button>
        //                         <div className="w-100">
        //                             <img
        //                                 src={recommendedArchitects[1]?.user_data?.profile_picture}
        //                                 alt={recommendedArchitects[1]?.user_data?.full_name}
        //                                 className="img-fluid w-100"
        //                                 style={{ objectFit: "cover" }}
        //                             />
        //                         </div>{" "}
        //                         <div
        //                             className="position-absolute bottom-0 rounded bg-gray text-gray-900 text-start w-95 p-2 m-2 d-flex flex-column"
        //                             style={{ background: "#FFFFFFBF" }}
        //                         >
        //                             <span className="body-title inter-600">
        //                                 {recommendedArchitects[1]?.user_data?.full_name}
        //                             </span>
        //                             <span className="">
        //                                 {recommendedArchitects[2]?.company_name} (
        //                                 {recommendedArchitects[2]?.rating}/5)
        //                             </span>
        //                         </div>
        //                     </div>
        //                     <div
        //                         className="img-box bg-gray rounded overflow-hidden"
        //                         style={{ top: -150, left: -200, border: "30px" }}
        //                     >
        //                         <Button
        //                             className="p-0 position-absolute d-flex justify-content-center align-items-center"
        //                             style={{
        //                                 width: 45,
        //                                 height: 45,
        //                                 borderRadius: 23,
        //                                 right: 12,
        //                                 top: 12,
        //                             }}
        //                         >
        //                             <SlBadge size={24} />
        //                         </Button>
        //                         <div className="w-100">
        //                             <img
        //                                 src={recommendedArchitects[2]?.user_data?.profile_picture}
        //                                 alt={recommendedArchitects[2]?.user_data?.full_name}
        //                                 className="img-fluid w-100"
        //                                 style={{ objectFit: "cover" }}
        //                             />
        //                         </div>
        //                         <div
        //                             className="position-absolute bottom-0 rounded bg-gray text-gray-900 text-start w-95 p-2 m-2 d-flex flex-column"
        //                             style={{ background: "#FFFFFFBF" }}
        //                         >
        //                             <span className="body-title inter-600">
        //                                 {recommendedArchitects[2]?.user_data?.full_name}
        //                             </span>
        //                             <span className="">
        //                                 {recommendedArchitects[2]?.company_name} (
        //                                 {recommendedArchitects[2]?.rating}/5)
        //                             </span>
        //                         </div>
        //                     </div>
        //                 </div>
        //             ) : (
        //                 recommendedArchitects?.length > 0 && (
        //                     <Row className="justify-content-between mt-3">
        //                         <Col>
        //                             <div className="rounded overflow-hidden bg-gray w-100 h-100">
        //                                 <img
        //                                     className="img-fluid w-100"
        //                                     src={
        //                                         recommendedArchitects[0]?.user_data?.profile_picture
        //                                     }
        //                                     alt={recommendedArchitects[0]?.user_data?.full_name}
        //                                 />
        //                             </div>
        //                         </Col>
        //                         <Col>
        //                             <div className="rounded overflow-hidden bg-gray w-100 h-100">
        //                                 <img
        //                                     className="img-fluid w-100"
        //                                     src={
        //                                         recommendedArchitects[1]?.user_data?.profile_picture
        //                                     }
        //                                     alt={recommendedArchitects[1]?.user_data?.full_name}
        //                                 />
        //                             </div>
        //                         </Col>
        //                         <Col>
        //                             <div className="rounded overflow-hidden bg-gray w-100 h-100">
        //                                 <img
        //                                     className="img-fluid w-100"
        //                                     src={
        //                                         recommendedArchitects[2]?.user_data?.profile_picture
        //                                     }
        //                                     alt={recommendedArchitects[2]?.user_data?.full_name}
        //                                 />
        //                             </div>
        //                         </Col>
        //                     </Row>
        //                 )
        //             )}
        //         </Col>
        //     </Row>
        // </Container>
    )
}

export default ArchitectsForDisplay