import ShareModal from "components/General/ShareModal";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FaRegHeart, FaStar } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";

const AllProfessionalCard = ({
  id,
  name,
  slug,
  company_name,
  user_data,
  type,
  location,
  rating,
  project_count,
  expertise,
  project_names,
  image,
  imgHeight = "300px",
  onClick,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const [hover, setHover] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const checkProfessionalIfSaved = async () => {
    try {
      const response = await authActions.checkProfessionalIfSaved(user.id, id);
      if (response) {
        setIsLiked(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProfessional = async () => {
    try {
      const response = await authActions.saveProfessional(user.id, id);
      if (response.data) {
        setIsLiked(true);
        notify("Professional saved successfully", "success");
      } else {
        notify("Failed to save professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProfessional = async () => {
    try {
      const response = await authActions.unsaveProfessional(user.id, id);
      if (response) {
        setIsLiked(false);
        notify("Professional unsaved successfully", "success");
      } else {
        notify("Failed to unsave professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkProfessionalIfSaved();
  }, []);

  // const navigateToProfessional = () => {
  //   // navigate(`/professionals?type=${slug}`);
  //   navigate(`/professionals/${slug}`);
  // };

  return (
    <>
      <Card
        className={`cursor-pointer w-100 py-0 px-0 border rounded shadow-none overflow-hidden position-relative ${hover ? "border-gray-300" : "border-gray-50"}`}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Card.Img
          className="img-fluid"
          variant="top"
          src={image}
          style={{
            maxHeight: imgHeight,
            minHeight: imgHeight,
            objectFit: "cover",
          }}
        />
        <div
          className="d-flex position-absolute"
          style={{ zIndex: 999, right: 15, top: 10 }}
        >
          <Button
            className="d-flex justify-content-center align-items-center share-like-btn p-0"
            onClick={(e) => {
              e.stopPropagation();
              setShowShare(true);
            }}
          >
            <FiSend size={20} color="#3C4043" />
          </Button>
          {user &&
            user?.user_type !== "builder" &&
            user?.user_type !== "architect" &&
            user?.user_type !== "brand" &&
            <Button
              className={`d-flex justify-content-center align-items-center share-like-btn ms-2 p-0 ${isLiked ? "bg-primary text-white" : ""
                }`}
              onClick={(e) => {
                e.stopPropagation();
                isLiked ? handleUnsaveProfessional() : handleSaveProfessional();
              }}
            >
              <FaRegHeart size={20} color={isLiked ? "#FFFFFF" : "#3C4043"} />
            </Button>
          }
        </div>
        <Card.Body
          className="p-2 d-flex flex-column align-items-start"
          onClick={() => navigate(`/projects/${slug}`)}
        >
          <Card.Title
            className={`inter-700 mb-0 ${hover ? "text-primary-500" : "text-gray-900"}`}
            style={{ fontSize: "16px" }}
          >
            {company_name}
          </Card.Title>
          <div className="inter-600 d-flex align-items-center">
            Ratings:{" "}
            {rating ? (
              <div className="d-flex align-items-center">
                {rating} <FaStar color="#D95656" className="ms-1" />
              </div>
            ) : (
              "Unavailable"
            )}
            {/* {rating ? ( <span>{rating} <FaStar color="#D95656" /></span> ) : ( "Unavailable" )} */}
          </div>
          <div className="body-text2 inter-400 d-flex flex-column align-items-start">
            <span className="ms-0">Architects and Interioe Designers</span>
            <span className="ms-0">Inspirations Created: {project_names ?? "-"}</span>
          </div>
        </Card.Body>
      </Card>
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={id}
        image={image}
        title={company_name}
        urlPath={`/professionals/${slug}`}
      />
    </>
  );
};

export default AllProfessionalCard;
