import React from "react";
import { Container, Image, Spinner } from 'react-bootstrap';
import { znanyeloader, znanyeloaderwebm, znanyeloadermp4 } from "assets";

const Loader = () => {
    return (
        <Container
            fluid
            className="d-flex justify-content-center align-items-center position-fixed w-100 h-100"
            style={{ background: "#FFFFFF", ...styles.parentContainer }}
        >
            <Spinner />
        </Container>
    );
}

const styles = {
    parentContainer: {
        zIndex: 9999,
        top: 0,
        bottom: 0
    }
}

export default Loader;