import React, { useRef, useState } from "react";
import { Button, Card, Col, Container, Form, Image, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import { LuMail } from "react-icons/lu";
import { othersignup } from "assets";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";

const OtherSignup = ({ requiredSubtypes, step, setStep }) => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const form = useRef()

    const handleOtherRegister = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData(form.current)
        const response = await authActions.saveOthersInfo(formData);
        if (response) {
            form.current.reset();
            setModalShow(true);
        }
        setLoading(false);
    }

    return (
        <>
            <Container className="d-flex flex-column justify-content-center align-items-center mt-5"
                style={{ minHeight: "70vh" }}
            >
                <Row style={{ maxWidth: 1000 }}>
                    <Col lg={6} className="mb-3 mb-lg-0 d-flex flex-column justify-content-between align-items-center">
                        <Image src={othersignup} alt="Homeowner signup" width="80%" />
                        <Card style={{ boxShadow: "none", borderRadius: 0, border: "none", borderLeft: "1px solid #BDC1C6" }}>
                            <h4 className="text-gray-900 inter-600">Others(Executors) Registration</h4>
                            <div className="text-gray-500">
                                Showcase your projects to potential clients while
                                you design for the top properties in India.
                            </div>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Form ref={form} onSubmit={(e) => handleOtherRegister(e)}>
                            <Form.Group className="mb-3">
                                <Form.Label className="mb-1 body-text2 inter-600">
                                    Designer Category<sup style={{ color: "red" }}>*</sup>
                                </Form.Label>
                                <Form.Select
                                    className="py-2 body-text2"
                                    placeholder="-Select Category-"
                                    name="user_subtype"
                                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                                >
                                    {requiredSubtypes?.map((type, index) => (
                                        <option key={index} value={type.id}>
                                            {type.title}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="mb-1 body-text2 inter-600">
                                    Name<sup style={{ color: "red" }}>*</sup>
                                </Form.Label>
                                <Form.Control
                                    required
                                    className="py-2 body-text2"
                                    type="text"
                                    name="full_name"
                                    placeholder="Enter your Full Name"
                                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="mb-1 body-text2 inter-600">
                                    Company Name<sup style={{ color: "red" }}>*</sup>
                                </Form.Label>
                                <Form.Control
                                    required
                                    className="py-2 body-text2"
                                    type="text"
                                    name="company_name"
                                    placeholder="Enter your Full Name"
                                    minLength={3}
                                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="mb-1 body-text2 inter-600">
                                    Phone Number<sup style={{ color: "red" }}>*</sup>
                                </Form.Label>
                                <InputGroup>
                                    <InputGroup.Text
                                        className="body-text2 inter-600"
                                        style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: "3px 0px 0px 3px" }}
                                    >
                                        +91
                                    </InputGroup.Text>
                                    <Form.Control
                                        className="border-start-0 p-2 body-text2"
                                        type="text"
                                        name="mobile"
                                        required
                                        placeholder="9090909090"
                                        maxLength={10}
                                        pattern="[0-9]*"
                                        style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="mb-1 body-text2 inter-600">
                                    Company Email<sup style={{ color: "red" }}>*</sup>
                                </Form.Label>
                                <InputGroup>
                                    <InputGroup.Text
                                        style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: "3px 0px 0px 3px" }}
                                    >
                                        <LuMail size={20} color="#1A1C1F" />
                                    </InputGroup.Text>
                                    <Form.Control
                                        className="border-start-0 p-2 body-text2"
                                        type="email"
                                        name="email"
                                        required
                                        placeholder="example@gmail.com"
                                        style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-1">
                                <Form.Check
                                    type="checkbox"
                                    label={
                                        <div className="body-text2 inter-400">
                                            Send me important emails for work and job leads.
                                        </div>
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="mb-1">
                                <Form.Check
                                    type="checkbox"
                                    label={
                                        <div className="body-text2 inter-400">
                                            Yes, I understand and agree to the
                                            <span className="text-primary-500">
                                                {" "}
                                                Interiokeys Terms of Service
                                            </span>
                                            , including the
                                            <span className="text-primary-500">
                                                {" "}
                                                User Agreement
                                            </span>{" "}
                                            and
                                            <span className="text-primary-500"> Privacy Policy</span>.
                                        </div>
                                    }
                                    required
                                />
                            </Form.Group>
                            <div className="d-flex mt-5 w-100">
                                <Button
                                    className="px-3 py-2 me-3 text-gray-900 bg-white border-gray-200"
                                    style={{ borderRadius: 3 }}
                                    onClick={() => setStep(step - 1)}
                                >
                                    Back
                                </Button>
                                <Button
                                    className="w-100 p-2"
                                    type="submit"
                                    style={{ borderRadius: 3 }}
                                    disabled={loading}
                                >
                                    {loading && (
                                        <Spinner animation="border" size="sm" className="me-2" />
                                    )}
                                    Register
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Modal
                className="d-flex justify-content-center align-items-center"
                show={modalShow}
            >
                <div className="py-3 px-4">
                    <Modal.Body className="p-0">
                        <h1 className="h2 text-primary text-center inter-600 pt-3">
                            Thank You for Registering with Interiokeys!
                        </h1>
                        <div className="text-gray-900 text-center">
                            We will get in touch with you soon to start the onboarding process.
                        </div>
                        <Button
                            onClick={() => navigate(`/`)}
                            variant="primary"
                            className="mt-5 py-2 body-text2 border-0"
                            style={{
                                fontWeight: "600",
                                color: "#fff",
                                width: "100%",
                                borderRadius: 3
                            }}
                        >
                            Go to Homepage
                        </Button>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    )
}

export default OtherSignup