import { homeownersignup } from "assets";
import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Image, InputGroup, Row, Spinner } from "react-bootstrap";
import { LuMail } from "react-icons/lu";

const UserSignup = ({ form, handleUserSignUp, verifyOtpLogin, loading, otp, setOtp, setUsername }) => {
    const [showOtpFields, setShowOtpFields] = useState(false);

    const handleOtpChange = (e, index) => {
        const value = e.target.value;
        if (value.match(/^\d$/)) {
            setOtp((prev) => {
                const otpArray = prev.split("");
                otpArray[index] = value;
                return otpArray.join("");
            });
            if (index < 3) {
                document.getElementById(`otp-${index + 1}`).focus();
            }
        } else if (value === "") {
            setOtp((prev) => {
                const otpArray = prev.split("");
                otpArray[index] = "";
                return otpArray.join("");
            });
        }
    };

    return (
        <Container
            className="d-flex flex-column justify-content-center align-items-center mt-5"
            style={{ minHeight: "70vh" }}
        >
            <Row style={{ maxWidth: 1000 }}>
                <Col
                    lg={6}
                    className="mb-3 mb-lg-0 d-flex flex-column justify-content-between align-items-center"
                >
                    <Image src={homeownersignup} alt="Homeowner signup" width="80%" />
                    <Card
                        style={{
                            boxShadow: "none",
                            borderRadius: 0,
                            border: "none",
                            borderLeft: "1px solid #BDC1C6",
                        }}
                    >
                        <h4 className="inter-600">Client Signup</h4>
                        <div className="text-gray-600">
                            Find the best executable interior inspiration from the top designers using
                            the best products.
                        </div>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Form
                        ref={form}
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (!showOtpFields) {
                                handleUserSignUp();
                                setShowOtpFields(true);
                            } else {
                                verifyOtpLogin();
                            }
                        }}
                    >
                        <Form.Group className="mb-3">
                            <Form.Label className="mb-1 body-text2 inter-600">
                                Full Name<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <Form.Control
                                required
                                className="py-2 body-text2"
                                type="text"
                                name="full_name"
                                placeholder="Enter your Full Name"
                                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                                disabled={showOtpFields}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="mb-1 body-text2 inter-600">
                                Phone Number<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <InputGroup>
                                <InputGroup.Text
                                    className="body-text2 inter-600"
                                    style={{
                                        backgroundColor: "white",
                                        border: "1px solid #BDC1C6",
                                        borderRadius: 3,
                                    }}
                                >
                                    +91
                                </InputGroup.Text>
                                <Form.Control
                                    className="border-start-0 p-2 body-text2"
                                    type="text"
                                    name="mobile"
                                    required
                                    placeholder="9090909090"
                                    maxLength={10}
                                    pattern="[0-9]*"
                                    style={{
                                        border: "1px solid #BDC1C6",
                                        borderRadius: "0px 3px 3px 0px",
                                    }}
                                    disabled={showOtpFields}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="mb-1 body-text2 inter-600">
                                Email<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <InputGroup>
                                <InputGroup.Text
                                    style={{
                                        backgroundColor: "white",
                                        border: "1px solid #BDC1C6",
                                        borderRadius: 3,
                                    }}
                                >
                                    <LuMail size={20} color="#1A1C1F" />
                                </InputGroup.Text>
                                <Form.Control
                                    className="border-start-0 p-2 body-text2"
                                    type="email"
                                    name="email"
                                    required
                                    placeholder="example@gmail.com"
                                    style={{
                                        border: "1px solid #BDC1C6",
                                        borderRadius: "0px 3px 3px 0px",
                                    }}
                                    disabled={showOtpFields}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>
                        {showOtpFields && (
                            <Form.Group className="my-3">
                                <Form.Label className="mb-1 body-text2 inter-600">
                                    OTP<sup style={{ color: "red" }}>*</sup>
                                </Form.Label>
                                <Row>
                                    {[0, 1, 2, 3].map((index) => (
                                        <Col key={index} xs="3">
                                            <Form.Control
                                                required
                                                id={`otp-${index}`}
                                                maxLength="1"
                                                value={otp[index] || ""}
                                                onChange={(e) => handleOtpChange(e, index)}
                                                style={{
                                                    textAlign: "center",
                                                    border: "1px solid #BDC1C6",
                                                    borderRadius: 3,
                                                }}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                                <Form.Text className="text-success">
                                    OTP has been sent to the email address
                                </Form.Text>
                            </Form.Group>
                        )}
                        <div className="d-flex mt-5 w-100">
                            <Button
                                className="w-100 p-2"
                                type="submit"
                                style={{ borderRadius: 3 }}
                                disabled={loading}
                            >
                                {loading && <Spinner animation="border" size="sm" className="me-2" />}
                                {showOtpFields ? "Verify OTP" : "Sign Up"}
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default UserSignup;
