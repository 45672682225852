import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ConsultaionCard from "./ConsultationCard";
import { FaRegHeart } from "react-icons/fa6";
import { useEffect, useState } from "react";
import ShareModal from "components/General/ShareModal";
import { FiSend } from "react-icons/fi";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import { useSelector } from "react-redux";
import LoginModal from "components/Auth/LoginModal";

const ProjectCard = ({
  id,
  slug,
  project_name,
  image,
  bhk,
  builder_name,
  architect,
  property_name,
  isConsultation,
  boxShadow,
  imgHeight = "300px",
  className,
  style,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [showShare, setShowShare] = useState(false);
  const [hover, setHover] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);

  const checkProjectIfSaved = async () => {
    try {
      const response = await authActions.checkProjectIfSaved(user.id, id);
      if (response) {
        setIsLiked(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProject = async () => {
    try {
      const response = await authActions.saveProject(user.id, id);
      if (response.data) {
        setIsLiked(true);
        notify("Project saved!", "success");
      } else {
        notify("Failed to save project, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProject = async () => {
    try {
      const response = await authActions.unsaveProject(user.id, id);
      if (response) {
        setIsLiked(false);
        notify("Project unsaved!", "success");
      } else {
        notify("Failed to unsave project, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkProjectIfSaved();
  }, []);

  if (isConsultation) {
    return <ConsultaionCard />;
  }

  return (
    <>
      <Card
        className={`cursor-pointer w-100 py-0 px-0 border rounded shadow-none overflow-hidden position-relative ${hover ? "border-gray-300" : "border-gray-50"
          } ${className}`}
        style={{
          boxShadow: boxShadow,
          // backgroundColor: hover ? "rgba(0, 0, 0, 0.1)" : "",
          ...style,
        }}
        onClick={() => navigate(`/projects/${slug}`)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Card.Img
          className="img-fluid"
          variant="top"
          src={image}
          style={{
            maxHeight: imgHeight,
            minHeight: imgHeight,
            objectFit: "cover",
          }}
        />
        <div
          className="d-flex position-absolute"
          style={{ zIndex: 999, right: 15, top: 10 }}
        >
          <Button
            className="d-flex justify-content-center align-items-center share-like-btn p-0"
            onClick={(e) => {
              e.stopPropagation();
              setShowShare(true);
            }}
          >
            <FiSend size={20} />
          </Button>

          <Button
            className={`d-flex justify-content-center align-items-center share-like-btn ms-2 p-0 ${isLiked ? "bg-primary text-white" : ""
              }`}
            onClick={(e) => {
              e.stopPropagation();

              if (!user) {
                setShowLoginModal(true)
              } else {
                isLiked ? handleUnsaveProject() : handleSaveProject();
              }

            }}
          >
            <FaRegHeart size={20} color={isLiked ? "#FFFFFF" : ""} />
          </Button>

        </div>
        <Card.Body
          className="p-2 d-flex flex-column align-items-start"
          onClick={() => navigate(`/projects/${slug}`)}
        >
          <Card.Title className={`inter600 body-text1 mb-0 ${hover ? "text-primary-500" : "text-gray-800"}`}>
            {`${project_name}/${bhk}/${property_name}`}
          </Card.Title>
          <div className="body-text2 inter-400 text-gray-800 d-flex flex-column align-items-start">
            <span className="ms-0">Architect: {architect ?? "-"}</span>
            <span className="ms-0">Builder: {builder_name ?? "-"}</span>
          </div>
        </Card.Body>
      </Card>
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={id}
        image={image}
        title={`${project_name}/${bhk}/${property_name}`}
        urlPath={`/projects/${slug}`}
      />
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      />
    </>
  );
};

export default ProjectCard;
