import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { FaSave } from "react-icons/fa";
import { LuEye, LuEyeOff, LuMail, LuPencilLine } from "react-icons/lu";

import * as authActions from "reduxFiles/actions/authActions";
import { userUpdate } from "reduxFiles/reducer/authSlices";
import { notify } from "utilities/notifications/Notify";
import { MdClose } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { FaLock } from "react-icons/fa6";
import { FiEdit3 } from "react-icons/fi";

function ChangePasswordForm() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const [isPasswordEdit, setIsPasswordEdit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const form = useRef(null);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    formData.append("id", user.id);
    let data = {};
    formData.forEach((value, key) => (data[key] = value));
    if (formData.get('password') === formData.get('confirm_password')) {
      try {
        const response = await authActions.updateProfile(data.id, data);
        if (response.data) {
          notify("Password Updated Successfully!", "success");
          dispatch(userUpdate(response.data));
          setIsPasswordEdit(false);
        } else {
          notify("Error while updating password!", "error");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      notify("The confirm password doesn't match the new password!", "error");
    }
  };

  return (
    <Form ref={form} onSubmit={handleChangePassword} className="border border-gray-100 mb-4" style={{ borderRadius: 6 }}>
      <div className="d-flex justify-content-between p-3">
        <div className={`h4 inter-600 mt-2`}>
          Edit Password
        </div>
        <div className="mt-2 me-2 cursor-pointer" onClick={() => setIsPasswordEdit(!isPasswordEdit)}>
          <FiEdit3 size={20} />
        </div>
      </div>
      <Row className="px-3 pb-3">
        <Col lg={5}>
          <Form.Group className="mb-3 user-select-none text-gray-900">
            <Form.Label className="mb-1 body-text2 inter-500">
              Enter Existing Password<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup className="border border-gray-300" style={{ borderRadius: 3 }}>
              <InputGroup.Text
                className={`border-0 ${isPasswordEdit ? "bg-white cursor-pointer" : "bg-disabled"}`}
                style={{ borderRadius: 3 }}
                onClick={() => isPasswordEdit ? setShowPassword(!showPassword) : ""}
              >
                {showPassword ? <LuEye size={20} /> : <LuEyeOff size={20} />}
              </InputGroup.Text>
              <Form.Control
                className="border-0 body-text2 py-2"
                name="old_password"
                type={showPassword ? "text" : "password"}
                placeholder="**********"
                required
                disabled={!isPasswordEdit}
                style={{ borderRadius: "0px 3px 3px 0px" }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3 user-select-none">
            <Form.Label className="mb-1 body-text2 inter-500">
              Create New Password<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}>
              <InputGroup.Text
                className={`border-0 ${isPasswordEdit ? "bg-white cursor-pointer" : "bg-disabled"}`}
                style={{ borderRadius: 3 }}
                onClick={() => isPasswordEdit ? setShowNewPassword(!showNewPassword) : ""}
              >
                {showNewPassword ? <LuEye size={20} /> : <LuEyeOff size={20} />}
              </InputGroup.Text>
              <Form.Control
                className="border-0 body-text2 py-2"
                name="password"
                type={showNewPassword ? "text" : "password"}
                placeholder="**********"
                required
                disabled={!isPasswordEdit}
                pattern="^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$"
                title="Password must contain at least 1 uppercase letter, 1 digit, and be at least 8 characters long"
                style={{ borderRadius: "0px 3px 3px 0px" }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="user-select-none">
            <Form.Label className="mb-1 body-text2 inter-500">
              Confirm New Password<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}>
              <InputGroup.Text
                className={`border-0 ${isPasswordEdit ? "bg-white cursor-pointer" : "bg-disabled"}`}
                style={{ borderRadius: 3 }}
                onClick={() => isPasswordEdit ? setShowConfirmPassword(!showConfirmPassword) : ""}
              >
                {showConfirmPassword ? <LuEye size={20} /> : <LuEyeOff size={20} />}
              </InputGroup.Text>
              <Form.Control
                className="border-0 body-text2 py-2"
                name="confirm_password"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="**********"
                required
                disabled={!isPasswordEdit}
                pattern="^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$"
                title="Password must contain at least 1 uppercase letter, 1 digit, and be at least 8 characters long"
                style={{ borderRadius: "0px 3px 3px 0px" }}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      {isPasswordEdit && (
        <div className="d-flex p-3">
          <Button
            className="bg-transparent inter-500 border-gray-200 text-gray-900 body-text2"
            style={{ borderRadius: 3 }}
            onClick={() => setIsPasswordEdit(false)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="inter-500 body-text2 text-white ms-2 d-flex justify-content-center align-items-center"
            style={{ borderRadius: 3 }}
          >
            <FaLock size={14} className="me-2" />
            Change
          </Button>
        </div>
      )}
    </Form>
  );
}

export default ChangePasswordForm;
