import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Library from "./Library";
import { useLocation } from "react-router-dom";

const Saved = () => {

    const location = useLocation();

    const [showInspiration, setShowInspiration] = useState(false);
    const [showSpace, setShowSpace] = useState(false);
    const [showProfessional, setShowProfessional] = useState(false);

    useEffect(() => {
        if (location.hash !== "") {
            if (location.hash === "#inspirations") {
                setShowInspiration(true);
            } else if (location.hash === "#spaces") {
                setShowSpace(true);
            } else if (location.hash === "#professionals") {
                setShowProfessional(true);
            }
        }
    }, [location]);

    return (
        <Container className="mt-5 pt-4 px-0" style={{ minHeight: "80vh" }}>
            <Library
                showAllRecords={true}
                showInspiration={showInspiration}
                showSpace={showSpace}
                showProfessional={showProfessional}
            />
        </Container>
    );
};

export default Saved;
