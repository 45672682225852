import React, { useEffect } from "react";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import "./App.scss";
import { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { Provider } from "react-redux";
import store, { persistor } from "./reduxFiles/store";
import Routers from "./Routers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConf } from "reduxFiles/actions/authActions";
import { GoogleOAuthProvider } from "@react-oauth/google";

// persistor.purge();

const App = () => {
  setDefaultBreakpoints([
    { xsmall: 0 }, // all mobile devices
    { small: 576 }, // mobile devices (not sure which one's this big)
    { medium: 768 }, // ipad, ipad pro, ipad mini, etc
    { large: 992 }, // smaller laptops
    { xlarge: 1200 }, // laptops and desktops
    { xxlarge: 1400 }, // laptops and desktops
  ]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <BreakpointProvider>
        <Provider store={store}>
          <ToastContainer {...toastConf} />
          <Routers />
        </Provider>
      </BreakpointProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
