import React, { useEffect, useRef, useState } from "react";
import "./drop-file-input.css";
import { FaUpload, FaXmark } from "react-icons/fa6";
import { RiUpload2Fill } from "react-icons/ri";

const DropFileInputProject = ({
  onFileChange,
  allowMultiple = true,
  showFiles = true,
  className,
  style,
}) => {
  const wrapperRef = useRef(null);

  const [fileList, setFileList] = useState([]);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const temp = allowMultiple ? e.target.files : [e.target.files[0]];
    console.log(e);
    if (temp) {
      const updatedList = [...fileList, ...temp];
      setFileList(updatedList);
      onFileChange(updatedList);
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    onFileChange(updatedList);
  };

  return (
    <>
      {((!allowMultiple && fileList.length == 0) || allowMultiple) && (
        <div
          ref={wrapperRef}
          className={`drop-file-input position-relative d-flex justify-content-center align-items-center border border-dashed ${className}`}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          style={{
            width: "100%",
            height: 240,
            borderRadius: 8,
            ...style,
          }}
        >
          <div className="drop-file-input__label d-flex flex-column justify-content-center align-items-center">
            <RiUpload2Fill size={24} className="text-dark mb-3" />
            <p className="mb-0">
              Drag & Drop your files here or{" "}
              <u className="text-primary">click to upload</u>
            </p>
            <p className="body-text2 inter-400 text-gray-400">Images (.jpg/.jpeg/.png)</p>
          </div>
          <input
            type="file"
            accept="image/*"
            value=""
            multiple={allowMultiple}
            onChange={onFileDrop}
            className="cursor-pointer position-absolute top-0 start-0 w-100 h-100"
            style={{ opacity: 0 }}
          />
        </div>
      )}
    </>
  );
};

export default DropFileInputProject;
