import React, { useEffect, useState } from "react";
import { Button, Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ProfessionalTypeSelection = ({ serviceCategories, step, setStep, serviceType, setServiceType }) => {

    const navigate = useNavigate()
    const [currentCard, setCurrentCard] = useState(null);
    const [hover, setHover] = useState(null);

    useEffect(() => {
        if (serviceType) {
            setCurrentCard(serviceCategories.filter(cat => cat.type === serviceType)[0])
        } else {
            setCurrentCard(serviceCategories[0])
        }
    }, [])

    return (
        <Container className="d-flex flex-column justify-content-center align-items-center"
            style={{ minHeight: "70vh" }}
        >
            <div className="w-100" style={{ maxWidth: 500 }}>
                <div className="mt-5 inter-500">
                    Professional Category
                </div>
                <div>
                    {serviceCategories.map((opt) => {
                        return (
                            <Card
                                className={`cursor-pointer p-3 mt-3 ${opt.title === currentCard?.title ? "border-black" : "border-gray-200"}`}
                                style={{ boxShadow: "none", borderRadius: 3 }}
                                onClick={() => {
                                    setCurrentCard(opt)
                                    setServiceType(opt.type)
                                }}
                                onMouseEnter={() => setHover(opt)}
                                onMouseLeave={() => setHover(null)}
                            >
                                <div className={`body-title ${opt.title === currentCard?.title ? "text-primary-500 inter-600" : "text-gray-800 inter-500"}`}>
                                    {opt.title}
                                </div>
                                <div className={`${(opt.title === currentCard?.title || opt.title === hover?.title) ? "body-text2 text-gray-600" : "d-none"}`}>
                                    {opt.description}
                                </div>
                            </Card>
                        )
                    })}
                </div>
                <div className="d-flex mt-5 w-100">
                    <Button
                        className="px-3 py-2 me-3 text-gray-900 bg-white border-gray-200"
                        style={{ borderRadius: 3 }}
                        onClick={() => setStep(step - 1)}
                    >
                        Back
                    </Button>
                    <Button
                        className="p-2 w-100"
                        style={{ borderRadius: 3 }}
                        onClick={() => {
                            setStep(step + 1)
                            navigate(`/signup#professional|${serviceType}`)
                        }}
                    >
                        Continue as {currentCard?.title}
                    </Button>
                </div>
            </div>
        </Container>
    )
}

export default ProfessionalTypeSelection