import { default_pfp } from "assets";
import * as authActions from "reduxFiles/actions/authActions";
import ProjectCard from "components/Cards/ProjectCard";
import RangeSlider from "components/Forms/RangeSlider";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { LuCheck, LuPencil } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userUpdate } from "reduxFiles/reducer/authSlices";
import { notify } from "utilities/notifications/Notify";
import { formatNumber } from "utilities/numberFormatter";

function ArchitectPublicProfile() {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [architectInfo, setArchitectInfo] = useState(null);
  const [rangeValue, setRangeValue] = useState({
    min: 3000000,
    max: 500000000,
  });
  const [profilePic, setProfilePic] = useState(null);
  const [isPfpEdit, setIsPfpEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const architectInfoRef = useRef();

  const fetchArchitectData = async () => {
    try {
      const response = await authActions.fetchArchitectByUser(user.id);
      if (response.data) {
        if (response.data?.results[0]) {
          setArchitectInfo(response.data?.results[0]);
          setRangeValue({
            min: response.data?.results[0].job_cost_minimum,
            max: response.data?.results[0].job_cost_maximum,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    // const resolvedProjects = await projectData;
    // setProjects(resolvedProjects);
  };

  const handleProfileImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePic(file);
    }
  };
  const handleButtonClick = () => {
    document.getElementById("profileUpload").click();
    setIsPfpEdit(true);
  };

  const handleProfilePicUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("profile_picture", profilePic);
      const response = await authActions.updateProfilePic(user.id, formData);
      if (response.data) {
        notify("Profile Picture Updated!", "success");
        setIsPfpEdit(false);
        dispatch(userUpdate(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(architectInfoRef.current);
    let data = {};
    formData.append("user", user.id);
    formData.append("job_cost_minimum", rangeValue.min);
    formData.append("job_cost_maximum", rangeValue.max);
    formData.forEach((value, key) => (data[key] = value));
    console.log(data);
    if (architectInfo) {
      const response = await authActions.updateArchitect(architectInfo?.id);
      console.log(response);
      if (response.data) {
        notify("Info updated!", "success");
      }
    } else {
      const response = await authActions.addArchitect(data);
      if (response.data) {
        notify("Info updated!", "success");
      }
    }
  };

  useEffect(() => {
    fetchData();
    fetchArchitectData();
  }, []);

  return (
    <Container fluid className="mt-5 pt-4 px-0">
      <Container fluid className="bg-gray-25 py-5">
        <Container className="d-flex align-items-center">
          <div className="position-relative">
            <img
              style={{
                border: "1px solid black",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              width={150}
              height={150}
              src={
                profilePic
                  ? URL.createObjectURL(profilePic)
                  : user?.profile_picture || default_pfp
              }
              alt="avatar"
            />
            <input
              type="file"
              id="profileUpload"
              style={{ display: "none" }}
              onChange={handleProfileImageChange}
            />
            {isPfpEdit && profilePic ? (
              <Button
                variant="success"
                className="position-absolute d-flex align-items-center rounded-btn text-white"
                style={{ bottom: "0px", right: "5px" }}
                onClick={handleProfilePicUpdate}
              >
                <LuCheck />
              </Button>
            ) : (
              <Button
                className="position-absolute d-flex align-items-center rounded-btn text-white"
                style={{ bottom: "0px", right: "5px" }}
                onClick={handleButtonClick}
              >
                <LuPencil />
              </Button>
            )}
          </div>
          <div className="inter-600 ms-auto" style={{ fontSize: "30px" }}>
            <Button
              variant="info"
              className="text-gray-900"
              onClick={() => navigate(`/professionals/${user?.id}`)}
            >
              See Public View
            </Button>
          </div>
        </Container>
      </Container>
      <Container>
        <Row className="my-4 signup-card" style={{ boxShadow: "none" }}>
          <div className="d-flex">
            <div className="h3 inter-700">About</div>
            <Button
              className="d-flex align-items-center rounded-btn text-white ms-auto"
              onClick={() => setIsEdit(!isEdit)}
            >
              <LuPencil />
            </Button>
          </div>
          <Col lg={6}>
            <Form ref={architectInfoRef} onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Company Name<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                  className="border-dark"
                  value={architectInfo?.company_name}
                  name="company_name"
                  disabled={!isEdit}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Location<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                  className="border-dark"
                  defaultValue={architectInfo?.location}
                  name="location"
                  disabled={!isEdit}
                />
              </Form.Group>
              <div>
                <div className="d-flex">
                  <div>
                    <span className="inter-600">Min: </span>
                    {formatNumber(rangeValue.min)}
                  </div>
                  <div className="ms-auto">
                    <span className="inter-600">Max: </span>
                    {formatNumber(rangeValue.max)}
                  </div>
                </div>
                <RangeSlider
                  min={3000000}
                  max={500000000}
                  step={500000}
                  value={rangeValue}
                  onChange={setRangeValue}
                  disabled={!isEdit}
                />
              </div>
              <Row>
                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Projects Count<sup style={{ color: "red" }}>*</sup>
                    </Form.Label>
                    <Form.Control
                      className="border-dark"
                      name="project_count"
                      defaultValue={architectInfo?.project_count}
                      disabled={!isEdit}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Years of Experience<sup style={{ color: "red" }}>*</sup>
                    </Form.Label>
                    <Form.Control
                      className="border-dark"
                      name="years_of_experience"
                      defaultValue={architectInfo?.years_of_experience}
                      disabled={!isEdit}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Cities<sup style={{ color: "red" }}>*</sup>
                    </Form.Label>
                    <Form.Control
                      className="border-dark"
                      name="no_of_cities"
                      defaultValue={architectInfo?.no_of_cities}
                      disabled={!isEdit}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Inspirations Made<sup style={{ color: "red" }}>*</sup>
                    </Form.Label>
                    <Form.Control className="border-dark" />
                  </Form.Group>
                </Col>
                {isEdit && (
                  <Col>
                    <Button type="submit" className="me-3 text-white">
                      Save
                    </Button>
                    <Button
                      className="bg-gray"
                      onClick={() => setIsEdit(false)}
                    >
                      Cancel
                    </Button>
                  </Col>
                )}
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="my-4 signup-card" style={{ boxShadow: "none" }}>
          <div className="h3 inter-600 mb-3">Portfolio Projects</div>
          {projects.map((item, index) => (
            <Col key={index} lg={4}>
              <ProjectCard
                key={index}
                id={item.id}
                project_name={item.title}
                type={item.type}
                image={item.spaces[0]?.image}
                boxShadow="none"
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default ArchitectPublicProfile;
