import { Button, Image, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { interiokeys_logo, no_location_image } from "assets";
import { MdOutlineArrowForward } from "react-icons/md";

const BookingConfirmation = ({ show }) => {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(max-width: 768px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);

  return (
    <Modal
      className="d-flex justify-content-center align-items-center"
      dialogClassName="location-modal-width"
      show={show}
    >
      <Image src={no_location_image} alt="No Location Image" style={{ borderRadius: "6px 6px 0 0" }} />
      <div className="text-center my-3">
        <Image src={interiokeys_logo} alt="Interiokeys Logo" width={30} height={30} />
      </div>
      <div className="px-3">
        <h3 className="inter-600 text-center">Booking Confirmed!</h3>
        <div className="text-gray-600 text-center">
          Your booking is confirmed and we will get in touch with you soon. Check out Interiokeys inspirations till then!
        </div>
        <Button
          className="body-text1 inter-400 w-100 my-3 d-flex justify-content-between align-items-center"
          style={{ borderRadius: 3 }}
          onClick={() => {
            navigate(`/projects`);
          }}
        >
          <span>Explore Inspirations</span>
          <MdOutlineArrowForward size={20} />
        </Button>
      </div>
    </Modal>
  );
};

export default BookingConfirmation;
