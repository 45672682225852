import React, { useEffect, useState } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";

const SearchBarInspiration = ({ searchText, setSearchText }) => {
  const isLargeScreen = useMediaQuery({ query: "(min-width: 992px)" });
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";

  const handleSearch = () => {
    if (searchText.trim() !== "") {
      navigate(`/projects?property=${searchText}`);
    }
  };

  return (
    <Form.Group className="d-flex w-100 bg-white" style={{ maxWidth: 700, borderRadius: 4, border: "1px solid #ccc" }}>
      <InputGroup className="flex-grow-1 p-2 py-1">
        <Form.Control
          size="lg"
          placeholder="Enter your property name"
          className="p-0 px-2 custom-search-placeholder body-title"
          style={{
            borderRadius: "4px 0 0 4px",
            border: "none",
            boxShadow: "none",
          }}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Button
          variant="primary"
          className="px-3"
        //   size="sm"
          onClick={handleSearch}
          style={{
            whiteSpace: "nowrap",
            borderRadius: 5,
          }}
        >
          Search Inspirations
        </Button>
      </InputGroup>
    </Form.Group>
  );
};

export default SearchBarInspiration;
