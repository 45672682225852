import { useEffect, useState } from "react";
import { Card, Image, Spinner } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { truncateText } from "utilities/truncateText";

const ProfessionalMegaMenu = ({ showProfessionals, setShowProfessionals, toggled, setToggled  }) => {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const professionals = [
    { id: 1, title: "Architects", slug: "architects" },
    { id: 2, title: "Interior Designers", slug: "interior-designers" },
    // { id: 3, title: "Builders", slug: "builders" },
  ]
  const [activeTab, setActiveTab] = useState(professionals[0]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      switch (activeTab.slug) {
        case "architects":
          console.log(activeTab.slug);
          const response = await authActions.fetchProfessionals(activeTab.slug);
          if (response.data) {
            setData(response.data?.results);
          }
          break;
        case "interior-designers":
          console.log(activeTab.slug);
          const response1 = await authActions.fetchProfessionals(activeTab.slug);
          if (response1.data) {
            setData(response1.data?.results);
          }
          break;
        // case "builders":
        //   console.log(activeTab.slug);
        //   const response2 = await authActions.fetchBuilders();
        //   if (response2.data) {
        //     setData(response2.data?.results);
        //   }
        //   break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (activeTab) {
      fetchData()
    }
  }, [activeTab])

  return (
    <>

      {small &&

        <Card
          className={`${showProfessionals ? "position-absolute huge-dropdown-container d-flex p-0 shadow-none" : "d-none"}`}
          style={{ left: 0, top: 70 }}
        >
          <Card.Body
            className="p-0"
            style={{ backgroundColor: "#FFFFFF", borderRadius: 5 }}
          >
            <div className="pt-2" style={{ padding: "0px 50px 30px" }}>
              <div className="body-text2 d-flex mb-4">
                {professionals.map((item) => {
                  return (
                    <div className="me-2 cursor-pointer" onClick={() => setActiveTab(item)}>
                      <div className={`my-1 mx-2 inter-500 ${item.slug === activeTab.slug ? "text-primary" : "text-gray-900"}`}>{item.title}</div>
                      <div className={`mt-1 w-100 ${item.slug === activeTab.slug ? "bg-primary" : ""}`} style={{ height: 2 }} />
                    </div>
                  )
                })}
              </div>
              {loading ?
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '10vh' }}>
                  <Spinner />
                </div>
                :
                <>
                  <div
                    className="body-text3 d-flex align-items-center inter-500 mb-2"
                    onClick={() => {
                      if (activeTab.slug === "architects") {
                        navigate(`/professionals/architects`)
                      } else if (activeTab.slug === "interior-designers") {
                        navigate(`/professionals/interior-designers`)
                      } else {
                        navigate(`/professionals/builders`)
                      }
                    }}
                  >
                    <span className="me-2">View All {activeTab.slug === "architects" ? "Architects" : activeTab.slug === "interior-designers" ? "Interior Designers" : "Builders"}</span>
                    <FaChevronRight size={12} />
                  </div>
                  <div className="d-flex flex-wrap">
                    {data.length && data?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex flex-column align-items-center body-text3 nav-link cursor-pointer me-2"
                          onClick={() => {
                            setShowProfessionals(false)
                            navigate(`/professionals/${activeTab.slug}/${item.slug}`)
                          }}
                        >
                          <Image
                            src={item?.user_data?.profile_picture}
                            alt={item.company_name}
                            className="small-image"
                            width="90px"
                            height="80px"
                          />
                          <div className="p-1">{truncateText(item.company_name, 13)}</div>
                        </div>
                      );
                    })}
                  </div>
                </>
              }
            </div>
          </Card.Body>
        </Card>
      }
      {!small && showProfessionals &&
        <div className="d-flex flex-column">
          {professionals.map((item) => {
            return (
              <div className="me-2 cursor-pointer" onClick={() => {
                if (item.slug === "architects") {
                  navigate(`/professionals/architects`)
                } else {
                  navigate(`/professionals/interior-designers`)
                }
                setToggled(!toggled)
              }}
                style={{ padding: '6px 16px' }}
              >
                <div className={`body-text1 inter-400 text-gray-900`}>{item.title}</div>
              </div>
            )
          })}
        </div>}
    </>

  );
};

export default ProfessionalMegaMenu;
