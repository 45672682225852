import { Flip, toast } from "react-toastify";

const toastConf = {
  position: "top-right",
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  pauseOnFocusLoss: false,
  hideProgressBar: true,
  className: "my-toast",
  theme: "light",
  transition: Flip,
};

export const notify = (msg, type, toastId) => {
  switch (type) {
    case "success":
      toastConf["toastId"] = toastId;
      toast.success(msg, toastConf);
      break;
    case "error":
      toastConf["toastId"] = toastId;
      toast.error(msg, toastConf);
      break;
    case "warning":
      toastConf["toastId"] = toastId;
      toast.warning(msg, toastConf);
      break;
    case "info":
      toastConf["toastId"] = toastId;
      toast.info(msg, toastConf);
      break;
    default:
      toastConf["toastId"] = toastId;
      toast.error(msg, toastConf);
  }
};
