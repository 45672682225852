import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import InteriorRequirement from "./InteriorRequirement";
import ConsultationRequirement from "./ConsultationRequirement";
import BookConsultationForm from "./BookConsultationForm";
import { useLocation, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "utilities/notifications/Notify";
import * as authActions from "reduxFiles/actions/authActions";
import { login } from "reduxFiles/reducer/authSlices";
import ProjectReferenceCard from "components/Order/ProjectReferenceCard";
import SpaceReferenceCard from "components/Order/SpaceReferenceCard";
import ArchitectReferenceCard from "components/Order/ArchitectReferenceCard";
import ReactSelect from "react-select";
import { FaCheck } from "react-icons/fa6";
import { BookingConfirmation } from "components";
import { validateEmail } from "utilities/validateEmail";

const Consultation = () => {
  const location = useLocation();
  const { state } = location;
  const dispatch = useDispatch();
  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  // const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const form = useRef(null);

  const { refType, refId } = useParams();
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [custom, setCustom] = useState(true);
  const [propertyId, setPropertyId] = useState(null);
  const [properties, setProperties] = useState([]);
  const [projectType, setProjectType] = useState("apartment");
  const [processEventKey, setProcessEventKey] = useState("selected_service");
  const [serviceType, setServiceType] = useState("");
  const [requirement, setRequirement] = useState("");

  const [selectedRoomType, setSelectedRoomType] = useState([]);
  const [floorPlan, setFloorPlan] = useState("");
  const [show, setShow] = useState(false);
  const [area, setArea] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [otpInput, setOtpInput] = useState(false);

  const propertyTypes = [
    { id: 1, title: "Apartments", slug: "apartment" },
    { id: 2, title: "Offices", slug: "office" },
    { id: 3, title: "Villas", slug: "villa" },
    { id: 4, title: "Showrooms", slug: "showroom" },
    // { id: 5, title: "Independent Land", slug: "independent_land" },
  ]

  const fetchProperties = async () => {
    try {
      const response = await authActions.fetchAllProperties();
      if (response.data) {
        setProperties(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProperties();
  }, [])

  const handleSubmitRequest = async (userId) => {
    setIsLoading(true);

    const formData = new FormData(form.current);
    const selectedRoomString = selectedRoomType.map(room => {
      const [key, value] = Object.entries(room)[0];
      return `${key}: ${value}`;
    }).join(", ");
    const data = {
      user: userId,
      service_type: serviceType,
      property_name: propertyId,
      requirement: requirement,
      selected_rooms: selectedRoomString,
      project_type: projectType,
      area: area,
      floorplan: floorPlan,
      type: refType,
      architect: state?.architect?.id,
      project: state?.project?.id,
      room: state?.room?.id,
      requirement_msg: formData.get('requirements_msg')
    };

    console.log("data for booking consultation: " + data);

    try {
      const response = await authActions.requestConsultationCallback(data);
      if (response.data) {
        clearStates();
        setShow(true);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData(form.current);
    if (formData.get("confirm_password") !== formData.get("password")) {
      notify("Passwords did't match!");
      setIsLoading(false);
      return;
    }
    // formData.append("user_type", serviceType);
    formData.append("user_type", "customer");
    let data = {};
    formData.forEach((value, key) => (data[key] = value));
    console.log(data);
    try {
      const response = await authActions.signup(data);
      if (response.data) {
        setOtpInput(true);
      } else if (response?.msg) {
        notify(response.msg);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    const formData = new FormData(form.current);
    if (validateEmail(formData.get("username"))) {
      try {
        const response = await authActions.login(formData);
        if (response.data) {
          dispatch(login(response.data));
        }
        if (response.data?.user.id) {
          handleSubmitRequest(response.data.user.id);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      notify("Please enter a valid email id!", "error");
    }
    setIsLoading(false)
  };

  const handleContinue = () => {
    // const formData = new FormData(form.current);
    // if(formData.get(""))
    if (propertyId) {
      isAuthenticated
        ? handleSubmitRequest(user.id)
        : setProcessEventKey("book");
    } else if (projectType) {
      if (projectType === "apartment" || projectType == "villa") {
        if (requirement && floorPlan) {
          if (requirement == "selected_rooms") {
            if (selectedRoomType?.length > 0) {
              isAuthenticated
                ? handleSubmitRequest(user.id)
                : setProcessEventKey("book");
            } else {
              notify("Please select room types for selected rooms", "error");
            }
          } else {
            isAuthenticated
              ? handleSubmitRequest(user.id)
              : setProcessEventKey("book");
          }
        } else {
          notify("Please select both requirement and floor plan", "error");
        }
      } else {
        if (area && area !== 0 && area !== "0") {
          isAuthenticated
            ? handleSubmitRequest(user.id)
            : setProcessEventKey("book");
        } else {
          notify("Please select area", "error");
        }
      }
    } else {
      notify("Please select type of project", "error");
    }
  };

  const clearStates = () => {
    setProjectType("");
    setProcessEventKey("selected_service");
    setServiceType("");
    setRequirement("");
    setSelectedRoomType([]);
    setFloorPlan("");
    // setReferenceData(null);
    // setShow(false);
    setArea(0);
    setIsLoading(false);
    setShowLoginForm(false);
    setOtpInput(false);
  };

  useEffect(() => {
    if ((refId, refType)) {
      console.log("refId, refType: ", refId, refType);
      // fetchData();
    }
  }, [refId, refType]);

  useEffect(() => {
    if (location.hash !== "") {
      setProcessEventKey("project_details");
      if (location.hash === "#turnkey") {
        setServiceType("turnkey");
      } else if (location.hash === "#customised") {
        setServiceType("customised");
      }
    }
  }, [location]);

  return (
    <>
      <Container fluid className="mt-5 pt-4 px-0 d-flex flex-column align-items-center" style={{ minHeight: "70vh" }}>
        <div className="w-100 bg-brand-25 d-flex justify-content-center align-items-center">
          <h1 className="h2 text-primary-500 inter-600 pb-3 pt-4">
            {`${isAuthenticated ? `Hey ${user.full_name},` : ""
              } Get started with Interiokeys!`}
          </h1>
        </div>
        <div className="d-flex flex-column flex-lg-row my-md-4 mt-lg-5 pt-lg-5" style={large ? { maxWidth: 1000, width: '100%' } : { width: '100%' }}>
          <div className="d-flex justify-content-center py-4 py-lg-0" style={{ minWidth: 250 }}>
            <div className="d-flex flex-lg-column mt-0 mt-md-4 mt-lg-3 ml-2 mb-1 px-3 px-lg-0">
              <div className="d-flex align-items-center mb-0 mb-lg-4 me-2">
                <div
                  className={`d-flex justify-content-center align-items-center p-1 text-center body-text1 rounded-btn 
                        ${processEventKey === "selected_service"
                      ? "bg-black text-white" : "bg-success text-white"
                    }`}
                >
                  {processEventKey === "selected_service" ? "1" : <FaCheck size={18} />}
                </div>
                <div
                  className={`ms-2 inter-500 body-text1 ${processEventKey !== "selected_service" ? "text-success" : ""
                    }`}
                >
                  Select Service
                </div>
              </div>
              <div className="d-flex align-items-center mb-0 mb-lg-4 me-2">
                <div
                  className={`d-flex justify-content-center align-items-center p-1 text-center rounded-btn ${processEventKey === "project_details"
                    ? "bg-black text-white"
                    : processEventKey === "book"
                      ? "bg-success text-white"
                      : "text-gray-200 border border-gray-200"
                    }`}
                >
                  {processEventKey === "selected_service" || processEventKey === "project_details" ? (
                    "2"
                  ) : (
                    <FaCheck size={18} />
                  )}
                </div>
                <div
                  className={`ms-2 inter-500 body-text1 ${processEventKey !== "selected_service" && processEventKey !== "project_details" ? "text-success" : processEventKey === "project_details" ? "" : "text-gray-200"
                    }`}
                >
                  Project Details
                </div>
              </div>
              <div className="d-flex align-items-center mb-0 mb-lg-4 me-2">
                <div
                  className={`d-flex justify-content-center align-items-center p-1 text-center rounded-btn ${processEventKey === "book" ? "bg-black text-white" : "text-gray-200 border border-gray-200"
                    }`}
                >
                  3
                </div>
                <div className={`ms-2 inter-500 body-text1 ${processEventKey === "book" ? "text-gray-900" : "text-gray-200"}`}>Contact Details</div>
              </div>
            </div>
          </div>
          <div className="mx-3 mx-lg-0 p-3 border" style={{ borderRadius: 6 }}>
            {processEventKey === "selected_service" && (
              <div className="my-0" style={large ? { minWidth: 600 } : {}}>
                <h4 className="inter-600 mb-3 py-2">
                  Select Service
                </h4>
                {state?.room && (
                  <div className="d-flex flex-column w-100">
                    <div className="text-gray-900 body-text2 inter-500 mb-1">
                      Selected Inspiration(Space)
                    </div>
                    <SpaceReferenceCard room={state.room} className="bg-white" />
                  </div>
                )}
                {state?.project && (
                  <div className="d-flex flex-column w-100">
                    <div className="text-gray-900 body-text2 inter-500 mb-1">
                      Selected Inspiration(Project)
                    </div>
                    <ProjectReferenceCard project={state.project} className="bg-white" />
                  </div>
                )}
                {state?.architect && (
                  <div className="d-flex flex-column w-100">
                    <div className="text-gray-900 body-text2 inter-500 mb-1">
                      Selected Architect
                    </div>
                    <ArchitectReferenceCard architect={state.architect} className="bg-white" />
                  </div>
                )}
                <ConsultationRequirement
                  serviceType={serviceType}
                  setServiceType={setServiceType}
                />
              </div>
            )}
            {processEventKey === "project_details" && (
              <div className="my-0" style={large ? { minWidth: 650 } : {}}>
                <h4 className="inter-600 mb-3 py-2">
                  Project Details
                </h4>
                {/* <div className="body-text2 inter-500 text-start mt-3 mb-1">
                  Type of project
                </div>
                <div className="px-2">
                  <Row className="align-items-center">
                    {propertyTypes.map((type) => {
                      return (
                        <Col className="mb-2 px-1 w-100">
                          <Button
                            className={`body-text2 inter-500 w-100 btn-bg-custom ${projectType === type.slug
                              ? "border-gray-600 text-gray-700 bg-gray-50"
                              : "border-gray-300 text-gray-700 bg-white"
                              }`}
                            style={{
                              borderRadius: 3, minHeight: 36, boxShadow: projectType === type.slug ? `0px 0px 0px 1px #93939326;
`: ''
                            }}
                            onClick={() => setProjectType(type.slug)}
                          >
                            {type.title}
                          </Button>
                        </Col>
                      )
                    })}
                  </Row>
                </div> */}
                <div className="body-text2 inter-500 text-start mt-2 mb-">
                  Property Name
                </div>
                <ReactSelect
                  name="project_property"
                  isMulti={false}
                  options={properties}
                  getOptionLabel={(e) => e.property_name}
                  getOptionValue={(e) => e.id}
                  onChange={(e) => {
                    if (e?.id) {
                      setPropertyId(e.id)
                      setCustom(false);
                    } else {
                      setCustom(true);
                    }
                  }}
                  isClearable
                  placeholder="Select Property"
                  className="body-text2 mb-2"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: "1px solid #BDC1C6",
                      borderRadius: 3
                    }),
                  }}
                />
                {custom &&
                  <>
                    <Row className="px-1">
                      {(projectType && projectType === "apartment") ||
                        projectType === "villa" ? (
                        <InteriorRequirement
                          setRequirement={setRequirement}
                          floorPlan={floorPlan}
                          setFloorPlan={setFloorPlan}
                          setSelectedRoomType={setSelectedRoomType}
                        />
                      ) : projectType ? (
                        <Col className="px-2">
                          <Form.Group
                            className="d-flex align-items-center px-2 py-1 mt-2 bg-gray-25 border rounded"
                            controlId="formRange"
                          >
                            <Form.Label className="w-15 inter-700 me-2">
                              Area: {area} Sft
                            </Form.Label>
                            <div className="h-100 d-flex align-items-center">
                              <input
                                type="number"
                                min="0"
                                max="10000"
                                step="50"
                                value={area}
                                onChange={(event) => setArea(event.target.value)}
                                style={{
                                  height: '36px',
                                  border: "1px solid #BDC1C6",
                                  borderRadius: 3
                                }}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  </>
                }
                <div className="mt-4 d-flex">
                  {/* <Button
                    className="bg-transparent inter-500 border-gray-200 text-gray-900 body-text2"
                    style={{ borderRadius: 3 }}
                    onClick={() => {
                      // setCustom(true)
                      // setProjectType("")
                      setProcessEventKey("selected_service")
                    }}
                  >
                    Back
                  </Button> */}
                  {processEventKey === "project_details" && <Button
                    className="bg-transparent inter-500 border-gray-200 text-gray-900 body-text2"
                    style={{ borderRadius: 3 }}
                    onClick={() => {
                      // setCustom(true)
                      // setProjectType("")
                      setProcessEventKey("selected_service")
                    }}
                  >
                    Cancel
                  </Button>}
                  {isAuthenticated ? (
                    <Button
                      variant="primary"
                      className="inter-500 body-text2 text-white ms-2"
                      style={{ width: 100 }}
                      onClick={() => setProcessEventKey("book")}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      className="inter-500 body-text2 text-white ms-2"
                      style={{ borderRadius: 3 }}
                      onClick={handleContinue}
                    >
                      Continue
                    </Button>
                  )}
                </div>
              </div>
            )}
            {processEventKey === "book" && (
              <div className="my-0" style={large ? { minWidth: 650 } : {}}>
                <h4 className="inter-600 mb-3 py-2">
                  Book Free Call
                </h4>
                <BookConsultationForm
                  form={form}
                  loading={isLoading}
                  showLoginForm={showLoginForm}
                  setShowLoginForm={setShowLoginForm}
                  otpInput={otpInput}
                  handleSubmitRequest={handleSubmitRequest}
                  handleSignUp={handleSignUp}
                  handleLogin={handleLogin}
                />
                <div>
                  <Button
                    className="bg-transparent inter-500 border-gray-200 text-gray-900 body-text2"
                    style={{ borderRadius: 3 }}
                    onClick={() => setProcessEventKey("project_details")}
                  >
                    Back
                  </Button>
                  <Button
                    className="ms-2 bg-transparent inter-500 border-gray-200 text-gray-900 body-text2"
                    style={{ borderRadius: 3 }}
                    onClick={() => {
                      // setCustom(true)
                      // setProjectType("")
                      setProcessEventKey("selected_service")
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <BookingConfirmation show={show} setShow={setShow} />
      </Container>
    </>
  );
};

export default Consultation;
