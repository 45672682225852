import React from "react";
import { FaStar } from "react-icons/fa6";

const RatingStarsView = ({ rating, setRating }) => {
  return (
    <div className="d-flex">
      {Array.from({ length: 5 }).map((item, index) => (
        <FaStar
          key={index}
          size={24}
          color={index <= rating - 1 ? "#f1c40f" : "#d3d3d3"}
          className="me-2"
          onClick={() => setRating(index + 1)}
        />
      ))}
    </div>
  );
};

export default RatingStarsView;
