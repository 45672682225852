import { useEffect, useState } from "react";
import * as authActions from "reduxFiles/actions/authActions";
import ProjectCard from "components/Cards/ProjectCard";
import Pagination from "components/Pagination";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ShareModal from "components/General/ShareModal";

import { useSelector } from "react-redux";
import PropertyCard from "components/Cards/PropertyCard";
import { IoChevronBack } from "react-icons/io5";
import { useScrollBottom, InfiniteScroll } from "components";

const InspirationsCreated = () => {
    const { propertySlug } = useParams();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);

    // const small = useMediaQuery({ query: "(min-width: 576px)" });
    // const medium = useMediaQuery({ query: "(min-width: 768px)" });
    // const large = useMediaQuery({ query: "(min-width: 992px)" });
    // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
    // const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });
    // const xxxlarge = useMediaQuery({ query: "(min-width: 1600px)" });

    const [propertyData, setPropertyData] = useState(1);
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showShareProject, setShowShareProject] = useState(false);

    const bottom = useScrollBottom();
    const [noNextItem, setNoNextItem] = useState(true);

    const fetchProperty = async () => {
        try {
            const response = await authActions.fetchPropertyBySlug(propertySlug);
            if (response.data) {
                setPropertyData(response.data?.results[0]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const loadPaginatedProjects = async () => {
        setLoading(true);
        try {
            const response = await authActions.loadProjects(null, user.id, null, propertySlug, null, null);
            if (response) {
                setProjects(response);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const loadMoreProjects = async () => {
        const projectList = projects.results;
        if (projects.next) {
            setNoNextItem(false)
            const newProjects = await authActions.loadProjects(projects.next);
            if (newProjects) {
                const newResults = projectList.concat(newProjects.results);
                newProjects.results = newResults;
                setProjects(newProjects);
            }
        } else {
            setNoNextItem(true)
        }
    };

    useEffect(() => {
        loadPaginatedProjects();
        if (propertySlug) {
            fetchProperty()
        }
    }, [propertySlug]);

    useEffect(() => {
        if (bottom) {
            loadMoreProjects()
        }
    }, [bottom]);

    return (
        <Container fluid>
            <Container className="mt-5 pt-5">
                <div className="d-flex align-items-center cursor-pointer" onClick={() => navigate(-1)}>
                    <IoChevronBack size={18} />
                    <span className="ms-2 body-text2 inter-500">Back to My Properties</span>
                </div>
                <PropertyCard
                    {...propertyData}
                    key={propertyData?.id}
                    builder={propertyData?.builder_data?.company_name}
                    project_type={propertyData?.project_type_data?.title}
                    allFunction={false}
                />
                <div className="body-text1 inter-600 text-gray-800 mt-5">{propertyData?.property_name} Inspirations ({projects?.count})</div>
                <div className="mb-3">
                    {loading ? (
                        <div
                            className="d-flex justify-content-center align-items-center w-100"
                            style={{ minHeight: "50vh" }}
                        >
                            <Spinner />
                        </div>
                    ) : projects?.count > 0 ? (
                        <div className="d-flex flex-column align-items-center" style={{ minHeight: "80vh" }}>
                            <Row className="row-cols-3 w-100">
                                {projects?.results?.map((item, index) => (
                                    <Col
                                        key={index}
                                        className={`d-flex flex-column py-2 mb-2 justify-content-start ${index % 3 === 0 ? "ps-0" : index % 3 === 2 ? "pe-0" : ""}`}
                                    >
                                        <ProjectCard
                                            {...item}
                                            id={item.id}
                                            project_name={item?.project_name}
                                            image={item?.rooms[0]?.media[0]?.file}
                                            // image={item?.image}
                                            architect={item?.company_name}
                                            location={item?.property_data?.location}
                                            builders={item?.property_data?.builder_data?.company_name}
                                            className="mx-0 flex-grow"
                                        />
                                        <ShareModal
                                            show={showShareProject}
                                            onHide={() => setShowShareProject(false)}
                                            // id={id}
                                            // image={image}
                                            title={`${item?.project_name}/${item?.bhk}/${item?.property_name}`}
                                            urlPath={
                                                item?.slug !== "undefined"
                                                    ? `projects/${item?.slug}`
                                                    : null
                                            }
                                        />
                                    </Col>
                                ))}
                            </Row>
                            <InfiniteScroll
                                bottom={bottom}
                                noNextItem={noNextItem}
                            />
                        </div>
                    ) : (
                        <div
                            className="d-flex justify-content-center align-items-center bg-gray-25 rounded w-100"
                            style={{ height: '60vh' }}
                        >
                            No projects available
                        </div>
                    )}
                </div>
            </Container>
        </Container >
    );
};

export default InspirationsCreated;
