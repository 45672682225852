import React, { useRef, useState } from "react";
import TeamMember from "./TeamMember";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

const TeamMemberList = ({
  teamMembers,
  handleDeleteMember,
  isSubmitting,
  handleAddTeamMember,
  showAddTeamModal,
  setShowAddTeamModal,
  enableEdit=false
}) => {
  const formRef = useRef();

  return (
    <div className="py-3">
      {teamMembers?.length > 0 ? (
        <div className="d-flex">
          {teamMembers.map((teamMember, index) => (
            <TeamMember
              key={index}
              {...teamMember}
              handleDelete={handleDeleteMember}
            />
          ))}
          {enableEdit && <div
            className="inter-400 text-center d-flex flex-column justify-content-start align-items-center"
            style={{
              height: "160px",
              cursor: "pointer",
            }}
            onClick={() => setShowAddTeamModal(true)}
          >
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                width: "100px",
                height: "100px",
                border: "1px solid #E8EAED",
                borderRadius: "50px",
                backgroundColor: "#eee",
              }}
            >
              <span style={{ fontSize: 40, color: "#ccc" }}>+</span>
            </div>
            <div
              className="text-muted body-text-2"
              style={{ marginTop: "10px" }}
            >
              Add Team Member
            </div>
          </div>}
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
          style={{ height: 160 }}
        >
          No Team Members available
        </div>
      )}

      {/* Add Team Member Modal */}
      <Modal show={showAddTeamModal} onHide={() => setShowAddTeamModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Team Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter name"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Role</Form.Label>
              <Form.Control
                type="text"
                name="role"
                placeholder="Enter role"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Profile Picture</Form.Label>
              <Form.Control type="file" name="image" accept="image/*" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAddTeamModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              const formData = new FormData(formRef.current);
              handleAddTeamMember(formData);
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <Spinner size="sm" animation="border" /> : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TeamMemberList;
