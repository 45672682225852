import { Card } from "react-bootstrap";

const TestimonialCard = ({
  testimonial,
  fixedHeight = false,
  className,
}) => {
  return (
    <Card
      className={`p-0 text-start shadow-none overflow-hidden ${className}`}
      style={{ width: "100%", height: "100%", borderRadius: 6, border: "1px solid #E8EAED" }}
    >
      <Card.Img className="img-fluid" variant="top" src={testimonial?.image} />
      <Card.Body className="p-0">
        <div
          className="body-text2 h-100 d-flex flex-column justify-content-between"
          style={{ color: "#303030" }}
        >
          <div
            className="mb-4 p-3 text-center body-text1 inter-400 text-gray-600"
            style={{ lineHeight: "20px" }}
          >
            {testimonial?.testimonial}
          </div>
          <div className="d-flex flex-column justify-content-end mx-2 py-2" style={{ borderTop: "1px solid #E8EAED" }}>
            <div
              className="body-title inter-600 text-center text-gray-900"
            >
              {testimonial?.name}
            </div>
            <div className="body-text2 inter-400 text-center text-gray-600">
              {testimonial?.title}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default TestimonialCard;
